<div *ngIf="routingMode" class="d-flex flex-column flex-grow-1">
    <div
            class="route-actions d-flex justify-content-between align-items-center">
        <div class="ml-4">
            <strong class="mr-1">Route&nbsp;Date:&nbsp;</strong>
            <mat-form-field appearance="outline" color="accent">
                <mat-datepicker-toggle matSuffix [for]="routeDatePicker">
                </mat-datepicker-toggle>
                <mat-datepicker [dateClass]="dateClass" touchUi
                                #routeDatePicker></mat-datepicker>
                <input #routeDateInput matInput [placeholder]="dateFormat"
                        [min]="viewmodel.routeDateMin" [matDatepicker]="routeDatePicker"
                        [(ngModel)]="viewmodel.routeDate"
                        (ngModelChange)="changeRouteDate($event)"/>
            </mat-form-field>
        </div>
        <strong class="mb-0">Current Stores Selected:
            <a (click)="setMapView()" class="text-teal">
                {{ viewmodel.routeCustomerIds.length }}
            </a>
        </strong>
        <div class="mr-4">
            <button appThrottleClick class="btn btn-secondary mr-1"
                    [disabled]="viewmodel.routeCustomerIds.length === 0 || directionsModalOpened"
                    (throttledClick)="onSetDrivingDirections()">Directions
            </button>
            <button class="btn btn-secondary mr-1"
                    (click)="setMapView()">{{ (directionsView ? "Edit Route" : (mapView ? "List View" : "Map View")) }}</button>
            <button appThrottleClick class="btn btn-primary"
                    [disabled]="viewmodel.routeCustomerIds.length === 0"
                    (throttledClick)="finishRoute()">{{viewmodel.finishButtonText}}</button>
        </div>
    </div>
    <div *ngIf="mapView" class="map-view-class d-flex flex-column flex-grow-1">
        <div >
            <div class="map-area mb-2">
                <mat-spinner class="map-spinner" [diameter]="60" *ngIf="mapLoading"></mat-spinner>
                <google-map #map
                    width="100%"
                    height="100%"
                    [center]="mapCenter" [zoom]="mapZoom"
                    [options]="{styles: mapStyles}"
                    (mapClick)="closeInfoWindow()"
                    (boundsChanged)="setMapBounds(map.getBounds())"
                    (zoomChanged)="resetOnZoom()"
                >
                    <map-marker-clusterer
                        [imagePath]="'/assets/img/mapmarker/m'"
                        [options]="mapMarkerClusterOptions"
                        [ignoreHidden]="true"
                    >
                        <ng-container *ngIf="!directionsView && !markersRebuilding">
                            <map-marker
                                *ngFor="let marker of viewmodel.nonRouteMarkers"
                                [visible]="viewmodel.nonRouteMarkersVisibleIds.includes(marker.customerId)"
                                [position]="{lat: marker.address.latitude, lng: marker.address.longitude}"
                                [title]="marker.title" [icon]="marker.icon"
                                (mapClick)="openInfoWindow($event, marker)"
                                >
                            </map-marker>
                        </ng-container>
                    </map-marker-clusterer>
                    <map-marker
                        *ngFor="let marker of viewmodel.routeMarkers"
                        [position]="{lat: marker.address.latitude, lng: marker.address.longitude}"
                        [title]="marker.title"
                        [label]="marker.label"
                        (mapClick)="openInfoWindow($event, marker)"
                    >
                    </map-marker>
                    <map-info-window #infoWindow>
                        <div *ngIf="viewmodel.infoMarker">
                            <div class="info-window-header">
                                <ng-container *ngIf="viewmodel.infoMarker.icon">
                                    <img [src]="viewmodel.infoMarker.icon.url"
                                            height="20" width="20"/>
                                </ng-container>
                                <strong>{{viewmodel.infoMarker.title}}</strong>
                                <ng-container
                                    *ngIf="viewmodel.infoMarker.customerNumber">
                                    ({{viewmodel.infoMarker.customerNumber}}
                                    )
                                </ng-container>
                            </div>
                            <div class="info-window-row">
                                <strong>
                                    {{viewmodel.infoMarker.addressText}},
                                    {{viewmodel.infoMarker.cityStateZip}}
                                </strong>
                            </div>
                            <ng-container *ngIf="viewmodel.infoMarker.customerType">
                                <div class="info-window-row"><strong>Store
                                    Type: </strong>
                                    {{viewmodel.infoMarker?.customerType?.name}}</div>
                            </ng-container>
                            <ng-container *ngIf="viewmodel.infoMarker.availability">
                                <div class="info-window-row">
                                    <strong>Availability: </strong>
                                    {{viewmodel.infoMarker.availability}}</div>
                            </ng-container>
                            <ng-container *ngIf="viewmodel.infoMarker.px3Rank">
                                <div class="info-window-row"><strong>Px3 Rank: </strong>
                                    {{viewmodel.infoMarker.px3Rank}}</div>
                            </ng-container>
                            <ng-container *ngIf="viewmodel.infoMarker.active">
                                <div class="info-window-row"><strong>Active: </strong>
                                    {{viewmodel.infoMarker.active}}</div>
                            </ng-container>
                            <ng-container *ngIf="viewmodel.infoMarker.lastCall">
                                <div class="info-window-row"><strong>Last
                                    Call: </strong>
                                    {{viewmodel.infoMarker.lastCall}}</div>
                            </ng-container>
                            <br/>
                            <div class="d-flex justify-content-center">
                                <button
                                    *ngIf="!viewmodel.isCustomerOnRoute(viewmodel.infoMarker.customerId); else routeRemove"
                                    appThrottleClick
                                    class="btn btn-primary btn-sm"
                                    [disabled]="viewmodel.route.stops.length > 19"
                                    (throttledClick)="setRouteCustomer(viewmodel.infoMarker, true)"
                                >
                                    Add to Route
                                </button>
                                <ng-template #routeRemove>
                                    <button
                                        class="btn btn-primary btn-sm"
                                        (click)="viewmodel.removeMarkerFromRoute(viewmodel.infoMarker)"
                                    >
                                        Remove from Route
                                    </button>
                                </ng-template>
                            </div>
                        </div>
                    </map-info-window>
                    @if (!!(viewmodel.directionsResult | async)) {
                        <map-directions-renderer
                            [directions]="(viewmodel.directionsResult | async)"
                            [options]="{markerOptions: { visible: false }}"
                        ></map-directions-renderer>
                    }
                </google-map>
            </div>
        </div>
        <div [ngClass]="{ 'd-flex flex-column': mapView }"
                class="d-flex flex-column flex-grow-1">
            <div *ngIf="directionsView">
                <div><strong>Total Drive Time:</strong>
                    {{viewmodel.routeTotalDriveTime
                        ? viewmodel.routeTotalDriveTime : ""}}
                </div>
                <div><strong>Average Time Allotted per Call: </strong>
                    {{viewmodel.routeAverageTimePerCall
                        ? viewmodel.routeAverageTimePerCall : ""}}</div>
                <button
                    appThrottleClick
                    class="btn btn-secondary mb-1"
                    [disabled]="!viewmodel.canOptimizeRoute"
                    (throttledClick)="optimizeWaypoints()"
                >
                    Optimize Route
                </button>
            </div>
            <!-- routed customers table -->
            <cdk-virtual-scroll-viewport itemSize="50" minBufferPx="600"
                maxBufferPx="800" *ngIf="mapView" class="map-viewport">
                <table cdkDropList
                        class="table table-bordered table-striped table-hover"
                        (cdkDropListDropped)="viewmodel.dropRouteItem($event)">
                    <thead>
                    <tr>
                        <th
                            class="text-align-center" scope="col" id="">
                            In Route
                            <div class="header-underline"></div>
                        </th>

                        <th scope="col"
                            id="name">
                            Name
                            <div class="header-underline"></div>
                        </th>
                        <th scope="col"
                            id="address1">
                            <span>Driving Address</span>
                            <div class="header-underline"></div>
                        </th>
                        <th scope="col"
                            id="city">
                            City
                            <div class="header-underline"></div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let stop of viewmodel.route.stops" cdkDrag
                        [cdkDragData]="stop">
                        <div *cdkDragPreview>
                            {{ stop.label }}
                        </div>
                        <td class="text-align-center">
                            <mat-checkbox *ngIf="!directionsView"
                                            appThrottleClick
                                            class="checkbox-margin" [checked]="true"
                                            (throttledClick)="removeRouteStop(stop)">
                            </mat-checkbox>
                            <div *ngIf="directionsView">
                                {{ stop.sequence + 1 }}</div>
                        </td>
                        <td>
                            {{stop.label}}
                        </td>
                        <td>
                                                    <span
                                                        class="material-icons mr-4 location-icon is-priority">business_center</span>
                            {{ stop.address?.address1 }}
                        </td>
                        <td>
                            {{ stop.address?.city }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </cdk-virtual-scroll-viewport>
            <div *ngIf="mapView && !directionsView"
                    class="card border sort-legend-container">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="w-50">
                            <div class="d-flex align-items-center mb-3 legend-entry">
                                <a (click)="filterMarkersByType()"
                                    [ngClass]="{ 'type-selected': viewmodel.typeFilters.length === 0 }">
                                    <img
                                        src="../../../assets/img/store-types/all-store-icon.png"/>
                                    Show All
                                </a>
                            </div>
                            <div class="d-flex align-items-center mb-3 legend-entry">
                                <a (click)="filterMarkersByType(routingModeFilters.chainRetail)"
                                    [ngClass]="{ 'type-selected': viewmodel.typeFilters.includes(routingModeFilters.chainRetail) }">
                                    <img
                                        src="../../../assets/img/store-types/chain-icon.png"/>
                                    Chain Retail
                                </a>
                            </div>
                            <div class="d-flex align-items-center mb-3 legend-entry">
                                <a (click)="filterMarkersByType(routingModeFilters.indirectWholesaler)"
                                    [ngClass]="{ 'type-selected': viewmodel.typeFilters.includes(routingModeFilters.indirectWholesaler) }">
                                    <img
                                        src="../../../assets/img/store-types/w-indirect-icon.png"/>
                                    Indirect Wholesaler
                                </a>
                            </div>
                            <div class="d-flex align-items-center mb-3 legend-entry">
                                <a (click)="filterMarkersByType(routingModeFilters.routed)"
                                    [ngClass]="{ 'type-selected': viewmodel.typeFilters.includes(routingModeFilters.routed) }">
                                    <img
                                        src="../../../assets/img/store-types/routed-store-icon.png"/>
                                    Store in Future Route
                                </a>
                            </div>
                        </div>
                        <div class="w-50">
                            <div class="d-flex align-items-center mb-3 legend-entry">
                                <a (click)="filterMarkersByType(routingModeFilters.independentRetail)"
                                    [ngClass]="{ 'type-selected': viewmodel.typeFilters.includes(routingModeFilters.independentRetail) }">
                                    <img
                                        src="../../../assets/img/store-types/independent-store-icon.png"/>
                                    Independent Retail
                                </a>
                            </div>
                            <div class="d-flex align-items-center mb-3 legend-entry">
                                <a (click)="filterMarkersByType(routingModeFilters.chainHq)"
                                    [ngClass]="{ 'type-selected': viewmodel.typeFilters.includes(routingModeFilters.chainHq) }">
                                    <img
                                        src="../../../assets/img/store-types/hq-icon.png"/>
                                    Chain Headquarters
                                </a>
                            </div>
                            <div class="d-flex align-items-center mb-3 legend-entry">
                                <a (click)="filterMarkersByType(routingModeFilters.directWholesaler)"
                                    [ngClass]="{ 'type-selected': viewmodel.typeFilters.includes(routingModeFilters.directWholesaler) }">
                                    <img
                                        src="../../../assets/img/store-types/w-direct-icon.png"/>
                                    Direct Wholesaler
                                </a>
                            </div>
                            <div class="d-flex align-items-center mb-3 legend-entry">
                                <a (click)="filterMarkersByType(routingModeFilters.px3Ranked)"
                                    [ngClass]="{ 'type-selected': viewmodel.typeFilters.includes(routingModeFilters.px3Ranked) }">
                                    <img
                                        src="../../../assets/img/store-types/px3-ranked-icon.png"/>
                                    Px3 Ranked
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
