@if (customer && hasReceipts) {
    <mat-card appearance="outlined" class="inner-card">
        <div id="retail-receipt">
            @if (isRetailDraft) {
                <app-retail-format-draft
                    #retailDraft
                    class="narrow"
                    [customerReceiptAddress]="customerReceiptAddress"
                    [signature]="signature"
                    [signatureRetailWidth]="signatureRetailWidth"
                    [signatureRetailHeight]="signatureRetailHeight"
                    [selectedContact]="selectedContact"
                    [isEasEmployee]="isEasEmployee"
                    [isTaxState]="isTaxState"
                    [selectedFormat]="selectedFormat"
                    [call$]="call$"
                    [activeProducts]="activeProducts$|async"
                    [employee]="employee"
                    [customer]="customer">
                </app-retail-format-draft>
            } @else {
                <!-- For each condition, Swisher always appears, then choose the appropriate EAS format-->
                @if (isTaxState) {
                    @if (narrowOnly() || selectedFormat === selectedFormats.narrow) {
                        <app-swisher-retail-narrow-tax-format
                            #swisherRetailFormat
                            class="narrow"
                            [activeProducts]="activeProducts$|async"
                            [call]="call$"
                            [signature]="signature"
                            [contact]="selectedContact"
                            [customer]="customer"
                            [employee]="employee"
                            receiptNumberExtention="00">
                        </app-swisher-retail-narrow-tax-format>
                        @if (isEasEmployee) {
                            <app-eas-retail-narrow-tax-format
                                #easRetailFormat
                                class="narrow"
                                [activeProducts]="activeProducts$|async"
                                [call]="call$"
                                [signature]="signature"
                                [contact]="selectedContact"
                                [customer]="customer"
                                [employee]="employee"
                                receiptNumberExtention="01">
                            </app-eas-retail-narrow-tax-format>
                        } @else {
                            <app-eas-retail-narrow-tax-format-swisher-rep
                                class="narrow"
                                #easRetailFormat
                                [activeProducts]="activeProducts$|async"
                                [call]="call$"
                                [signature]="signature"
                                [contact]="selectedContact"
                                [customer]="customer"
                                [employee]="employee"
                                receiptNumberExtention="01">
                            </app-eas-retail-narrow-tax-format-swisher-rep>
                        }
                    } @else {
                        <app-swisher-retail-wide-tax-format
                            #swisherRetailFormat
                            class="wide"
                            [activeProducts]="activeProducts$|async"
                            [call]="call$"
                            [signature]="signature"
                            [contact]="selectedContact"
                            [customer]="customer"
                            [employee]="employee"
                            receiptNumberExtention="00">
                        </app-swisher-retail-wide-tax-format>
                        @if (isEasEmployee) {
                            <app-eas-retail-wide-tax-format
                                #easRetailFormat
                                class="wide"
                                [activeProducts]="activeProducts$|async"
                                [call]="call$"
                                [signature]="signature"
                                [contact]="selectedContact"
                                [customer]="customer"
                                [employee]="employee"
                                receiptNumberExtention="01">
                            </app-eas-retail-wide-tax-format>
                        } @else {
                            <app-eas-retail-wide-tax-format-swisher-rep
                                #easRetailFormat
                                class="wide"
                                [activeProducts]="activeProducts$|async"
                                [call]="call$"
                                [signature]="signature"
                                [contact]="selectedContact"
                                [customer]="customer"
                                [employee]="employee"
                                receiptNumberExtention="01">
                            </app-eas-retail-wide-tax-format-swisher-rep>
                        }
                    }
                } @else {
                    <app-swisher-retail-format
                        #swisherRetailFormat
                        class="narrow"
                        [activeProducts]="activeProducts$|async"
                        [call]="call$"
                        [signature]="signature"
                        [contact]="selectedContact"
                        [customer]="customer"
                        [employee]="employee"
                        receiptNumberExtention="00">
                    </app-swisher-retail-format>
                    @if (isEasEmployee) {
                        <app-eas-retail-format
                            #easRetailFormat
                            class="narrow"
                            [activeProducts]="activeProducts$|async"
                            [call]="call$"
                            [signature]="signature"
                            [contact]="selectedContact"
                            [customer]="customer"
                            [employee]="employee"
                            receiptNumberExtention="01">
                        </app-eas-retail-format>
                    } @else {
                        <app-eas-retail-format-swisher-rep
                            #easRetailFormat
                            class="narrow"
                            [activeProducts]="activeProducts$|async"
                            [call]="call$"
                            [signature]="signature"
                            [contact]="selectedContact"
                            [customer]="customer"
                            [employee]="employee"
                            receiptNumberExtention="01">
                        </app-eas-retail-format-swisher-rep>
                    }
                }
            }
        </div>
    </mat-card>
} @else {
    <mat-spinner class="mx-auto mt-5"></mat-spinner>
}
