import { DragDropModule } from "@angular/cdk/drag-drop";
import { ObserversModule } from '@angular/cdk/observers';
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { CdkTableModule } from "@angular/cdk/table";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
    MatMomentDateModule,
    MomentDateAdapter,
    MomentDateModule
} from "@angular/material-moment-adapter";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChip, MatChipsModule } from "@angular/material/chips";
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
    MatNativeDateModule,
    MatRippleModule
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from "@angular/material/tree";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { TableVirtualScrollModule } from "ng-table-virtual-scroll";
import { NgxCurrencyDirective, } from "ngx-currency";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { AddNewBusinessComponent } from "../accounts/add-new-business/add-new-business.component";
import { ActivitiesSurveysComponent } from "../accounts/call-master/stepper-call/activities-surveys/activities-surveys.component";
import { DistributionGridComponent } from "../accounts/call-master/stepper-call/distribution-grid/distribution-grid.component";
import { RetailIncentiveAgreementComponent } from "../contracts/retail-incentive-agreement/retail-incentive-agreement.component";
import { ProjectAssignmentDialogComponent } from "../details/project/project-configuration/project-assignments/project-assignment-dialog/project-assignment-dialog.component";
import { BasicDialogComponent } from "../dialogs/basic-dialog/basic-dialog.component";
import { ConfirmationDialogComponent } from "../dialogs/confirmation-dialog/confirmation-dialog.component";
import { CountDownComponent } from "../dialogs/count-down/count-down.component";
import { CreateProductsGroupDialogComponent } from "../dialogs/create-products-group-dialog/create-products-group-dialog.component";
import { DirectionsDialogComponent } from "../dialogs/directions-dialog/directions-dialog.component";
import { DistributionDialogComponent } from "../dialogs/distribution-dialog/distribution-dialog.component";
import { FilterSaveDialogComponent } from "../dialogs/filter-save-dialog/filter-save-dialog.component";
import { FinishRouteDialogComponent } from "../dialogs/finish-route-dialog/finish-route-dialog.component";
import { OrderDateOptionsDialogComponent } from "../dialogs/order-date-options-dialog/order-date-options-dialog.component";
import { ProgressSpinnerDialogComponent } from "../dialogs/progress-spinner-dialog/progress-spinner-dialog.component";
import { ShareOptionsDialogComponent } from "../dialogs/share-options-dialog/share-options-dialog.component";
import { SignaturePadComponent } from "../dialogs/signature-pad/signature-pad.component";
import { StepsInRouteDialogComponent } from "../dialogs/steps-in-route-dialog/steps-in-route-dialog.component";
import { SwisherOverlayRef } from "../overlay/swisher-overlay-ref";
import { AddProductsComponent } from "../products/add-products/add-products.component";
import { ActivitiesFilterService } from "../services/activities-filter.service";
import { ZrtFilterServiceBase } from "../services/zrt-filter.service.base";
import { ButtonListComponent } from './button-list/button-list.component';
import { BasicCardComponent } from './cards/basic-card/basic-card.component';
import { ShareTashareChartComponent } from './charts/share-tashare-chart/share-tashare-chart.component';
import { SkuBreakdownChartComponent } from "./charts/sku-breakdown-chart/sku-breakdown-chart.component";
import { MY_DATE_FORMATS } from "./constants/date-formats";
import { DigitOnlyDirective } from "./directives/ngx-digit-only";
import { NgxPrintDirective } from "./directives/print.directive";
import { ThrottledClickDirective } from "./directives/throttle-click.directive";
import { EmployeeDropdownComponent } from "./employee-dropdown/employee-dropdown.component";
import { CallActivitiesFilterComponent } from "./filters/call-activities-filter/call-activities-filter.component";
import { CallAttributesFilterComponent } from "./filters/call-attributes-filter/call-attributes-filter.component";
import { CallDateInformationFilterComponent } from "./filters/call-date-information-filter/call-date-information-filter.component";
import { CallHistoryAttributesFilterComponent } from "./filters/call-history-attributes-filter/call-history-attributes-filter.component";
import { CallPicturesActivitiesFilterComponent } from "./filters/call-pictures-activities-filter/call-pictures-activities-filter.component";
import { CallPicturesAttributesFilterComponent } from "./filters/call-pictures-attributes-filter/call-pictures-attributes-filter.component";
import { ContractInformationFilterComponent } from "./filters/contract-information-filter/contract-information-filter.component";
import { CustomerActivitiesFilterComponent } from "./filters/customer-activities-filter/customer-activities-filter.component";
import { CustomerAttributesFilterComponent } from "./filters/customer-attributes-filter/customer-attributes-filter.component";
import { CustomerDateInformationFilterComponent } from "./filters/customer-date-information-filter/customer-date-information-filter.component";
import { CustomerLocationFilterComponent } from './filters/customer-location-filter/customer-location-filter.component';
import { DailySummaryAttributesFilterComponent } from "./filters/daily-summary-attributes-filter/daily-summary-attributes-filter.component";
import { DateInformationFilterComponent } from "./filters/date-information-filter/date-information-filter.component";
import { FilterContainerComponent } from './filters/filter-container/filter-container.component';
import { MySearchesFilterComponent } from './filters/my-searches/my-searches-filter.component';
import { SpecialCoverageInformationDialogComponent } from "./filters/my-searches/special-coverage-information-dialog/special-coverage-information-dialog.component";
import { OrderExtractionFilterComponent } from "./filters/order-extraction-filter/order-extraction-filter.component";
import { OrderInformationFilterComponent } from "./filters/order-information-filter/order-information-filter.component";
import { ProductActivitiesFilterComponent } from "./filters/product-activities-filter/product-activities-filter.component";
import { ProductDateInformationFilterComponent } from "./filters/product-date-information-filter/product-date-information-filter.component";
import { ProjectActivitiesFilterComponent } from "./filters/project-activities-filter/project-activities-filter.component";
import { ProjectAssignmentsFilterComponent } from "./filters/project-assignments-filter/project-assignments-filter.component";
import { ProjectAttributesFilterComponent } from "./filters/project-attributes-filter/project-attributes-filter.component";
import { ProjectCustomerAttributesFilterComponent } from "./filters/project-customer-attributes-filter/project-customer-attributes-filter.component";
import { ProjectLocationFilterComponent } from "./filters/project-location-filter/project-location-filter.component";
import { RequestInformationFilterComponent } from "./filters/request-information-filter/request-information-filter.component";
import { RouteDateInformationFilterComponent } from "./filters/route-date-information-filter/route-date-information-filter.component";
import { RouteInformationFilterComponent } from "./filters/route-information-filter/route-information-filter.component";
import { SummaryDateInformationFilterComponent } from "./filters/summary-date-information-filter/summary-date-information-filter.component";
import { TransactionsFilterComponent } from "./filters/transactions-filter/transactions-filter.component";
import { WholesalerGroupProductsFilterComponent } from "./filters/wholesaler-group-products-filter/wholesaler-group-products-filter.component";
import { WholesalerGroupsFilterComponent } from "./filters/wholesaler-groups-filter/wholesaler-groups-filter.component";
import { GridComponent } from './grid/grid.component';
import { IconMarkerComponent } from './icon-marker/icon-marker.component';
import { AddButtonComponent } from './page-header/buttons/add-button/add-button.component';
import { ColumnVisabilityButtonComponent } from './page-header/buttons/column-visability-button/column-visability-button.component';
import { DeleteButtonComponent } from './page-header/buttons/delete-button/delete-button.component';
import { ExcelExportButtonComponent } from './page-header/buttons/excel-export-button/excel-export-button.component';
import { HeaderButtonComponent } from './page-header/buttons/header-button/header-button.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PluralPipe } from './pipes/plural.pipe';
import { PrecisionCurrency } from "./pipes/precision-currency";
import { ZrtTreeViewComponent } from './zrt-tree-view/zrt-tree-view.component';
import { OrderDetailsDialogComponent } from "./dialogs/order-details-dialog/order-details-dialog.component";
import { ValidationErrorComponent } from "./cards/validation-error/validation-error.component";

@NgModule({
    declarations: [
        ConfirmationDialogComponent,
        SignaturePadComponent,
        DistributionDialogComponent,
        DistributionGridComponent,
        OrderDateOptionsDialogComponent,
        DistributionGridComponent,
        ShareOptionsDialogComponent,
        BasicDialogComponent,
        ProgressSpinnerDialogComponent,
        FilterSaveDialogComponent,
        FinishRouteDialogComponent,
        DirectionsDialogComponent,
        StepsInRouteDialogComponent,
        NgxPrintDirective,
        DigitOnlyDirective,
        ThrottledClickDirective,
        AddNewBusinessComponent,
        AddProductsComponent,
        CreateProductsGroupDialogComponent,
        ActivitiesSurveysComponent,
        GridComponent,
        PageHeaderComponent,
        HeaderButtonComponent,
        ColumnVisabilityButtonComponent,
        ExcelExportButtonComponent,
        FilterContainerComponent,
        CustomerLocationFilterComponent,
        OrderDetailsDialogComponent,
        TransactionsFilterComponent,
        DateInformationFilterComponent,
        CallAttributesFilterComponent,
        OrderInformationFilterComponent,
        OrderExtractionFilterComponent,
        MySearchesFilterComponent,
        EmployeeDropdownComponent,
        AddButtonComponent,
        RouteDateInformationFilterComponent,
        RouteInformationFilterComponent,
        CallHistoryAttributesFilterComponent,
        CallActivitiesFilterComponent,
        RequestInformationFilterComponent,
        CallDateInformationFilterComponent,
        ContractInformationFilterComponent,
        RetailIncentiveAgreementComponent,
        ProjectAssignmentsFilterComponent,
        ProjectAssignmentDialogComponent,
        ProjectActivitiesFilterComponent,
        ProjectAttributesFilterComponent,
        ProjectLocationFilterComponent,
        PrecisionCurrency,
        ProjectCustomerAttributesFilterComponent,
        CustomerAttributesFilterComponent,
        CustomerActivitiesFilterComponent,
        CustomerDateInformationFilterComponent,
        CallPicturesAttributesFilterComponent,
        CallPicturesActivitiesFilterComponent,
        ZrtTreeViewComponent,
        WholesalerGroupsFilterComponent,
        WholesalerGroupProductsFilterComponent,
        ProductDateInformationFilterComponent,
        ProductActivitiesFilterComponent,
        SummaryDateInformationFilterComponent,
        CountDownComponent,
        DailySummaryAttributesFilterComponent,
        SpecialCoverageInformationDialogComponent,
        IconMarkerComponent,
        PluralPipe,
        ButtonListComponent,
        DeleteButtonComponent,
        BasicCardComponent,
        ShareTashareChartComponent,
        SkuBreakdownChartComponent,
        ValidationErrorComponent,
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        MatExpansionModule,
        MatCheckboxModule,
        DragDropModule,
        MatRadioModule,
        FormsModule,
        ScrollingModule,
        MatDatepickerModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        MomentDateModule,
        MatMomentDateModule,
        MatCardModule,
        MatProgressSpinnerModule,
        RouterModule,
        FontAwesomeModule,
        MatTableModule,
        MatListModule,
        MatSortModule,
        MatRippleModule,
        BrowserAnimationsModule,
        TableVirtualScrollModule,
        ObserversModule,
        MatMenuModule,
        MatChipsModule,
        MatSidenavModule,
        MatAutocompleteModule,
        AngularMultiSelectModule,
        MatStepperModule,
        CdkStepperModule,
        MatSlideToggleModule,
        MatTreeModule,
        CdkTableModule,
        NgxMaskDirective,
        NgxMaskPipe,
        NgxCurrencyDirective,
    ],
    providers: [MatChip,
        ActivitiesFilterService,
        ZrtFilterServiceBase,
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        { provide: SwisherOverlayRef, useValue: "" },
        provideNgxMask(),
    ],
    exports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        MatExpansionModule,
        MatCheckboxModule,
        DragDropModule,
        ScrollingModule,
        DistributionGridComponent,
        MatDatepickerModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        MomentDateModule,
        MatMomentDateModule,
        MatCardModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        RouterModule,
        NgxPrintDirective,
        DigitOnlyDirective,
        ThrottledClickDirective,
        FilterSaveDialogComponent,
        FinishRouteDialogComponent,
        DirectionsDialogComponent,
        StepsInRouteDialogComponent,
        AddProductsComponent,
        AddNewBusinessComponent,
        CreateProductsGroupDialogComponent,
        ActivitiesSurveysComponent,
        GridComponent,
        MatTableModule,
        MatListModule,
        MatSortModule,
        BrowserAnimationsModule,
        MatRippleModule,
        TableVirtualScrollModule,
        ObserversModule,
        MatMenuModule,
        MatChipsModule,
        MatSidenavModule,
        PageHeaderComponent,
        FilterContainerComponent,
        CustomerLocationFilterComponent,
        MatAutocompleteModule,
        OrderDetailsDialogComponent,
        TransactionsFilterComponent,
        DateInformationFilterComponent,
        CallAttributesFilterComponent,
        OrderInformationFilterComponent,
        OrderExtractionFilterComponent,
        EmployeeDropdownComponent,
        AngularMultiSelectModule,
        RouteDateInformationFilterComponent,
        RouteInformationFilterComponent,
        CallHistoryAttributesFilterComponent,
        CallActivitiesFilterComponent,
        RequestInformationFilterComponent,
        CallDateInformationFilterComponent,
        ContractInformationFilterComponent,
        RetailIncentiveAgreementComponent,
        MatStepperModule,
        CdkStepperModule,
        ProjectAssignmentsFilterComponent,
        ProjectAssignmentDialogComponent,
        ProjectActivitiesFilterComponent,
        ProjectAttributesFilterComponent,
        ProjectLocationFilterComponent,
        PrecisionCurrency,
        ProjectCustomerAttributesFilterComponent,
        CustomerAttributesFilterComponent,
        CustomerActivitiesFilterComponent,
        CustomerDateInformationFilterComponent,
        MatTreeModule,
        ZrtTreeViewComponent,
        WholesalerGroupsFilterComponent,
        WholesalerGroupProductsFilterComponent,
        ProductDateInformationFilterComponent,
        ProductActivitiesFilterComponent,
        SummaryDateInformationFilterComponent,
        DailySummaryAttributesFilterComponent,
        SpecialCoverageInformationDialogComponent,
        IconMarkerComponent,
        ButtonListComponent,
        BasicCardComponent,
        ShareTashareChartComponent,
        SkuBreakdownChartComponent,
        ValidationErrorComponent,
    ]
})
export class SharedModule {}
