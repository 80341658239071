<div #originalEasRetailReceipt *ngIf="hasEasProducts()">
    <div class="row">
        <div class="col center image-margin-top">
            <img *ngIf="printLogo" [src]="printLogo" alt="Eas Print Logo" />
        </div>
    </div>
    <div class="row">
        <div class="col center"><b>Swisher fulfillment for E-Alternative Solutions</b></div>
    </div>
    <div class="row">
        <div class="col center">
            {{swisherAddress}}
        </div>
    </div>
    <div class="row">
        <div class="col center">{{ time() | date: dateTimeFormat }}</div>
        <div *ngIf="employee" class="col center">
            {{zrtAndSalesRepName()}}
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="center">EAS State License # {{ easLicense() }}</div>
        </div>
    </div>
    <hr />
    <div>
        <div class="row">
            <div class="col center">
                {{ customer().businessAddress.name }} - Receipt #{{receiptNumber()}}
            </div>
        </div>
        <div *ngIf="stateVaporLicense()" class="row">
            <div class="col center">
                Retail State Vapor License # {{ stateVaporLicense()?.licenseNumber }}
            </div>
        </div>
        <div class="row">
            <div class="col center">Retail State OTP License # {{stateOptLicense()?.licenseNumber}}</div>
        </div>
        <div class="row">
            <div class="col center">
                {{ formattedAddress() }}
            </div>
        </div>
        <div *ngIf="customer().businessAddress?.county" class="row">
            <div class="col center">{{ customer().businessAddress?.county }}&nbsp;County</div>
        </div>
        <hr />
        <div *ngIf="easCashProducts()?.length > 0">
            <div class="row margin-right">
                <div class="col-3">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                    <b>Taxes</b>
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
            </div>
            <div class="row margin-right">
                <div class="col-3">
                    <b>Cash Sales Item</b>
                </div>
                <div class="col-1 no-side-padding">
                    <b>Qty</b>
                </div>
                <div class="col no-side-padding">
                    <b>Price</b>
                </div>
                <div class="col no-side-padding">
                    <b>State</b>
                </div>
                <div class="col no-side-padding">
                    <b>County</b>
                </div>
                <div class="col no-side-padding">
                    <b>City</b>
                </div>
                <div class="col no-side-padding">
                    <b>Disc</b>
                </div>
                <div class="col no-side-padding">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let cashProduct of easCashProducts()">
                <div class="col-3">
                    {{ cashProduct.product?.description }}
                </div>
                <div class="col-1 no-side-padding">
                    {{ cashProduct.quantity }}
                </div>
                <div class="col no-side-padding">
                    {{ (cashProduct.callProductTax?.statePrepaid ? (cashProduct.price - (cashProduct.callProductTax?.stateTaxAmount / cashProduct.quantity)) : cashProduct.price) | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ (cashProduct.callProductTax?.stateTaxAmount ?? 0) | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ (cashProduct.callProductTax?.countyTaxAmount ?? 0) | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ (cashProduct.callProductTax?.cityTaxAmount ?? 0) | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ cashProduct.discount | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ cashProduct.totalWithTax | currency}}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col total-display">
                    <b>Total: {{ easCashDueNow() | currency }}</b>
                </div>
            </div>
            <div class="row">
                <div class="col larger total-display">
                    <b><u>Cash Due Now: {{ easCashDueNow() | currency }}</u></b>
                </div>
            </div>
            <br />
        </div>
        <div *ngIf="easGratisProducts()?.length > 0">
            <div class="row margin-right">
                <div class="col-3">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                    <b>Taxes</b>
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
            </div>
            <div class="row margin-right">
                <div class="col-3">
                    <b>Promotional Goods</b>
                </div>
                <div class="col-1 no-side-padding">
                    <b>Qty</b>
                </div>
                <div class="col no-side-padding">
                    <b>Price</b>
                </div>
                <div class="col no-side-padding">
                    <b>State</b>
                </div>
                <div class="col no-side-padding">
                    <b>County</b>
                </div>
                <div class="col no-side-padding">
                    <b>City</b>
                </div>
                <div class="col no-side-padding">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let gratisProduct of easGratisProducts()">
                <div class="col-3">
                    {{ gratisProduct.product?.description }}
                </div>
                <div class="col-1 no-side-padding">
                    {{ gratisProduct.quantity }}
                </div>
                <div class="col no-side-padding">
                    {{ (gratisProduct.callProductTax?.statePrepaid ? (gratisProduct.value - (gratisProduct.callProductTax?.stateTaxAmount / gratisProduct.quantity)) : gratisProduct.value) | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ (gratisProduct.callProductTax?.stateTaxAmount ?? 0) | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ (gratisProduct.callProductTax?.countyTaxAmount ?? 0) | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ (gratisProduct.callProductTax?.cityTaxAmount ?? 0) | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ gratisProduct.totalWithTax | currency}}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col total-display">
                    <b>Promotional Goods Total:
                        {{ easGratisTotal() | currency }}</b>
                </div>
            </div>
            <br />
        </div>
        <div *ngIf="easExchangeOutProducts()?.length > 0">
            <div class="row margin-right">
                <div class="col-3">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                    <b>Taxes</b>
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
            </div>
            <div class="row margin-right">
                <div class="col-3">
                    <b>Exchange Out Item</b>
                </div>
                <div class="col-1 no-side-padding">
                    <b>Qty</b>
                </div>
                <div class="col no-side-padding">
                    <b>Price</b>
                </div>
                <div class="col no-side-padding">
                    <b>State</b>
                </div>
                <div class="col no-side-padding">
                    <b>County</b>
                </div>
                <div class="col no-side-padding">
                    <b>City</b>
                </div>
                <div class="col no-side-padding">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let outProduct of easExchangeOutProducts()">
                <div class="col-3">
                    {{ outProduct.product?.description }}
                </div>
                <div class="col-1 no-side-padding">
                    {{ outProduct.quantity}}
                </div>
                <div class="col no-side-padding">
                    {{ (outProduct.callProductTax?.statePrepaid ? (outProduct.price + (outProduct.callProductTax?.stateTaxAmount / outProduct.quantity)) : outProduct.price) | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ (outProduct.callProductTax?.stateTaxAmount ?? 0 * -1) | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ (outProduct.callProductTax?.countyTaxAmount ?? 0 * -1) | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ (outProduct.callProductTax?.cityTaxAmount ?? 0 * -1) | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ outProduct.totalWithTax | currency}}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col total-display">
                    <b>Exchange Out Total:
                        {{ easExchangeOutTotal() | currency }}</b>
                </div>
            </div>
            <br />
        </div>
        <div *ngIf="easExchangeInProducts()?.length > 0">
            <div class="row margin-right">
                <div class="col-3">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                    <b>Taxes</b>
                </div>
                <div class="col no-side-padding">
                </div>
                <div class="col no-side-padding">
                </div>
            </div>
            <div class="row margin-right">
                <div class="col-3">
                    <b>Exchange In Item</b>
                </div>
                <div class="col-1 no-side-padding">
                    <b>Qty</b>
                </div>
                <div class="col no-side-padding">
                    <b>Price</b>
                </div>
                <div class="col no-side-padding">
                    <b>State</b>
                </div>
                <div class="col no-side-padding">
                    <b>County</b>
                </div>
                <div class="col no-side-padding">
                    <b>City</b>
                </div>
                <div class="col no-side-padding">
                    <b>Total</b>
                </div>
            </div>
            <div class="row margin-right" *ngFor="let inProduct of easExchangeInProducts()">
                <div class="col-3">
                    {{ inProduct.product?.description }}
                </div>
                <div class="col-1 no-side-padding">
                    {{ inProduct.quantity }}
                </div>
                <div class="col no-side-padding">
                    {{ (inProduct.callProductTax?.statePrepaid ? (inProduct.price - (inProduct.callProductTax?.stateTaxAmount / inProduct.quantity)) : inProduct.price) | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ (inProduct.callProductTax?.stateTaxAmount ?? 0) | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ (inProduct.callProductTax?.countyTaxAmount ?? 0) | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ (inProduct.callProductTax?.cityTaxAmount ?? 0) | currency }}
                </div>
                <div class="col no-side-padding">
                    {{ inProduct.totalWithTax | currency}}
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col total-display">
                    <b>Exchange In Total: {{ easExchangeInTotal() | currency }}</b>
                </div>
            </div>
            <br />
        </div>
    </div>
    <div class="row">
        <div class="col">
            Salesrep: ({{salesRepName()}})
        </div>
    </div>
    <div class="row">
        <div class="col larger">
            <b>Customer Representative:</b>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col">
            <img *ngIf="signature()" [src]="signature()" alt="Signature" width="400" height="100" />
            <hr class="no-top-margin" />
        </div>
    </div>
    <div class="row">
        <div class="col">Signature ({{contactName()}})</div>
        <br />
    </div>
    <div class="row">
        <div class="col center">
            All {{ state().name }} state cigarette and/or tobacco product taxes
        </div>
    </div>
    <div class="row">
        <div class="col center">
            are included in the total amount of this invoice
        </div>
    </div>
    <div class="row">
        <div class="col center">
            Falsification of this form is grounds for immediate dismissal
        </div>
    </div>
    <div class="row">
        <div class="col center">Receipt #{{receiptNumber()}}</div>
    </div>
    <hr />
</div>
