import { Injectable } from '@angular/core';
import { SharedHelper } from 'shield.shared';
import { Customer } from 'src/app/entity-models/customer.entity';
import { WholesalerGroupProductCatalogItem } from 'src/app/entity-models/wholesaler-group-product-catalog-item.entity';
import { CustomerDelineationService } from 'src/app/services/delineation-services/customer-delineation.service';
import { WholesalerGroupMemberDelineationService } from 'src/app/services/delineation-services/wholesaler-group-member-delineation.service';
import { WholesalerGroupProductCatalogItemDelineationService } from 'src/app/services/delineation-services/wholesaler-group-product-catalog-item-delineation.service';

export type WholesalerProductCatalogMap = {
    [id: string]: {
        wholesaler: Customer,
        groupItems: WholesalerGroupProductCatalogItem[]
    }
};
@Injectable()
export class ProductCatalogHelperService {

    constructor(
        private customerDelineationService: CustomerDelineationService,
        private groupMemberService: WholesalerGroupMemberDelineationService,
        private groupCatalogItemService: WholesalerGroupProductCatalogItemDelineationService,
    ) { }

    /**
     * Returns a map containing a list of wholesalers and their associated
     * product catalog items.
     * @param wholesalersOrIds The list of wholesalers (Or their ids) to look up.
     * @returns
     */
    async getByWholesalers<T extends (string | Customer)>(wholesalersOrIds: T[]): Promise<WholesalerProductCatalogMap> {
        if(wholesalersOrIds.length === 0) {
            return {};
        }
        let wholesalers: Customer[];
        if (!(wholesalersOrIds[0] instanceof Customer)) {
            wholesalers = (await this.customerDelineationService.getByIds(
                wholesalersOrIds as string[])
            ).values;
        } else {
            wholesalers = wholesalersOrIds as Customer[];
        }
        const wholesalerIds = wholesalers.map(w => w.id);
        const wholesalerGroups = (
            await this.groupMemberService.getByWholesalerIds(wholesalerIds)
        ).values;
        const catalogItems = (await Promise.all(
            wholesalerGroups.map(
                wg => this.groupCatalogItemService.getByWholesalerGroupId(wg.wholesalerGroupId)
            ))).map(resp => resp.values);

        return SharedHelper.toDictionary(
            wholesalerIds,
            id => id,
            id => {
                const wholesalerGroup = wholesalerGroups.find(g => g.wholesalerId === id);
                return {
                    wholesaler: wholesalers.find(w => w.id === id),
                    groupItems: catalogItems.find(
                        gciArr => gciArr[0].wholesalerGroupId === wholesalerGroup.wholesalerGroupId
                    ),
                };
            }
        );
    }

}
