import { ChangeDetectorRef, Component, computed, ElementRef, HostListener, OnDestroy, OnInit, signal, TemplateRef, ViewChild } from "@angular/core";
import { BehaviorSubject, Subscription, switchMap, tap } from "rxjs";

import { AppStateService } from "src/app/services/app-state.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { CallService } from "../../call-services/call.service";

import { Contact } from "src/app/entity-models/contact.entity";
import { Customer } from "src/app/entity-models/customer.entity";

import { RetailStepperStep } from "src/app/enums/retail-stepper-step";
import { ContactComponent } from "../../../contact/contact.component";
import { ContactViewModel } from "../../../contact/contact.viewmodel";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import { CallTypes, LicenseTypes, newSequentialId, Subsidiary, SystemInformationKeys } from "shield.shared";
import { ErrorLevel } from "src/app/accounts/account-enums/error-level";
import { CallValidationViewmodel } from "src/app/accounts/account-models/call-validation.viewmodel";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { SignaturePadComponent } from "src/app/dialogs/signature-pad/signature-pad.component";
import { SignaturePadViewModal } from "src/app/dialogs/signature-pad/signature-pad.viewmodel";
import { Address } from "src/app/entity-models/address.entity";
import { CallReceipt } from "src/app/entity-models/call-receipt";
import { CallReceiptLicense } from "src/app/entity-models/call-receipt-license.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { Receipt } from "src/app/entity-models/receipt";
import { ReceiptSettings } from "src/app/entity-models/receipt-settings.entity";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { RmWholesaleCall } from "src/app/entity-models/rm-wholesale-call.entity";
import { DexieTableNames } from "src/app/enums/dexie-table-names";
import { ReceiptSelectedFormat } from "src/app/enums/receipt-selected-format";
import { ReceiptType } from "src/app/enums/receipt-type";
import { RmWholesaleStepperStep } from "src/app/enums/rm-wholesale-stepper-step";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { ContactDelineationService } from "src/app/services/delineation-services/contact-delineation.service";
import { ReceiptDelineationService } from "src/app/services/delineation-services/receipt-delineation.service";
import { ReceiptSettingsDelineationService } from "src/app/services/delineation-services/receipt-settings-delineation.service";
import { SystemInformationDelineationService } from "src/app/services/delineation-services/system-information-delineation.service";
import { DialogService } from "src/app/services/dialog.service";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { ValidationErrorMessage } from "src/app/shared/cards/validation-error/validation-error.component";
import { ValidationStops } from "../../../account-enums/validation-stops";
import { CallValidationService } from "../../../account-services/call-validation.service";
import { CustomerStateService } from "../../../account-services/customer-state.service";
import { CallEventLoggerService, ReceiptRenderLocation, ReceiptRenderType } from "../../call-services/call-event-logger.service";
import { StepperCallApplicationService } from "../stepper-call-services/stepper-call-application.service";
import { ReceiptAddressViewModel } from "./receipt-address.viewmodel";
import { RetailReceiptComponent } from "./retail-receipt/retail-receipt.component";
import { WholesaleReceiptComponent } from "./wholesaler-receipt/wholesale-receipt.component";

export enum DisplayType {
    Preview,
    Render,
}

/**
 * Indicates the smallest width (exclusive) that the "wide" receipt format will display in preview.
 */
const DISPLAY_WIDE_RECEIPT_MIN_SCREEN_WIDTH = 1366;

@Component({
    selector: "app-receipts",
    templateUrl: "./receipts.component.html",
    styleUrls: ["./receipts.component.scss"]
})
export class ReceiptsComponent implements OnInit, OnDestroy {
    @ViewChild("retailReceipt") retailReceipt: RetailReceiptComponent;
    @ViewChild("wholesaleReceipt") wholesaleReceipt: WholesaleReceiptComponent;
    @ViewChild("retailReceiptCapture") retailReceiptCapture: RetailReceiptComponent;
    @ViewChild("wholesaleReceiptCapture") wholesaleReceiptCapture: WholesaleReceiptComponent;
    @ViewChild("screen", { static: true }) screen: Screen;
    @ViewChild("wholesalerFinalPrintButton") wholesalerFinalPrintButton: ElementRef;
    @ViewChild("ngxPrintWholesalerDraft") ngxPrintWholesalerDraft: ElementRef;
    @ViewChild("ngxPrintWholesalerFinal") ngxPrintWholesalerFinal: ElementRef;
    @ViewChild("ngxPrintRetailFinal") ngxPrintRetailFinal: ElementRef;
    @ViewChild("spinnerMessageTemplate", {read: TemplateRef}) spinnerMessageTemplate: TemplateRef<any>;

    //Public vars
    contactOptions: Contact[] = [];
    //selectedContact: Contact;
    customer: Customer;
    customerSubscription: Subscription;
    selectedIndexSubscription: Subscription;
    retailCallSubscription: Subscription;
    selectedIndex: RetailStepperStep | RmWholesaleStepperStep;
    call: RetailCall | RmWholesaleCall;
    narrowRecieptFormat = "/assets/css/narrow-receipt-print.css";
    wideRecieptFormat = "/assets/css/wide-receipt-print.css";
    selectedFormat = signal<ReceiptSelectedFormat>(ReceiptSelectedFormat.narrow);
    formatOptions = ["Narrow", "Wide"];
    retailStyleSheet = this.narrowRecieptFormat;
    wholesaleStyleSheet = this.narrowRecieptFormat;
    customerReceiptAddressOptions: ReceiptAddressViewModel[];
    selectedCustomerReceiptAddress: ReceiptAddressViewModel;
    signatureWidth = 400;
    signatureHeight = 100;
    signature: string;
    contactOverlayRef: SwisherOverlayRef<ContactViewModel, ContactComponent>;
    signaturePadOverlayRef: SwisherOverlayRef<
        SignaturePadViewModal,
        SignaturePadComponent
    >;
    validationErrorMessages = signal<ValidationErrorMessage[]>([]);
    screenWidth = signal<number>(window.screen.width);
    narrowOnly = computed(() => {
        return this.screenWidth() <= DISPLAY_WIDE_RECEIPT_MIN_SCREEN_WIDTH
            && this.selectedFormat() === ReceiptSelectedFormat.wide;
    });
    isSignatureDisabled = true;
    isRetailReceiptDisabled = true;
    isWholesaleReceiptDisabled = true;
    capturedRetailReceipt: string;
    isTaxState = false;
    employee: Employee;
    employeeSubscription: Subscription;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    showPrintDraftReceipts = true; //  default to existing behavior
    paperlessDialogShown = false;
    faInfoCircle = faInfoCircle;
    readonly DisplayType = DisplayType;

    private modalRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    get hasReceipts(): boolean {
        return (
            this.stepperCallApplicationService.hasRetailReceipts ||
            this.stepperCallApplicationService.hasWholesaleReceipts
        );
    }

    get hasRetailReceipts(): boolean {
        return this.stepperCallApplicationService.hasRetailReceipts;
    }

    get hasWholesaleReceipts(): boolean {
        return this.stepperCallApplicationService.hasWholesaleReceipts;
    }

    constructor(private contactDelineationService: ContactDelineationService,
        private overlayService: OverlayService,
        private snackBarService: SnackbarService,
        public callService: CallService,
        private stepperCallApplicationService: StepperCallApplicationService,
        private callValidationService: CallValidationService,
        public customerStateService: CustomerStateService,
        private receiptSettingsDelineationService: ReceiptSettingsDelineationService,
        private receiptDelineationService: ReceiptDelineationService,
        private appStateService: AppStateService,
        private pleaseWaitService: PleaseWaitService,
        private systemInformationDelineationService: SystemInformationDelineationService,
        private dialogService: DialogService,
        private eventLogger: CallEventLoggerService,
        private changeRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.systemInformationDelineationService.getByKey(SystemInformationKeys.showPrintDraftReceipts).then(response => {
            //  only update the value if the flag was present & not NaN
            if (response?.values?.value) {
                //  any non-zero int is true
                this.showPrintDraftReceipts = parseInt(response.values.value) !== 0;
            }
        }).catch(e => console.error(e));

        this.employeeSubscription = this.appStateService.currentEmployee.subscribe(
            async (employee) => {
                this.employee = employee;
                const receiptSettingsResponse = await this.receiptSettingsDelineationService.getReceiptSettingsByEmployeeId(this.employee.id);
                if (!receiptSettingsResponse) {
                    this.shouldWait$.next(false);
                    return;
                }
                const receiptSettings = receiptSettingsResponse.values;

                if (receiptSettings) {
                    this.selectedFormat.set(receiptSettings.receiptSelectedFormat);
                    await this.onFormatChange(this.selectedFormat());
                }
            }
        );

        if (!this.customerSubscription || this.customerSubscription.closed) {
            this.customerSubscription = this.customerStateService.observableCustomer.subscribe(
                async (customer) => {
                    await this.getContactsByCustomerId(customer);
                    this.setSelectedContact();
                    if (customer) {
                        this.isTaxState = this.customerStateService.isTaxAvailable();
                    }
                }
            );
        }
        if (
            !this.selectedIndexSubscription ||
            this.selectedIndexSubscription.closed
        ) {
            this.selectedIndexSubscription = this.stepperCallApplicationService.observableSelectedIndex.subscribe(
                (selectedIndex) => {
                    this.selectedIndex = selectedIndex;
                    if (
                        selectedIndex === RetailStepperStep.receipts &&
                        this.call
                    ) {
                        this.stepperCallApplicationService.shouldBuildProducts = true;

                        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

                        setTimeout(async () => {
                            await this.buildUpObjects();
                            this.shouldWait$.next(false);
                        }, 0);
                    }
                }
            );
        }

        if (
            !this.retailCallSubscription ||
            this.retailCallSubscription.closed
        ) {
            this.retailCallSubscription = this.callService.observableCall.subscribe(
                async (call) => {
                    if (call) {
                        if ((call.callType === CallTypes.retail && this.selectedIndex === RetailStepperStep.receipts)
                            || (call.callType === CallTypes.rmWholesale && this.selectedIndex === RmWholesaleStepperStep.receipts)) {
                            this.call = call;
                            await this.buildUpObjects();
                            this.shouldWait$.next(false);
                        }
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (this.customerSubscription && !this.customerSubscription.closed) {
            this.customerSubscription.unsubscribe();
        }

        if (
            this.selectedIndexSubscription &&
            !this.selectedIndexSubscription.closed
        ) {
            this.selectedIndexSubscription.unsubscribe();
        }

        if (
            this.employeeSubscription &&
            !this.employeeSubscription.closed
        ) {
            this.employeeSubscription.unsubscribe();
        }

        if (
            this.retailCallSubscription &&
            !this.retailCallSubscription.closed
        ) {
            this.retailCallSubscription.unsubscribe();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.screenWidth.set(event.target.innerWidth);
    }

    //events
    async onFormatChange(newFormat: ReceiptSelectedFormat): Promise<void> {
        this.selectedFormat.set(newFormat);
        const receiptSettings = new ReceiptSettings();
        receiptSettings.employeeId = this.employee.id;
        receiptSettings.receiptSelectedFormat = newFormat;
        await this.receiptSettingsDelineationService.persist(receiptSettings, DexieTableNames.receiptSettings);

        switch (newFormat) {
            case ReceiptSelectedFormat.narrow:
                this.retailStyleSheet = this.narrowRecieptFormat;
                this.wholesaleStyleSheet = this.narrowRecieptFormat;
                break;
            case ReceiptSelectedFormat.wide:
                this.retailStyleSheet = this.wideRecieptFormat;
                this.wholesaleStyleSheet = this.wideRecieptFormat;
                break;
            default:
                break;
        }
    }

    openModal(retail: boolean, print: boolean): void {
        if (this.modalRef) {
            this.modalRef.close();
        }

        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation";
        data.message = `${print ? "Printing" : "Emailing"} the final receipt will lock transactions and you will not be able to make any changes, are you sure you want to proceed?`;
        data.buttonLeftText = "No";
        data.buttonLeftFunction = () => this.modalRef.close();
        data.buttonRightText = "Yes";
        data.buttonRightFunction = () => void this.printReceipt(retail, print);


        this.modalRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.modalRef.afterClosed$.subscribe(() => {
            this.modalRef = undefined;
        });

    }

    async printReceipt(retail: boolean, print: boolean) {

        //Show dialog to confirm that they want to print receipts instead of emailing them.
        if (print && !this.paperlessDialogShown) {
            this.dialogService.showConfirmDialog(
                `In efforts to go paperless, save time and resources we highly encourage you to push
                for emailing receipts rather than printing them. Would you like to try emailing
                receipt(s) now?`,
                "Paperless Receipts",
                "No",
                "Yes"
            )
                .pipe(
                    tap(() => this.paperlessDialogShown = true),
                    switchMap((response) => this.performPrint(retail, !response))
                )
                .subscribe();
        } else {
            await this.performPrint(retail, print);
        }
    }

    private async performPrint(retail: boolean, print: boolean) {
        if (retail && print) {
            await this.validationRetailReceiptPrint();
        }
        if (retail && !print) {
            await this.validationRetailReceiptEmail();
        }
        if (!retail && print) {
            await this.onPrintWholesaleFinal(true);
        }
        if (!retail && !print) {
            await this.onPrintWholesaleFinal(false);
        }

        this.eventLogger.logReceiptRender(
            print ?
                ReceiptRenderType.Print :
                ReceiptRenderType.Email,
            ReceiptRenderLocation.CallSave,
            this.call,
            this.customer
        );
        this.modalRef.close();
    }

    async onPrintRetailDraft(): Promise<void> {
        //Only set the capturable receipt into draft mode, not the display one.
        this.retailReceiptCapture.isRetailDraft = true;
        this.changeRef.detectChanges();
        this.pleaseWaitService.withSpinnerShowing(async () => {
            await Helper.timeOutAsPromise(async () => {
                const images = await Promise.all(
                    this.retailReceiptCapture
                        .draftComponent()
                        .draftReceiptElements()
                        .map(e => this.generateReceiptPdfDataUrl(e.nativeElement))
                );
                if (images.length > 0) {
                    await Helper.addIFrameImage(document, images);
                }
                this.onCompletePrintRetailDraft();
            }, 1000);
        });
    }

    onCompletePrintRetailDraft(): void {
        setTimeout(() => {
            this.retailReceiptCapture.isRetailDraft = false;
        }, 0);
    }

    async onCompletePrintWholesaleDraft(): Promise<void> {
        await Helper.timeOutAsPromise(async () => {
            this.wholesaleReceiptCapture.isWholesaleDraft = false;
            await this.wholesaleReceipt.buildViewmodels();
        }, 0);
    }

    async onPrintWholesaleFinal(print = true): Promise<void> {
        this.wholesaleReceipt.isWholesaleFinal = true;
        this.wholesaleReceiptCapture.isWholesaleFinal = true;
        await Helper.timeOutAsPromise(async () => {
            print ? await this.validationWholesaleReceiptPrint() : await this.validationWholesaleReceiptsEmail();
        }, 0);
    }

    async combinedWholesaleFunctions(): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        this.wholesaleReceipt.isWholesaleFinal = true;
        this.callService.isFinalWholesaleReceiptPrinted = true;
        await this.captureReceipt(ReceiptType.wholesale);
        this.shouldWait$.next(false);
    }

    onCompletePrintEmailRetailFinal(): void {
        setTimeout(() => {
            this.retailReceipt.isRetailFinal = false;
            this.retailReceiptCapture.isRetailFinal = false;
        }, 0);
    }

    onCompletePrintWholesaleFinal(): void {
        setTimeout(() => {
            this.wholesaleReceipt.isWholesaleFinal = false;
            this.wholesaleReceiptCapture.isWholesaleFinal = false;
        }, 0);
    }
    async onSelectedContact(): Promise<void> {
        this.setSelectedContact();
        await this.callService.saveCallAndNotify();
    }

    //Public methods
    async buildUpObjects(): Promise<void> {
        if (this.callService.call
            && (
                (this.selectedIndex === RetailStepperStep.receipts
                    && this.callService.call?.callType === CallTypes.retail)
                || (this.selectedIndex === RmWholesaleStepperStep.receipts
                    && this.callService.call?.callType === CallTypes.rmWholesale)
            )
        ) {
            if (this.stepperCallApplicationService.shouldBuildProducts) {
                this.stepperCallApplicationService.shouldBuildProducts = false;
                await this.stepperCallApplicationService.buildOrderProductViewModel();
                this.stepperCallApplicationService.buildCashProductViewModel();
                this.stepperCallApplicationService.buildGratisProductViewModel();
                this.stepperCallApplicationService.buildProductInViewModel();
                this.stepperCallApplicationService.buildProductOutViewModel();
            }

            if (this.callService.call && !this.callService.call.receiptBase) {
                this.callService.call.receiptBase = Math.floor(
                    new Date().getTime() / 1000.0
                );
                await this.callService.saveCallAndNotify();
            }

            if (this.callService.call) {
                this.signature = this.callService.call.signature;
                this.runValidation();
            }
            //this.setReceiptElementReferences();
        }
    }

    setReceiptElementReferences(): void {
        if (!this.isTaxState) {
            if (this.retailReceipt &&
                !this.retailReceipt?.originalSwisherRetailReceipt &&
                this.retailReceipt?.swisherRetailFormat?.originalSwisherRetailReceipt) {
                this.retailReceipt.originalSwisherRetailReceipt = this.retailReceipt.swisherRetailFormat?.originalSwisherRetailReceipt;
            }

            if (this.retailReceipt &&
                !this.retailReceipt?.originalEasRetailReceipt &&
                this.retailReceipt.easRetailFormat?.originalEasRetailReceipt) {
                this.retailReceipt.originalEasRetailReceipt = this.retailReceipt.easRetailFormat?.originalEasRetailReceipt;
            }

            if (this.wholesaleReceipt &&
                !this.wholesaleReceipt?.swisherWholesaleFormat &&
                this.wholesaleReceipt.swisherWholesaleFormat?.originalSwisherWholesaleReceipt) {
                this.wholesaleReceipt.originalSwisherWholesaleReceipt = this.wholesaleReceipt.swisherWholesaleFormat?.originalSwisherWholesaleReceipt;
            }

            if (this.wholesaleReceipt &&
                !this.wholesaleReceipt?.easWholesaleFormat &&
                this.wholesaleReceipt.easWholesaleFormat?.originalEasWholesaleReceipt) {
                this.wholesaleReceipt.originalEasWholesaleReceipt = this.wholesaleReceipt.easWholesaleFormat?.originalEasWholesaleReceipt;
            }
        }
    }

    setSelectedContact(): void {
        if (
            (
                (this.selectedIndex === RetailStepperStep.receipts
                    && this.callService.call?.callType === CallTypes.retail)
                || (this.selectedIndex === RmWholesaleStepperStep.receipts
                    && this.callService.call?.callType === CallTypes.rmWholesale
                )
            )
            && this.call?.selectedContact) {
            this.call.selectedContact = this.contactOptions.find((c) => c.id === this.call.selectedContact.id);
            this.callService.call.selectedContact = this.call.selectedContact;
        }
    }

    async generateReceiptPdfDataUrl(htmlElement: HTMLElement): Promise<string> {
        const DEFAULT_WIDTH = 580;
        const DEFAULT_HEIGHT = 660;
        const width = Math.max(htmlElement.offsetWidth, htmlElement.scrollWidth);
        const height = Math.max(htmlElement.offsetHeight, htmlElement.scrollHeight);
        const settings = {
            width: width + 20 < DEFAULT_WIDTH ? DEFAULT_WIDTH : width + 20,
            height: height + 40 < DEFAULT_HEIGHT ? DEFAULT_HEIGHT : height + 40,
            margin: [10, 10, 10, 10],
            scale: 1,
        }

        const pdf = new jsPDF("p", "px", [settings.width, settings.height], true);
        const pdfConf = {
            margin: settings.margin,
            html2canvas: { logging: false, scale: settings.scale, allowTaint: true, useCORS: true, imageTimeout: 0 }
        }

        return new Promise((resolve, reject) => {
            pdf.html(htmlElement, pdfConf).then(() => {
                const output = pdf.output("dataurlstring");
                resolve(output);
            }, (e) => reject(e));
        });
    }

    async captureReceipt(type: ReceiptType, email?: boolean): Promise<void> {
        return this.pleaseWaitService.withSpinnerShowing(async () => {
            await Helper.timeOutAsPromise(async () => {
                try {
                    if (type === ReceiptType.retail) {
                        await this.captureRetailReceipt();
                    } else {
                        await this.captureWholesaleReceipt(email);
                    }
                } catch (e) {
                    console.error(e);
                    this.snackBarService.showError("An error occurred while capturing receipt. Please try again.");
                }
            }, 100);
        }, null, this.spinnerMessageTemplate);
    }

    private async captureRetailReceipt(): Promise<void> {
        if (this.callService.call.callType === CallTypes.retail
            || this.callService.call.callType === CallTypes.rmWholesale
        ) {

            this.callService.call.callReceipts ??= [];
            const isOriginal = this.callService.call.callReceipts.filter((cr) => !cr.wholesalerId).length === 0;
            const images = new Array<string>();
            const receiptElements: [Subsidiary, ElementRef<HTMLElement> | undefined][] = [
                [Subsidiary.Swisher, this.retailReceiptCapture.swisherRetailFormat.originalSwisherRetailReceipt],
                [Subsidiary.EAS, this.retailReceiptCapture.easRetailFormat.originalEasRetailReceipt],
            ];
            try {
                this.changeRef.detach();
                for (const [subsidiary, element] of receiptElements) {
                    if (!element?.nativeElement) {
                        continue;
                    }
                    if (isOriginal) {
                        const receipt = new Receipt();
                        receipt.id = newSequentialId();
                        if (subsidiary !== Subsidiary.Swisher && subsidiary !== Subsidiary.EAS) throw new Error("Invalid subsidiary");

                        const callReceipt = new CallReceipt();
                        callReceipt.id = receipt.id;
                        callReceipt.callId = this.callService.call.id;
                        callReceipt.receiptNumber = this.employee.zrt + (this.callService.call as RetailCall).receiptBase.toString();
                        callReceipt.receiptNumberExtention = subsidiary === Subsidiary.Swisher ? "00" : "01";
                        callReceipt.isOriginal = true;


                        const callReceiptLicenses = new Array<CallReceiptLicense>();

                        const customerLicenses = this.customer.customerLicenses ??= [];
                        const retailStateOptLicense = customerLicenses.find((l) => l.licenseTypeId === LicenseTypes.RetailStateOTPLicense && l.isActive);
                        const retailStateVaporLicense = customerLicenses.find((l) => l.licenseTypeId === LicenseTypes.RetailStateVaporLicense && l.isActive);

                        if (retailStateOptLicense) {
                            const callReceiptLicense = new CallReceiptLicense();
                            callReceiptLicense.id = newSequentialId();
                            callReceiptLicense.callReceiptId = callReceipt.id;
                            callReceiptLicense.licenseNumber = retailStateOptLicense.licenseNumber;
                            callReceiptLicense.licenseTypeId = LicenseTypes.RetailStateOTPLicense;

                            callReceiptLicenses.push(callReceiptLicense);
                        }

                        if (retailStateVaporLicense) {
                            const callReceiptLicense = new CallReceiptLicense();
                            callReceiptLicense.id = newSequentialId();
                            callReceiptLicense.callReceiptId = callReceipt.id;
                            callReceiptLicense.licenseNumber = retailStateOptLicense.licenseNumber;
                            callReceiptLicense.licenseTypeId = LicenseTypes.RetailStateVaporLicense;

                            callReceiptLicenses.push(callReceiptLicense);
                        }

                        callReceipt.callReceiptLicenses = callReceiptLicenses

                        receipt.base64Image = await this.generateReceiptPdfDataUrl(
                            element.nativeElement
                        );
                        if (!receipt.base64Image || receipt.base64Image.length === 0) {
                            throw new Error("Receipt image failed to generate.")
                        }
                        images.push(receipt.base64Image);
                        this.callService.call.callReceipts ??= [];
                        this.callService.call.callReceipts.push(callReceipt);
                        await this.receiptDelineationService.persist(receipt, DexieTableNames.receipts);
                        await this.callService.saveCallAndNotify();
                    } else {
                        const image = await this.generateReceiptPdfDataUrl(
                            element.nativeElement
                        );
                        images.push(image);
                    }
                }
            } finally {
                this.changeRef.reattach();
            }

            await Helper.timeOutAsPromise(async () => {
                await Helper.addIFrameImage(document, images);
            }, 0);

            this.onCompletePrintEmailRetailFinal();
        }
        this.shouldWait$.next(false);
    }

    async onPrintWholesaleDraft(): Promise<void> {
        //Only set the capturable receipt into draft mode, not the display one.
        this.wholesaleReceiptCapture.isWholesaleDraft = true;
        this.changeRef.detectChanges();
        await this.pleaseWaitService.withSpinnerShowing(async () => {
            await Helper.timeOutAsPromise(async () => {
                const draftReceiptElements = this.wholesaleReceiptCapture.draftWholesaleElements();
                if (draftReceiptElements.length === 0) {
                    return;
                }
                const images: string[] = [];
                try {
                    for (const draftElementRef of draftReceiptElements) {
                        if (!draftElementRef?.nativeElement) {
                            continue;
                        }
                        images.push(
                            await this.generateReceiptPdfDataUrl(draftElementRef.nativeElement)
                        );
                    }
                } finally {
                    await this.onCompletePrintWholesaleDraft();
                }
                if (images.length > 0) {
                    await Helper.addIFrameImage(document, images);
                }
            }, 500);
        });
    }

    private async captureWholesaleReceipt(email?: boolean): Promise<void> {
        if (!email) {
            this.wholesalerFinalPrintButton?.nativeElement?.click();
        }

        if (this.callService.call.callType === CallTypes.retail
            || this.callService.call.callType === CallTypes.rmWholesale
        ) {
            (this.callService.call as RetailCall).callReceipts ??= [];
            const isOriginal = (this.callService.call as RetailCall).callReceipts.filter((cr) => cr.wholesalerId).length === 0;
            const images = new Array<string>();
            const elementRefs = [...this.wholesaleReceiptCapture.swisherWholesaleComponents(),
            ...this.wholesaleReceiptCapture.easWholesaleComponents(),
            ].map(c => c.elementRef);
            try {
                this.changeRef.detach();
                for (const elementRef of elementRefs) {

                    if (this.callService.call.callType === CallTypes.retail
                        || this.callService.call.callType === CallTypes.rmWholesale) {

                        const callReceipt = new CallReceipt();
                        const receipt = new Receipt();
                        callReceipt.id = newSequentialId();
                        receipt.id = callReceipt.id;
                        callReceipt.callId = this.callService.call.id;
                        callReceipt.receiptNumber = this.employee.zrt +
                            this.callService.call?.receiptBase?.toString();
                        callReceipt.isOriginal = isOriginal;

                        callReceipt.saleUserId = this.employee.id;
                        callReceipt.saleUserZrt = this.employee.zrt;
                        callReceipt.saleUserFullName = this.employee.fullName;
                        if (this.customer.businessAddress) {
                            callReceipt.address1 = this.customer.businessAddress.address1;
                            callReceipt.address2 = this.customer.businessAddress.address2;
                            callReceipt.city = this.customer.businessAddress.city;
                            callReceipt.county = this.customer.businessAddress.county;
                            callReceipt.state = this.customer.businessAddress.state;
                            callReceipt.zip = this.customer.businessAddress.zip;
                            callReceipt.country = this.customer.businessAddress.country;
                        }
                        receipt.base64Image = await this.generateReceiptPdfDataUrl(elementRef?.nativeElement);
                        if (!receipt.base64Image || receipt.base64Image.length === 0) {
                            throw new Error("Receipt image failed to generate.")
                        }
                        images.push(receipt.base64Image);
                        const nextReceiptExtention = this.getNextReceiptNumberExtention();

                        callReceipt.receiptNumberExtention = nextReceiptExtention.toString().padStart(2, "0");
                        const wholeReceiptNumber = callReceipt.receiptNumber
                            + callReceipt.receiptNumberExtention;
                        let vm = this.wholesaleReceiptCapture.swisherViewModels()
                            .find((svm) => svm.receiptNumber === wholeReceiptNumber);
                        if (!vm) {
                            vm = this.wholesaleReceiptCapture.easViewModels()
                                .find((easVm) => easVm.receiptNumber === wholeReceiptNumber);
                        }
                        if (!vm) {
                            console.log("Can not find wholesaler to associate with the receipt.");
                            continue;
                        }
                        callReceipt.callReceiptLicenses ??= [];
                        if (vm.retailOptLicense) {
                            vm.retailOptLicense.callReceiptId = callReceipt.id;
                            callReceipt.callReceiptLicenses.push(vm.retailOptLicense);
                        }
                        if (vm.retailVaporLicense) {
                            vm.retailVaporLicense.callReceiptId = callReceipt.id;
                            callReceipt.callReceiptLicenses.push(vm.retailVaporLicense);
                        }
                        if (vm.wholesalerRetailOptLicense) {
                            vm.wholesalerRetailOptLicense.callReceiptId = callReceipt.id;
                            callReceipt.callReceiptLicenses.push(vm.wholesalerRetailOptLicense);
                        }

                        callReceipt.wholesalerId = vm.wholesaler.id;
                        this.callService.call.callReceipts.push(callReceipt);
                        await this.receiptDelineationService.persist(receipt, DexieTableNames.receipts);
                    }
                }
            } finally {
                this.changeRef.reattach();
            }
            if (!email && images.length > 0) {
                await Helper.addIFrameImage(document, images);
            }

            if (elementRefs) {
                await this.callService.saveCallAndNotify();
            }

            this.onCompletePrintWholesaleFinal();
        }
    }

    getNextReceiptNumberExtention(): number {
        let rtn = -1;
        if (this.callService.call.callType === CallTypes.retail
            || this.callService.call.callType === CallTypes.rmWholesale) {
            rtn = this.callService.call.callReceipts?.length > 0
                ? Math.max(
                    ...this.callService.call.callReceipts.map(
                        (rc) => {
                            const parsed = parseInt(
                                rc.receiptNumberExtention
                            );
                            return isNaN(parsed) ? 0 : parsed;
                        }
                    )
                )
                : 0;
            if (!rtn) {
                rtn = 2;
            } else {
                rtn++;
            }
        }
        return rtn
    }

    async getContactsByCustomerId(customer: Customer): Promise<void> {
        this.customer = customer;

        if (customer) {
            this.customerReceiptAddressOptions = this.getCustomerRecieptAddressOptions(
                this.customer
            );
            this.selectedCustomerReceiptAddress = this.customerReceiptAddressOptions[0];

            const response = await this.contactDelineationService.getByCustomerId(
                this.customer.id
            );

            if (!response) {
                this.shouldWait$.next(false);
                return;
            }

            await this.setContactOptions();
        }
    }

    private buildAddressOption(address: Address): ReceiptAddressViewModel {
        const rtn = new ReceiptAddressViewModel;
        let addressFormatted = "";
        if (address) {
            if (address.address1) {
                addressFormatted = addressFormatted.concat(address.address1, ", ");
            }

            if (address.address2) {
                addressFormatted = addressFormatted.concat(address.address2, ", ");
            }

            if (address.city) {
                addressFormatted = addressFormatted.concat(address.city, ", ");
            }

            if (address.state) {
                addressFormatted = addressFormatted.concat(address.state, ", ");
            }

            if (address.zip) {
                addressFormatted = addressFormatted.concat(address.zip);
            }
        }
        rtn.addressName = address.name ?? "";
        rtn.addressFormatted = addressFormatted;

        return rtn;
    }

    getCustomerRecieptAddressOptions(customer: Customer): ReceiptAddressViewModel[] {
        const rtn: ReceiptAddressViewModel[] = [];

        if (customer) {
            if (customer.businessAddress) {
                const address = this.buildAddressOption(
                    customer.businessAddress
                );
                if (address) {
                    rtn.push(address);
                }
            }
            if (customer.dbaAddress) {
                const address = this.buildAddressOption(customer.dbaAddress);
                if (address) {
                    rtn.push(address);
                }
            }
        }
        return rtn;
    }

    onAddContact(): void {
        const data: ContactViewModel = new ContactViewModel();
        data.customerId = this.customer.id;
        data.headerLeftText = "Retail Call - Add Contact";

        this.contactOverlayRef = this.overlayService.open(
            ContactComponent,
            data,
            true
        );

        this.contactOverlayRef.afterClosed$
            .pipe(
                switchMap((rtnData) => rtnData ? this.setContactOptions() : Promise.resolve())
            )
            .subscribe();
    }

    async setContactOptions(): Promise<void> {
        const response = await this.contactDelineationService.getByCustomerId(
            this.customer.id
        );

        if (!response) {
            this.shouldWait$.next(false);
            return;
        }

        this.contactOptions = response.values?.filter((c) => !c.isDeleted);
    }

    openSinatureModal(): void {
        if (this.call.selectedContact) {
            const data: SignaturePadViewModal = new SignaturePadViewModal();
            data.save = () => this.onSave();
            this.signaturePadOverlayRef = this.overlayService.open(
                SignaturePadComponent,
                data
            );
        } else {
            this.snackBarService.showWarning(
                "Please select a contact before adding a signature."
            );
        }
    }

    async onSave(): Promise<void> {
        const myData: SignaturePadViewModal = this.signaturePadOverlayRef.data;

        if (myData
            && (this.callService.call?.callType === CallTypes.retail
                || this.callService.call.callType === CallTypes.rmWholesale)
        ) {
            this.signature = myData.toDataUrlPng();
            this.callService.call.signature = this.signature;
            await this.callService.saveCallAndNotify();
            this.signaturePadOverlayRef.close();
        } else {
            this.snackBarService.showWarning("Not a valid signature");
        }
    }

    private runValidation() {
        const callValidation: CallValidationViewmodel = this.callValidationService.isCallValid(
            this.callService.call.id
        );

        const errorMessages = callValidation.validationViolations
            .filter((vv) => vv.errorLevel === ErrorLevel.invalid)
            ?.map((vv) => { return { text: vv.message } });

        this.validationErrorMessages.set(errorMessages);

        this.isSignatureDisabled =
            callValidation.validationViolations.findIndex(
                (vv) =>
                    (vv.validationStops & ValidationStops.signatureHardStop) ===
                    ValidationStops.signatureHardStop
            ) !== -1;

        this.isRetailReceiptDisabled =
            callValidation.validationViolations.findIndex(
                (vv) =>
                    (vv.validationStops & ValidationStops.retailReceiptHardStop) ===
                    ValidationStops.retailReceiptHardStop
            ) !== -1;

        this.isWholesaleReceiptDisabled =
            callValidation.validationViolations.findIndex(
                (vv) =>
                    (vv.validationStops & ValidationStops.wholesaleReceiptHardStop) ===
                    ValidationStops.wholesaleReceiptHardStop
            ) !== -1;
    }

    async validationRetailReceiptPrint(): Promise<void> {
        this.callService.isFinalRetailReceiptPrinted = true;
        await this.captureReceipt(ReceiptType.retail);
    }

    async validationWholesaleReceiptPrint(): Promise<void> {
        this.callService.isFinalWholesaleReceiptPrinted = true;
        await this.captureReceipt(ReceiptType.wholesale);
    }

    async validationRetailReceiptEmail(): Promise<void> {
        if (!this.call.selectedContact.email) {
            this.snackBarService.showWarning(
                "No email found for contact!  Please add one and try again."
            );
        }
        else {
            this.callService.isEmailFinalRetailReceipt = true;
            await this.captureReceipt(ReceiptType.retail);
            this.snackBarService.showInfo(
                "An email copy of this receipt will be sent to both " + this.call.selectedContact.firstName + " " + this.call.selectedContact.lastName + " and yourself."
            );
        }
    }

    async validationWholesaleReceiptsEmail(): Promise<void> {
        if (!this.call.selectedContact.email) {
            this.snackBarService.showWarning(
                "No email found for contact!  Please add one and try again."
            );
        }
        else {
            await this.captureReceipt(ReceiptType.wholesale, true);
            this.callService.isEmailFinalWholesaleReceipt = true;
            this.snackBarService.showInfo(
                "An email copy of this receipt will be sent to both " + this.call.selectedContact.firstName + " " + this.call.selectedContact.lastName + " and yourself."
            );
        }
    }

    compareContactOptions(a: Contact, b: Contact): boolean {
        return a?.id === b?.id;
    }
}
