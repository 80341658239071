<mat-card class="container-fluid" appearance="outlined">
    <div
        *ngIf="!injectedData && (((callService.call?.callType === retailCallType
                || callService.call?.callType === rmWholesaleCallType)
                && !callService.isFinalRetailReceiptPrinted
                && !callService.isEmailFinalRetailReceipt
                && this.stepperCallApplicationService.hasRetailReceipts)
            || (!callService.isFinalWholesaleReceiptPrinted
                && !callService.isEmailFinalWholesaleReceipt
                && this.stepperCallApplicationService.hasWholesaleReceipts)
            || displayValidationErrors().length > 0)">
        <validation-error
            [messages]="displayValidationErrors()"
            (click)="onJumpToIndex(step.receipts)">
        </validation-error>
    </div>
    <div class="row">
        <div class="col-12 col-xl-3">
            <div class="card grid-item card--order--transactions card--order--transactions-double mb-3">
                <div class="card-header">
                    Transactions
                    <span *ngIf="!injectedData" (click)="onJumpToIndex(step.salesGratis)" class="float-right"><a
                            class="single-icon">
                            <fa-icon [icon]="faEye"></fa-icon>
                        </a></span>
                </div>
                <div class="card-body p-0">
                    <div class="card-view-port">
                        <h2 class="m-teal mb-0 mt-3">Orders</h2>
                        <table class="table table-striped mb-1">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Total Sticks</th>
                                    <th>Total Value</th>
                                    <th>Wholesaler</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="
                                        let transaction of stepperCallApplicationService.callSalesTransactionViewmodel
                                    ">
                                    <td>{{ transaction.type }}</td>
                                    <td>{{ transaction.totalSticks }}</td>
                                    <td *ngIf="
                                            transaction.totalValue ||
                                            transaction.totalValue === 0
                                        ">
                                        {{ transaction.totalValue | currency }}
                                    </td>
                                    <td *ngIf="
                                            !transaction.totalValue &&
                                            transaction.totalValue !== 0
                                        ">
                                        $--
                                    </td>
                                    <td>{{ transaction.wholesaler }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <h2 class="m-teal mb-0 mt-3">Exchanges</h2>
                        <table class="table table-striped mb-1">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Total Sticks</th>
                                    <th>Total Retail</th>
                                    <th>Total Wholesaler</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="
                                        let transaction of stepperCallApplicationService.callExchangeTransactionViewModel
                                    ">
                                    <td>{{ transaction.type }}</td>
                                    <td>{{ transaction.totalSticks }}</td>
                                    <td>
                                        {{ transaction.totalRetail | currency }}
                                    </td>
                                    <td>
                                        {{
                                        transaction.totalWholesaler
                                        | currency
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="row mt-3">
                            <div *ngIf="receipts?.length" class="col-12 text-center">
                                <button appThrottleClick class="btn sw-red-btn btn-sm mb-3"
                                    (throttledClick)="reprint()">View Receipts
                                </button>
                                <button appThrottleClick *ngIf="receipts?.length" class="btn btn-sm btn-white-wide actionBtn mb-3"
                                    (throttledClick)="email()"
                                    title="Send Receipt">
                                    <span class="flaticon-send larger green"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-xl-6">
            <div class="card grid-item card--pictures mb-3">
                <div class="card-header">Pictures</div>
                <div class="card-body p-0">
                    <div class="card-view-port">
                        <div *ngIf="slides && slides.length > 0" class="row">
                            <div class="col-3 chevron-container padding-left-picture-type">
                                <div *ngIf="pictureType" class="picture-info-container">
                                    Picture Type: {{ pictureType }}
                                </div>
                                <span class="chevron material-icons" (click)="carousel.previous()">chevron_left</span>
                            </div>
                            <div class="col carousel-container">
                                <mat-carousel #carousel (change)="setPictureType($event)" [autoplay]="false"
                                    color="primary" [proportion]="proportion" maxWidth="400px" [slides]="slides.length"
                                    [loop]="true" [hideArrows]="true" [hideIndicators]="false" [useKeyboard]="true"
                                    [useMouseWheel]="true" orientation="ltr" (click)="openPictureInModal(pictureString, pictureType)">
                                    <mat-carousel-slide #matCarouselSlide *ngFor="
                                            let slide of slides;
                                            let i = index
                                        " [image]="slide.image" overlayColor="#00000040" [hideOverlay]="true">
                                    </mat-carousel-slide>
                                </mat-carousel>
                            </div>
                            <div class="col-3 chevron-container padding-right-picture-type">
                                <div *ngIf="pictureTags" class="picture-info-container">
                                    Picture Tags: {{ pictureTags }}
                                </div>
                                <span class="chevron material-icons" (click)="carousel.next()">chevron_right</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card no-scroll">
            <div class="card-header">
                Activities/Surveys Completed
            </div>
            <app-activities-surveys #activitiesAndSurveys
                            [isReadOnly]="true"
                            [isPanel]="true"
                            [call]="call"></app-activities-surveys>
        </div>
    </div>
        <div class="col-12 col-xl-3">
            <div class="card grid-item card--distribution mb-3">
                <div class="card-header">
                    Distribution
                    <span *ngIf="!injectedData" (click)="onJumpToIndex(step.inDistribution)" class="float-right">
                        <a class="single-icon">
                            <fa-icon [icon]="faEye"></fa-icon>
                        </a>
                    </span>
                </div>
                <div class="card-body p-0">
                    <div class="card-view-port scroll-y-only-distribution pt-2">
                        <div class="row">
                            <div class="col">
                                <h3 class="closing-notes-activity-heading unset-margin ml-2">Items Marked</h3>
                            </div>
                        </div>
                        <div class="row table-scroll">
                            <div class="col">
                                <table class="table no-cellpadding table-scroll table-striped mb-0">
                                    <thead>
                                        <tr>
                                            <th>Category</th>
                                            <th class="center">In Dist</th>
                                            <th class="center">Intro</th>
                                            <th class="center">OOS</th>
                                            <th class="center">COS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="
                                                let row of categoryDistributionViewmodels
                                            ">
                                            <td>
                                                {{ row.category }}
                                            </td>
                                            <td class="center">
                                                {{ row.inDist }}
                                            </td>
                                            <td class="center">
                                                {{ row.intro }}
                                            </td>
                                            <td class="center">
                                                {{ row.oos }}
                                            </td>
                                            <td class="center">
                                                {{ row.cos }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <table class="table table-striped mb-0">
                            <thead>
                                <tr>
                                    <!-- Total InDist, Not In Dist ect -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card grid-item card--call--comments mb-3">
                <div class="card-header">Px3 Comments</div>
                <div class="card-body p-0">
                    <div class="card-view-port">
                        <div class="row">
                            <div *ngIf="!injectedData" class="col">
                                <mat-form-field appearance="outline" color="accent" class="call-note-form-field">
                                    <mat-label id="notes">Notes</mat-label>
                                    <textarea cdkTextareaAutosize="false" matAutosizeMaxRows="12"
                                        (blur)="callService.saveClosingNotes(closingNotes)"
                                        [(ngModel)]="closingNotes" rows="6" type="text" matInput maxlength="4000">
                                    </textarea>
                                    <mat-hint align="end">{{
                                        closingNotes?.length || 0
                                        }}/4000
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                            <div *ngIf="injectedData" class="col">
                                {{ closingNotes }}
                            </div>
                        </div>
                        <div *ngIf="!injectedData" class="row">
                            <div class="col center">
                                <button appThrottleClick mat-raised-button class="btn sw-green-btn btm-sm actionBtn"
                                    [disabled]="saveAndShareDisabled" (throttledClick)="onSaveOrShare(false)">
                                    <mat-icon style="display: inline">check_circle_outline</mat-icon>
                                    Save
                                </button>
                                <button appThrottleClick mat-raised-button class="btn sw-teal-btn btn-sm actionBtn"
                                    [disabled]="saveAndShareDisabled" (throttledClick)="onSaveOrShare(true)">
                                    Share
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>
