import { Injectable } from "@angular/core";
import {
    BehaviorSubject,
    Observable
} from "rxjs";
import {
    CallTypes,
    GenericResponseDto,
    newSequentialId,
    SharedHelper
} from "shield.shared";
import { CallCashProduct } from "src/app/entity-models/call-cash-product.entity";
import { CallExchangeInProduct } from "src/app/entity-models/call-exchange-in-product.entity";
import { CallExchangeOutProduct } from "src/app/entity-models/call-exchange-out-product.entity";
import { CallGratisProduct } from "src/app/entity-models/call-gratis-product.entity";
import { CallOrderProduct } from "src/app/entity-models/call-order-product.entity";
import { CallPicture } from "src/app/entity-models/call-picture.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { Product } from "src/app/entity-models/product.entity";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { RmWholesaleCall } from "src/app/entity-models/rm-wholesale-call.entity";
import { Survey } from "src/app/entity-models/survey.entity";
import { CustomerGenericTypes } from "src/app/enums/customer-generic-types";
import { SubsidiaryTypes } from "src/app/enums/subsidiary-types";
import { Helper } from "src/app/helpers/helper";
import { AppStateService } from "src/app/services/app-state.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { PictureDelineationService } from "src/app/services/delineation-services/picture-delineation.service";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { SurveyDelineationService } from "src/app/services/delineation-services/survey-delineation.service";
import { ProductCatalogHelperService } from "src/app/services/product-catalog-helper.service";
import { WholesalerViewmodel } from "src/app/shared/viewmodels/wholesaler.viewmodel";
import { CustomerStateService } from "../../../account-services/customer-state.service";
import { Call, CallService } from "../../call-services/call.service";
import { CallCashProductViewModel } from "../../call-viewmodels/call-cash-product.viewmodel";
import { CallCategoryDistributionViewModel } from "../../call-viewmodels/call-category-distribution.viewmodel";
import { CallExchangeTransactionViewModel } from "../../call-viewmodels/call-exchange-transaction.viewmodel";
import { CallGratisProductViewModel } from "../../call-viewmodels/call-gratis-product.viewmodel";
import { CallOrderProductViewModel } from "../../call-viewmodels/call-order-product.viewmodel";
import { CallProductInViewModel } from "../../call-viewmodels/call-product-in.viewmodel";
import { CallProductOutViewModel } from "../../call-viewmodels/call-product-out.viewmodel";
import { CallSalesTransactionViewmodel } from "../../call-viewmodels/call-sales-transaction.viewmodel";

@Injectable()
export class StepperCallApplicationService {
    totalDiffRetail: number;
    totalDiffWholesale: number;
    totalDiscountPercent: number;
    shouldBuildProducts = true;
    distributionOrderProducthasBeenUpdated = false;
    salesAndGratisWholesaleHasBeenUpdated = false;
    callCashProductViewModels: CallCashProductViewModel[] = [];
    callOrderProductViewModels: CallOrderProductViewModel[] = [];
    callGratisProductViewModels: CallGratisProductViewModel[] = [];
    callProductOutViewModels: CallProductOutViewModel[] = [];
    callProductInViewModels: CallProductInViewModel[] = [];
    callSalesTransactionViewmodel: CallSalesTransactionViewmodel[] = [];
    callExchangeTransactionViewModel: CallExchangeTransactionViewModel[] = [];
    totalCashBeforeDiscount = 0;
    totalGratis = 0;
    totalCashAndGratisDiscount = 0;
    totalPaymentDue = 0;
    gratisDueNowTotal = 0;
    exchangeOutTotal = 0;
    exchangeInTotal = 0;
    cashDueNowTotal = 0;
    easGratisDueNowTotal = 0;
    easExchangeOutTotal = 0;
    easExchangeInTotal = 0;
    easCashDueNowTotal = 0;
    hasOrderableProduct = false;
    employee: Employee;

    get hasRetailReceipts(): boolean {
        let rtn = false;

        if (this.callService.call?.callType === CallTypes.retail
            || this.callService.call?.callType === CallTypes.rmWholesale) {
            rtn =
                (this.callService.call.cashProducts ??= []).length > 0 ||
                (this.callService.call.gratisProducts ??= []).length > 0 ||
                (this.callService.call.exchangeInProducts ??= []).length > 0 ||
                (this.callService.call.exchangeOutProducts ??= []).length > 0;
        }
        return rtn;
    }
    get hasWholesaleReceipts(): boolean {
        let rtn = false;

        if (this.callService.call?.callType === CallTypes.retail
            || this.callService.call?.callType === CallTypes.rmWholesale) {
            rtn =
                this.callService.call &&
                (this.callService.call.orderProducts ??= []).length > 0;
        }

        return rtn;
    }
    constructor(
        private callService: CallService,
        private productDelineationService: ProductDelineationService,
        private pictureDelineationService: PictureDelineationService,
        private customerStateService: CustomerStateService,
        private customerDelineationService: CustomerDelineationService,
        private projectDelineationService: ProjectDelineationService,
        private surveryDelineationService: SurveyDelineationService,
        private appStateService: AppStateService,
        private productCatalogHelper: ProductCatalogHelperService,
    ) {

        this.appStateService.currentEmployee.subscribe((employee) => {
            this.employee = employee;
        });

        this.productDelineationService.observableActiveProducts.subscribe(async () => {
            if (this.employee) {
                await this.buildOrderProductViewModel();
                this.buildCashProductViewModel();
                this.buildGratisProductViewModel();
                this.buildProductInViewModel();
                this.buildProductOutViewModel();
            }
        });
    }

    private _areOrdersBuilt = false;
    ordersAreBuiltSubject: BehaviorSubject<boolean> = new BehaviorSubject(this._areOrdersBuilt);
    observableOrdersAreBuilt: Observable<boolean> = this.ordersAreBuiltSubject.asObservable();
    get areOrdersBuilt(): boolean {
        return this._areOrdersBuilt;
    }
    private _isCashBuilt = false;
    cashIsBuiltSubject: BehaviorSubject<boolean> = new BehaviorSubject(this._isCashBuilt);
    observableCashBuilt: Observable<boolean> = this.cashIsBuiltSubject.asObservable();
    get isCashBuilt(): boolean {
        return this._isCashBuilt;
    }
    private _isGratisBuilt = false;
    gratisIsBuiltSubject: BehaviorSubject<boolean> = new BehaviorSubject(this._isGratisBuilt);
    observableGratisBuilt: Observable<boolean> = this.gratisIsBuiltSubject.asObservable();
    get isGratisBuilt(): boolean {
        return this._isCashBuilt;
    }
    private _isExchangeInBuilt = false;
    exchangeInIsBuiltSubject: BehaviorSubject<boolean> = new BehaviorSubject(this._isExchangeInBuilt);
    observableExchangeInBuilt: Observable<boolean> = this.exchangeInIsBuiltSubject.asObservable();
    get isExchangeinBuilt(): boolean {
        return this._isExchangeInBuilt;
    }
    private _isExchangeOutBuilt = false;
    exchangeOutIsBuiltSubject: BehaviorSubject<boolean> = new BehaviorSubject(this._isExchangeOutBuilt);
    observableExchangeOutBuilt: Observable<boolean> = this.exchangeOutIsBuiltSubject.asObservable();
    get isExchangeOutBuilt(): boolean {
        return this._isExchangeOutBuilt;
    }

    // selectedIndex
    protected _selectedIndex = 0;
    selectedIndexSubject: BehaviorSubject<number> = new BehaviorSubject(
        this._selectedIndex
    );

    get selectedIndex(): number {
        return this._selectedIndex;
    }

    set selectedIndex(value: number) {
        this._selectedIndex = value;

        if (this.callService.call?.callType === CallTypes.retail
            || this.callService.call?.callType === CallTypes.rmWholesale) {
            if (value || value === 0) {
                this.callService.call.lastIndexVisited = value;
                void this.callService.saveCallAndNotify();
            }
        }
        this.selectedIndexSubject.next(value);
    }

    // farthestIndex
    private _farthestIndexSubject: BehaviorSubject<number> = new BehaviorSubject(
        (this.callService.call as RetailCall | RmWholesaleCall)?.farthestIndex
    );

    get farthestIndex(): number {
        return (this.callService.call as RetailCall | RmWholesaleCall)?.farthestIndex;
    }

    set farthestIndex(value: number) {
        if (
            value
            && (this.callService.call?.callType === CallTypes.retail
                || this.callService.call?.callType === CallTypes.rmWholesale) &&
            this.callService.call?.farthestIndex != null &&
            value > this.callService?.call?.farthestIndex
        ) {
            this.callService.call.farthestIndex = value;
            void this.callService.saveCallAndNotify();
            this._farthestIndexSubject.next(value);
        }
    }

    observableSelectedIndex: Observable<number> = this.selectedIndexSubject.asObservable();

    async addPicture(picture: CallPicture, image: string): Promise<void> {
        const call = this.callService.call as RetailCall | RmWholesaleCall;
        picture.id = newSequentialId();

        const beforePic: CallPicture = call.callPictures?.find(
            (pic) => pic.type === "Before"
        );
        const afterPic: CallPicture = call.callPictures?.find(
            (pic) => pic.type === "After"
        );

        const selectedIndex = this.selectedIndex;

        if (picture) {
            if (picture.type === "Before" && beforePic) {
                this.callService.replacePicture(beforePic, picture, image);
            } else if (picture.type === "After" && afterPic) {
                this.callService.replacePicture(afterPic, picture, image);
            } else {
                this.callService.pictures.push({
                    id: picture.id,
                    image
                });
                call.callPictures.push(picture);
            }
            // notify subscribers
            await this.callService.savePicturesAndNotify();

            if (!(this.callService.call.callType === CallTypes.retail || this.callService.call?.callType === CallTypes.rmWholesale)) {
                return;
            }

            if (
                picture.type === "Before" ||
                picture.type === "After"
            ) {
                this.selectedIndex++;
            }

            if (selectedIndex !== this.selectedIndex) {
                this.selectedIndexSubject.next(this.selectedIndex);
            }
        }
    }

    calculateTotals(): void {
        let totalCashBeforeDiscount = 0;
        let totalGratis = 0;
        let totalCashAndGratisDiscount = 0;

        if (
            this.totalCashBeforeDiscount ||
            this.totalCashBeforeDiscount === 0
        ) {
            this.callCashProductViewModels.forEach((product) => {
                totalCashBeforeDiscount =
                    totalCashBeforeDiscount +
                    product.quantity * product.price;
                totalCashAndGratisDiscount =
                    totalCashAndGratisDiscount +
                    product.quantity * product.discount;
            });
        }

        if (this.callGratisProductViewModels) {
            this.callGratisProductViewModels.forEach((product) => {
                totalGratis =
                    totalGratis +
                    product.quantity * product.value;
                totalCashAndGratisDiscount =
                    totalCashAndGratisDiscount +
                    product.quantity * product.value;
            });
        }

        this.totalCashBeforeDiscount = totalCashBeforeDiscount;
        this.totalGratis = totalGratis;
        this.totalCashAndGratisDiscount = totalCashAndGratisDiscount;
        this.totalPaymentDue =
            this.totalCashBeforeDiscount +
            this.totalGratis -
            this.totalCashAndGratisDiscount;
        this.totalDiscountPercent =
            this.totalCashAndGratisDiscount /
            this.totalCashBeforeDiscount;

        // WHY THIS CODE: PER MALLORY, GRATIS SHOULD NOT GO ABOVE 100% EVEN IF IT MATHEMATICALLY SHOULD, SO WE ADD THIS TO PREVENT THIS
        this.totalDiscountPercent = Math.abs(this.totalDiscountPercent) >= 1 ? 1 : Math.abs(this.totalDiscountPercent)

        this.cashDueNowTotal = this.callCashProductViewModels.filter((g) => g.product?.subsidiary === SubsidiaryTypes.rogueholdingsLlc
            || g.product?.subsidiary === SubsidiaryTypes.swisher).reduce(
                (prev: number, cur: CallCashProductViewModel) => {
                    return prev + cur.quantity * (cur.price - cur.discount) + ((!cur.statePrepaid ? cur.stateTaxAmount : 0) + (cur.countyTaxAmount ?? 0) + (cur.cityTaxAmount ?? 0));
                },
                0
            );

        this.easCashDueNowTotal = this.callCashProductViewModels.filter((g) => g.product?.subsidiary === SubsidiaryTypes.eas).reduce(
            (prev: number, cur: CallCashProductViewModel) => {
                return prev + cur.quantity * (cur.price - cur.discount) + ((!cur.statePrepaid ? cur.stateTaxAmount : 0) + (cur.countyTaxAmount ?? 0) + (cur.cityTaxAmount ?? 0));
            },
            0
        );

        this.gratisDueNowTotal = this.callGratisProductViewModels.filter((g) => g.product?.subsidiary === SubsidiaryTypes.rogueholdingsLlc
            || g.product?.subsidiary === SubsidiaryTypes.swisher).reduce(
                (prev: number, cur: CallGratisProductViewModel) => {
                    return prev + cur.quantity * cur.value + (!cur.statePrepaid ? cur.stateTaxAmount : 0) + (cur.countyTaxAmount ?? 0) + (cur.cityTaxAmount ?? 0);
                },
                0
            );

        this.easGratisDueNowTotal = this.callGratisProductViewModels.filter((g) => g.product?.subsidiary === SubsidiaryTypes.eas).reduce(
            (prev: number, cur: CallGratisProductViewModel) => {
                return prev + cur.value * cur.quantity + (!cur.statePrepaid ? cur.stateTaxAmount : 0) + (cur.countyTaxAmount ?? 0) + (cur.cityTaxAmount ?? 0);
            },
            0
        );

        this.exchangeOutTotal = this.callProductOutViewModels.filter((g) => g.product?.subsidiary === SubsidiaryTypes.rogueholdingsLlc
            || g.product?.subsidiary === SubsidiaryTypes.swisher).reduce(
                (prev: number, cur: CallProductOutViewModel) => {
                    return prev + cur.price * cur.quantity + ((!cur.statePrepaid ? cur.stateTaxAmount : 0) * -1) + ((cur.countyTaxAmount ?? 0) * -1) + ((cur.cityTaxAmount ?? 0) * -1);
                },
                0
            );

        this.easExchangeOutTotal = this.callProductOutViewModels.filter((g) => g.product?.subsidiary === SubsidiaryTypes.eas).reduce(
            (prev: number, cur: CallProductOutViewModel) => {
                return prev + cur.price * cur.quantity + (!cur.statePrepaid ? cur.stateTaxAmount : 0) + (cur.countyTaxAmount ?? 0) + (cur.cityTaxAmount ?? 0);
            },
            0
        );

        this.exchangeInTotal = this.callProductInViewModels.filter((g) => g.product?.subsidiary === SubsidiaryTypes.rogueholdingsLlc
            || g.product?.subsidiary === SubsidiaryTypes.swisher).reduce(
                (prev: number, cur: CallProductInViewModel) => {
                    return prev + cur.price * cur.quantity + (!cur.statePrepaid ? cur.stateTaxAmount : 0) + (cur.countyTaxAmount ?? 0) + (cur.cityTaxAmount ?? 0);
                },
                0
            );
        this.easExchangeInTotal = this.callProductInViewModels.filter((g) => g.product?.subsidiary === SubsidiaryTypes.eas).reduce(
            (prev: number, cur: CallProductInViewModel) => {
                return prev + cur.price * cur.quantity + (!cur.statePrepaid ? cur.stateTaxAmount : 0) + (cur.countyTaxAmount ?? 0) + (cur.cityTaxAmount ?? 0);
            },
            0
        );
    }

    buildCashProductViewModel(): void {

        if (!this.productDelineationService.activeProducts.size) { return; }

        const cashProductViewModels: CallCashProductViewModel[] = [];

        if (this.callService.call?.callType === CallTypes.retail
            || this.callService.call?.callType === CallTypes.rmWholesale) {
            this.callService.call.cashProducts ??= [];

            this.callService.call?.cashProducts.forEach(
                (cashProduct: CallCashProduct) => {
                    const cashProductViewModel: CallCashProductViewModel = new CallCashProductViewModel();
                    cashProductViewModel.id = cashProduct.id;
                    cashProductViewModel.product = this.productDelineationService.activeProducts.get(cashProduct.productId);
                    cashProductViewModel.unitsOfMeasure = [...(cashProductViewModel.product.upcs.length === 1 ? cashProductViewModel.product.upcs : cashProductViewModel.product.upcs.filter(upc => upc.uom !== "Case"))];
                    cashProductViewModel.upc = cashProduct.upc;
                    cashProductViewModel.units = cashProductViewModel.unitsOfMeasure.find(uom => uom.upc === cashProductViewModel.upc)?.noOfEaches ?? cashProductViewModel.unitsOfMeasure[0].noOfEaches;
                    cashProductViewModel.discount = cashProduct.discount;
                    cashProductViewModel.price = cashProduct.price;
                    // if (cashProduct.callProductTax?.statePrepaid) {
                    //     cashProductViewModel.price -= cashProduct.callProductTax?.stateTaxAmount ?? 0;
                    // }
                    // if (cashProduct.callProductTax?.countyPrepaid) {
                    //     cashProductViewModel.price -= cashProduct.callProductTax?.countyTaxAmount ?? 0;
                    // }
                    // if (cashProduct.callProductTax?.cityPrepaid) {
                    //     cashProductViewModel.price -= cashProduct.callProductTax?.cityTaxAmount ?? 0;
                    // }
                    cashProductViewModel.quantity = cashProduct.quantity;
                    cashProductViewModel.oldUnits = cashProduct.units;
                    cashProductViewModel.oldQuantity =
                        cashProductViewModel.quantity;
                    cashProductViewModel.oldPrice = cashProductViewModel.price;
                    cashProductViewModel.statePrepaid = cashProduct.callProductTax?.statePrepaid;
                    cashProductViewModel.stateTaxAmount = cashProduct.callProductTax?.stateTaxAmount ?? 0;
                    cashProductViewModel.countyTaxAmount = cashProduct.callProductTax?.countyTaxAmount ?? 0;
                    cashProductViewModel.cityTaxAmount = cashProduct.callProductTax?.cityTaxAmount ?? 0;
                    cashProductViewModel.total = cashProductViewModel.quantity * (cashProductViewModel.price - cashProductViewModel.discount);
                    cashProductViewModel.totalWithTax = cashProductViewModel.total
                        + (!cashProductViewModel.statePrepaid ? cashProductViewModel.stateTaxAmount ?? 0 : 0)
                        + (cashProductViewModel.countyTaxAmount ?? 0)
                        + (cashProductViewModel.cityTaxAmount ?? 0);


                    cashProductViewModels.push(cashProductViewModel);
                }
            );
        }
        this.callCashProductViewModels = cashProductViewModels;
        this._isCashBuilt = true;
        this.cashIsBuiltSubject.next(this._isCashBuilt);
    }

    buildGratisProductViewModel(): void {

        if (!this.productDelineationService.activeProducts.size) { return; }

        const gratisProductViewModels: CallGratisProductViewModel[] = [];

        if (this.callService.call?.callType === CallTypes.retail
            || this.callService.call?.callType === CallTypes.rmWholesale) {
            this.callService.call.gratisProducts ??= [];

            this.callService.call?.gratisProducts.forEach((gratisProduct) => {
                const gratisProductViewModel: CallGratisProductViewModel = new CallGratisProductViewModel();
                gratisProductViewModel.id = gratisProduct.id;
                gratisProductViewModel.product = this.productDelineationService.activeProducts.get(gratisProduct.productId);
                gratisProductViewModel.unitsOfMeasure = [...(gratisProductViewModel.product.upcs.length === 1 ? gratisProductViewModel.product.upcs : gratisProductViewModel.product.upcs.filter(upc => upc.uom !== "Case"))];
                gratisProductViewModel.value = gratisProduct.value;
                gratisProductViewModel.quantity = gratisProduct.quantity;
                gratisProductViewModel.units = gratisProduct.units;
                gratisProductViewModel.oldUnits = gratisProductViewModel.units;
                gratisProductViewModel.oldQuantity =
                    gratisProductViewModel.quantity;
                gratisProductViewModel.oldValue = gratisProductViewModel.value;
                gratisProductViewModel.upc = gratisProduct.upc;
                gratisProductViewModel.statePrepaid = gratisProduct.callProductTax?.statePrepaid;
                gratisProductViewModel.stateTaxAmount = gratisProduct.callProductTax?.stateTaxAmount ?? 0;
                gratisProductViewModel.countyTaxAmount = gratisProduct.callProductTax?.countyTaxAmount ?? 0;
                gratisProductViewModel.cityTaxAmount = gratisProduct.callProductTax?.cityTaxAmount ?? 0;
                gratisProductViewModel.total = gratisProductViewModel.quantity * gratisProductViewModel.value;
                gratisProductViewModel.totalWithTax = gratisProductViewModel.total
                    + (!gratisProductViewModel.statePrepaid ? gratisProductViewModel.stateTaxAmount ?? 0 : 0)
                    + (gratisProductViewModel.countyTaxAmount ?? 0)
                    + (gratisProductViewModel.cityTaxAmount ?? 0);

                gratisProductViewModels.push(gratisProductViewModel);
            });
        }
        this.callGratisProductViewModels = gratisProductViewModels;
        this._isGratisBuilt = true;
        this.gratisIsBuiltSubject.next(this._isGratisBuilt);
    }

    buildProductOutViewModel(): void {

        if (!this.productDelineationService.activeProducts.size) { return; }

        const retailCallProductOutViewModels: CallProductOutViewModel[] = [];

        if (this.callService.call?.callType === CallTypes.retail
            || this.callService.call?.callType === CallTypes.rmWholesale) {
            this.callService.call.exchangeOutProducts ??= [];

            this.callService.call?.exchangeOutProducts.forEach((outProduct) => {
                const outProductViewModel: CallProductOutViewModel = new CallProductOutViewModel();
                outProductViewModel.id = outProduct.id;
                outProductViewModel.product = this.productDelineationService.activeProducts.get(outProduct.productId);
                outProductViewModel.price = outProduct.price;
                outProductViewModel.wholesalePrice = outProduct.wholesalePrice;
                outProductViewModel.quantity = outProduct.quantity;
                outProductViewModel.units = outProduct.units;
                outProductViewModel.oldUnits = outProductViewModel.units;
                outProductViewModel.oldQuantity = outProductViewModel.quantity;
                outProductViewModel.statePrepaid = outProduct.callProductTax?.statePrepaid;
                outProductViewModel.stateTaxAmount = outProduct.callProductTax?.stateTaxAmount ?? 0;
                outProductViewModel.countyTaxAmount = outProduct.callProductTax?.countyTaxAmount ?? 0;
                outProductViewModel.cityTaxAmount = outProduct.callProductTax?.cityTaxAmount ?? 0;
                outProductViewModel.total = outProductViewModel.quantity * outProductViewModel.price;
                outProductViewModel.totalWithTax = outProductViewModel.total
                    + (!outProductViewModel.statePrepaid ? outProductViewModel.stateTaxAmount * -1 : 0)
                    + (outProductViewModel.countyTaxAmount * -1)
                    + (outProductViewModel.cityTaxAmount * -1);
                outProductViewModel.unsalable = (this.callService.call as RetailCall | RmWholesaleCall).unsalableProductIds?.includes(
                    outProduct.id
                );


                retailCallProductOutViewModels.push(outProductViewModel);
            });
        }
        this.callProductOutViewModels = retailCallProductOutViewModels;
        this._isExchangeOutBuilt = true;
        this.exchangeOutIsBuiltSubject.next(this._isExchangeOutBuilt);
    }

    buildProductInViewModel(): void {

        if (!this.productDelineationService.activeProducts.size) { return; }

        const retailCallProductInViewModels: CallProductInViewModel[] = [];

        if (this.callService.call?.callType === CallTypes.retail
            || this.callService.call?.callType === CallTypes.rmWholesale) {
            this.callService.call.exchangeInProducts ??= [];

            this.callService.call?.exchangeInProducts.forEach((inProduct) => {
                const inProductViewModel: CallProductInViewModel = new CallProductInViewModel();
                inProductViewModel.id = inProduct.id;
                inProductViewModel.product = this.productDelineationService.activeProducts.get(inProduct.productId);
                inProductViewModel.price = inProduct.price;
                inProductViewModel.wholesalePrice = inProduct.wholesalePrice;
                inProductViewModel.quantity = inProduct.quantity;
                inProductViewModel.units = inProduct.units;
                inProductViewModel.oldUnits = inProductViewModel.units;
                inProductViewModel.oldQuantity = inProductViewModel.quantity;
                inProductViewModel.statePrepaid = inProduct.callProductTax?.statePrepaid;
                inProductViewModel.stateTaxAmount = inProduct.callProductTax?.stateTaxAmount ?? 0;
                inProductViewModel.countyTaxAmount = inProduct.callProductTax?.countyTaxAmount ?? 0;
                inProductViewModel.cityTaxAmount = inProduct.callProductTax?.cityTaxAmount ?? 0;
                inProductViewModel.total = inProductViewModel.quantity * inProductViewModel.price;
                inProductViewModel.totalWithTax = inProductViewModel.total
                    + (!inProductViewModel.statePrepaid ? inProductViewModel.stateTaxAmount ?? 0 : 0)
                    + (inProductViewModel.countyTaxAmount ?? 0)
                    + (inProductViewModel.cityTaxAmount ?? 0);

                retailCallProductInViewModels.push(inProductViewModel);
            });
        }
        this.callProductInViewModels = retailCallProductInViewModels;
        this._isExchangeInBuilt = true;
        this.exchangeInIsBuiltSubject.next(this._isExchangeInBuilt);
    }

    async buildOrderProductViewModel(): Promise<void> {

        if (!this.productDelineationService.activeProducts.size || !this.employee) { return; }

        this._areOrdersBuilt = false;
        const orderProductViewModels: CallOrderProductViewModel[] = [];

        if (this.callService.call?.callType === CallTypes.retail
            || this.callService.call?.callType === CallTypes.rmWholesale) {
            this.callService.call.orderProducts ??= [];

            const wholesalerIds = new Set(
                this.callService.call.orderProducts.map(op => op.wholesalerCustomerId)
            );

            const wholesalersMap = await this.productCatalogHelper.getByWholesalers([...wholesalerIds]);

            for (const orderProduct of this.callService.call?.orderProducts ?? []) {
                const orderProductViewModel: CallOrderProductViewModel = new CallOrderProductViewModel(false, null);
                orderProductViewModel.id = orderProduct.id;
                orderProductViewModel.product = this.productDelineationService.activeProducts.get(orderProduct.productId);
                orderProductViewModel.unitsOfMeasure = [...(orderProductViewModel.product.upcs.length === 1 ? orderProductViewModel.product.upcs : orderProductViewModel.product.upcs.filter(upc => upc.uom !== "Case"))];
                orderProductViewModel.uin = orderProduct.uin;
                orderProductViewModel.quantity = orderProduct.quantity;
                orderProductViewModel.units = orderProduct.units;
                orderProductViewModel.oldUnits = orderProductViewModel.units;
                orderProductViewModel.oldQuantity = orderProductViewModel.quantity;
                orderProductViewModel.upc = orderProduct.upc;
                orderProductViewModel.orderDates = orderProduct.callOrderDates;
                orderProductViewModel.storeCount = orderProduct.storeCount;
                if (orderProduct.wholesalerCustomerId) {
                    if (!wholesalersMap[orderProduct.wholesalerCustomerId]) {
                        continue;
                    }
                    const { wholesaler, groupItems } = wholesalersMap[orderProduct.wholesalerCustomerId];
                    orderProductViewModel.wholesaler = [new WholesalerViewmodel(wholesaler)];

                    const groupCatalogItem =
                        groupItems.find((p) => p.productId === orderProduct.productId);
                    orderProductViewModel.dateAvailable = groupCatalogItem.wholesalerItems.find(
                        wi => wi.wholesalerId === orderProduct.wholesalerCustomerId && wi.dateAvailable > new Date()
                    )?.dateAvailable;
                    // If the wholesaler specifically doesn't have a date available for this product,
                    // fall back to the group's date available, or leave null.
                    if (!orderProductViewModel.dateAvailable) {
                        if (groupCatalogItem.dateAvailable && groupCatalogItem.dateAvailable > new Date()) {
                            orderProductViewModel.dateAvailable = groupCatalogItem.dateAvailable;
                        }
                    }

                    if (!orderProductViewModel.uin) {
                        orderProduct.uin = groupCatalogItem?.productUIN;
                    }
                }

                if (orderProduct.selectedProject) {

                    orderProductViewModel.selectedProject = orderProduct.selectedProject;

                    const projectProducts = orderProduct.selectedProject.projectProducts.filter((pp) => pp.productId === orderProduct.productId && !!pp.wholesalerId);
                    let projectWholesalerIds = projectProducts.map((pp) => pp.wholesalerId);
                    projectWholesalerIds = [...new Set(projectWholesalerIds)];

                    const wholesalerResponse = await this.customerDelineationService.getByIds(projectWholesalerIds);
                    if (wholesalerResponse?.values) {
                        orderProductViewModel.wholesalers = wholesalerResponse.values.map((w) => new WholesalerViewmodel(w));
                    }

                } else {

                    const wholesalersResponse = await this.customerDelineationService
                        .getWholesalersByCustomerAndProduct(this.customerStateService.customer, orderProductViewModel.product);
                    if (wholesalersResponse) {

                        const wholesalers = wholesalersResponse.values;
                        orderProductViewModel.wholesalers = wholesalers.map((w) => new WholesalerViewmodel(w));
                    }
                }

                orderProductViewModels.push(orderProductViewModel);
            }
        }
        this.callOrderProductViewModels = orderProductViewModels;
        this._areOrdersBuilt = true;
        this.ordersAreBuiltSubject.next(this._areOrdersBuilt);
    }

    resetCall(): void {
        this.callService.call = null;
        this.callService.pictures = [];
        this.selectedIndex = 0;
        this.farthestIndex = 0;
        this.totalDiffRetail = 0;
        this.totalDiffWholesale = 0;
        this.totalDiscountPercent = 0;
        this.callService.isFinalRetailReceiptPrinted = false;
        this.callService.isFinalWholesaleReceiptPrinted = false;
        this.callCashProductViewModels = new Array<CallCashProductViewModel>();
        this.callOrderProductViewModels = new Array<CallOrderProductViewModel>();
        this.callGratisProductViewModels = new Array<CallGratisProductViewModel>();
        this.callExchangeTransactionViewModel = new Array<CallExchangeTransactionViewModel>();
        this.callProductInViewModels = new Array<CallProductInViewModel>();
        this.callProductOutViewModels = new Array<CallProductOutViewModel>();
    }

    async buildCategoryDistributionViewmodelsFromDomainModel(
        call: Call
    ): Promise<CallCategoryDistributionViewModel[]> {
        const rtn: CallCategoryDistributionViewModel[] = [];

        if (call?.callType === CallTypes.retail
            || call?.callType === CallTypes.rmWholesale) {
            const productsInDist: string[] = call?.inDistProductIds;
            let productsIntro: string[] = call?.productsIntro;
            let productsOos: string[] = call?.productsOos;
            let productsCos: string[] = call?.productsCos;

            if (call.callProductStatus?.length) {
                productsIntro = call.callProductStatus.filter((product) => product.intro).map((product) => product.productId);
                productsOos = call.callProductStatus.filter((product) => product.outOfStock).map((product) => product.productId);
                productsCos = call.callProductStatus.filter((product) => product.correctiveOffSelf).map((product) => product.productId);
            }

            const allProductKeys: string[] =
                productsInDist?.concat(
                    productsIntro,
                    productsOos,
                    productsCos
                ) ?? [];
            const distinctKeys: string[] = [
                ...new Set(allProductKeys.map((key) => key))
            ];

            const productsResponse = await this.productDelineationService.getByIds(
                distinctKeys
            );

            if (!productsResponse) { return; }

            const products = productsResponse.values;

            const groupedProducts: Map<string, Product[]> = Helper.groupBy(
                products,
                (product: Product) => product.division
            );

            groupedProducts.forEach((group) => {
                const retailCallCategoryDistributionViewModel: CallCategoryDistributionViewModel = new CallCategoryDistributionViewModel();
                retailCallCategoryDistributionViewModel.category =
                    group[0].division;
                retailCallCategoryDistributionViewModel.inDist = 0;
                retailCallCategoryDistributionViewModel.intro = 0;
                retailCallCategoryDistributionViewModel.oos = 0;
                retailCallCategoryDistributionViewModel.cos = 0;

                group.forEach((product) => {
                    if (productsInDist.includes(product.id)) {
                        retailCallCategoryDistributionViewModel.inDist++;
                    }
                    if (productsIntro.includes(product.id)) {
                        retailCallCategoryDistributionViewModel.intro++;
                    }
                    if (productsOos.includes(product.id)) {
                        retailCallCategoryDistributionViewModel.oos++;
                    }
                    if (productsCos.includes(product.id)) {
                        retailCallCategoryDistributionViewModel.cos++;
                    }
                });

                rtn.push(retailCallCategoryDistributionViewModel);
            });
        }

        return rtn;
    }

    buildExchangeTransactionViewmodelsFromDomainModel(call: Call): void {
        const rtn: CallExchangeTransactionViewModel[] = [];

        if (call?.callType === CallTypes.retail
            || call?.callType === CallTypes.rmWholesale) {
            let productsOut: CallExchangeOutProduct[] =
                call?.exchangeOutProducts;
            productsOut ??= [];

            let totalSticks = 0;
            let totalRetail = 0;
            let totalWholesale = 0;

            productsOut.forEach((trans) => {
                totalSticks += trans.quantity * trans.units;
                totalRetail += trans.quantity * trans.price;
                totalWholesale += trans.quantity * trans.wholesalePrice;
            });

            let transaction: CallExchangeTransactionViewModel = new CallExchangeTransactionViewModel();
            transaction.type = "Out";
            transaction.totalSticks = totalSticks;
            transaction.totalRetail = totalRetail;
            transaction.totalWholesaler = totalWholesale;
            rtn.push(transaction);

            let productsIn: CallExchangeInProduct[] = call?.exchangeInProducts;
            productsIn ??= [];

            totalSticks = 0;
            totalRetail = 0;
            totalWholesale = 0;

            productsIn.forEach((trans) => {
                totalSticks += trans.quantity * trans.units;
                totalRetail += trans.quantity * trans.price;
                totalWholesale += trans.quantity * trans.wholesalePrice;
            });

            transaction = new CallExchangeTransactionViewModel();
            transaction.type = "In";
            transaction.totalSticks = totalSticks;
            transaction.totalRetail = totalRetail;
            transaction.totalWholesaler = totalWholesale;
            rtn.push(transaction);
        }

        this.callExchangeTransactionViewModel = rtn;
    }

    async buildSalesTransactionViewmodelsFromDomainModel(call: Call): Promise<void> {
        const rtn: CallSalesTransactionViewmodel[] = [];

        if (call?.callType === CallTypes.retail
            || call?.callType === CallTypes.rmWholesale) {
            let cashProducts: CallCashProduct[] = call?.cashProducts;
            cashProducts ??= [];

            let gratisProducts: CallGratisProduct[] = call?.gratisProducts;
            gratisProducts ??= [];

            let orderProducts: CallOrderProduct[] = call?.orderProducts;
            orderProducts ??= [];

            let totalSticks = 0;
            let totalValue = 0;

            cashProducts?.forEach((trans) => {
                totalSticks = totalSticks + trans.quantity * trans.units;
                totalValue =
                    totalValue +
                    trans.quantity * (trans.price - trans.discount);
            });

            let transaction: CallSalesTransactionViewmodel = new CallSalesTransactionViewmodel();
            transaction.type = "Cash";
            transaction.totalSticks = totalSticks;
            transaction.totalValue = totalValue;
            transaction.wholesaler = "-";
            rtn.push(transaction);

            totalSticks = 0;
            totalValue = 0;

            gratisProducts?.forEach((trans) => {
                totalSticks = totalSticks + trans.quantity * trans.units;
                totalValue = totalValue + trans.quantity * trans.value;
            });

            transaction = new CallSalesTransactionViewmodel();
            transaction.type = "Gratis";
            transaction.totalSticks = totalSticks;
            transaction.totalValue = totalValue;
            transaction.wholesaler = "-";
            rtn.push(transaction);

            totalSticks = 0;

            orderProducts?.forEach((trans) => {
                totalSticks = totalSticks + trans.quantity * trans.units;
            });

            const groupedOrderProductArray: Map<
                string,
                CallOrderProduct[]
            > = Helper.groupBy(
                orderProducts,
                (order: CallOrderProduct) => order.wholesalerCustomerId
            );

            if (groupedOrderProductArray?.size > 0) {
                const wholesalers = await this.customerDelineationService.getByIds(Array.from(groupedOrderProductArray.keys()));
                groupedOrderProductArray.forEach(
                    (group: CallOrderProduct[]) => {
                        totalSticks = 0;
                        group.forEach((obj: CallOrderProduct) => {
                            totalSticks =
                                totalSticks + obj.quantity * obj.units;
                        });

                        transaction = new CallSalesTransactionViewmodel();
                        transaction.type = "Order";
                        transaction.totalSticks = totalSticks;
                        transaction.wholesaler = wholesalers?.values?.find((v) => v.id === group[0].wholesalerCustomerId)?.name ?? "-";
                        rtn.push(transaction);
                    }
                );
            } else {
                transaction = new CallSalesTransactionViewmodel();
                transaction.type = "Order";
                transaction.totalSticks = totalSticks;
                transaction.wholesaler = "-";
                rtn.push(transaction);
            }
        }
        this.callSalesTransactionViewmodel = rtn;
    }

    async startCall(
        employee: Employee,
    ): Promise<void> {

        const startedProjectsResponse = await this.projectDelineationService.getStartedProjectsByCustomerAndEmployeeIds(this.customerStateService.customer.id, employee.id);
        if (!startedProjectsResponse) { return; }

        let surveyIds = new Array<string>();

        for (const project of startedProjectsResponse.values) {
            surveyIds = surveyIds.concat(project.projectActivitySurveys.map((as) => as.activitySurveyId));
        }

        const surveyResponse = surveyIds?.length ? await this.surveryDelineationService.getByIds(surveyIds) : new GenericResponseDto<Survey[]>();

        if (!surveyResponse) { return; }

        let call: RetailCall | RmWholesaleCall;
        const callsResponse = await this.callService.getLocalCallsByCustomerId(this.customerStateService.customer.id);

        if (!callsResponse) { return; }

        if (callsResponse.values) {
            call = callsResponse.values.find(
                (myCall) =>
                    myCall.stopTime == null && myCall.createdUserId === employee.id
            ) as RetailCall | RmWholesaleCall;
        }

        if (call) {
            if (call.callPictures?.length > 0) {
                const picsResponse = await this.pictureDelineationService.getLocalPicturesByIds(
                    call.callPictures.map((p) => p.id)
                );

                if (!picsResponse) { return; }

                if (picsResponse.values) {
                    this.callService.pictures = picsResponse.values;
                    this.saveSelectedIndexAndNotify();
                }
            }
        } else {
            const now: Date = new Date();
            call = Helper.getCustomerGenericType(this.customerStateService.customer) === CustomerGenericTypes.retail
                ? new RetailCall() : new RmWholesaleCall();
            call.createdUserId = employee.id;
            call.createdUtcDateTime = now;
            call.createdUserZrt = employee.zrt;
            call.createdUserFullName = employee.fullName;
            call.id = newSequentialId();
            call.customerId = this.customerStateService.customer.id;
            call.startTime = now;
            call.farthestIndex = 0;
            this.callService.pictures = [];
        }


        this.callService.call = call;

        this.selectedIndex = call.lastIndexVisited ?? 0;

        if (
            this.callService.call &&
            (!this.callService.call.surveys ||
                this.callService.call.surveys.length === 0)
        ) {
            this.callService.call.surveys = surveyResponse.values?.map((s) =>
                this.callService.buildCallActivitySurveyViewModelFromSurvey(s, this.callService.call.surveyAnswers))
                ?? [];
        }

        await this.callService.saveCallAndNotify();
    }

    async saveSelectedIndexAndNotify(): Promise<void> {
        if (this.callService.call?.callType === CallTypes.retail
            || this.callService.call?.callType === CallTypes.rmWholesale) {

            if (this.selectedIndex || this.selectedIndex === 0) {
                this.callService.call.lastIndexVisited = this.selectedIndex;
                await this.callService.saveCallAndNotify();
            }
        }
        this.selectedIndexSubject.next(this.selectedIndex);
    }
}
