import { Component, ElementRef, Input, ViewChild, inject, input, viewChild } from "@angular/core";
import { Customer } from "src/app/entity-models/customer.entity";
import { Contact } from "src/app/entity-models/contact.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { ReceiptAddressViewModel } from "../receipt-address.viewmodel";
import { SwisherRetailFormatComponent } from "../../../stepper-call/receipts/retail-receipt/swisher-retail-format/swisher-retail-format.component";
import { EasRetailFormatComponent } from "../../../stepper-call/receipts/retail-receipt/eas-retail-format/eas-retail-format.component";
import { ReceiptSelectedFormat } from "src/app/enums/receipt-selected-format";
import { StepperCallApplicationService } from "../../stepper-call-services/stepper-call-application.service";
import { Subsidiary } from "shield.shared";
import { CallService } from "../../../call-services/call.service";
import { Product } from "src/app/entity-models/product.entity";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { RmWholesaleCall } from "src/app/entity-models/rm-wholesale-call.entity";
import { map, Observable, of, shareReplay } from "rxjs";
import { RetailFormatDraftComponent } from "./retail-format-draft/retail-format-draft.component";

@Component({
    selector: "app-retail-receipt",
    templateUrl: "./retail-receipt.component.html",
    styleUrls: ["./retail-receipt.component.scss"]
})
export class RetailReceiptComponent {

    @ViewChild("swisherRetailFormat") swisherRetailFormat: SwisherRetailFormatComponent;
    @ViewChild("easRetailFormat") easRetailFormat: EasRetailFormatComponent;
    draftComponent = viewChild<RetailFormatDraftComponent>("retailDraft");
    private stepperCallApplicationService = inject(StepperCallApplicationService);
    private callService = inject(CallService);
    private productService = inject(ProductDelineationService);

    call$ = this.callService.observableCall as Observable<RetailCall | RmWholesaleCall>;
    activeProducts$ = this.productService.observableActiveProducts.pipe(shareReplay(1));

    narrowOnly = input.required<boolean>();

    //Inputs
    private _employee: Employee;
    @Input()
    get employee(): Employee {
        return this._employee;
    }
    set employee(value: Employee) {
        this._employee = value;
        if (value) {
            if (value.subsidiaryId === Subsidiary.EAS) {
                this.isEasEmployee = true;
            } else {
                this.isEasEmployee = false;
            }
        }
    }

    private _customer: Customer;
    @Input()
    get customer(): Customer {
        return this._customer;
    }
    set customer(value: Customer) {
        this._customer = value;
    }

    private _customerReceiptAddress = new ReceiptAddressViewModel;
    @Input()
    get customerReceiptAddress(): ReceiptAddressViewModel {
        return this._customerReceiptAddress;
    }
    set customerReceiptAddress(value: ReceiptAddressViewModel) {
        if (value) {
            this._customerReceiptAddress = value;
        }
    }

    @Input()
    selectedContact: Contact;

    @Input()
    get signature(): string {
        return this._signature;
    }
    set signature(value: string) {
        if (value) {
            this._signature = value;
        }
    }

    @Input() isTaxState = false;
    @Input() selectedFormat: ReceiptSelectedFormat;

    signatureRetailWidth = 400;
    signatureRetailHeight = 100;
    selectedFormats = ReceiptSelectedFormat;

    isRetailDraft = false;
    isRetailFinal = false;
    _signature: string;

    originalSwisherRetailReceipt: ElementRef;
    originalEasRetailReceipt: ElementRef;

    get hasReceipts(): boolean {
        return (
            (this.stepperCallApplicationService.callCashProductViewModels ?? [])
                .length > 0 ||
            (this.stepperCallApplicationService.callGratisProductViewModels ?? [])
                .length > 0 ||
            (this.stepperCallApplicationService.callProductInViewModels ?? [])
                .length > 0 ||
            (this.stepperCallApplicationService.callProductOutViewModels ?? [])
                .length > 0
        );
    }

    isEasEmployee = false;

}
