import { Component, computed, EventEmitter, OnDestroy, OnInit, Output, signal, ViewChild } from "@angular/core";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { RetailStepperStep } from "src/app/enums/retail-stepper-step";
import { CallCategoryDistributionViewModel } from "../../call-viewmodels/call-category-distribution.viewmodel";
import { MatCarouselComponent } from "@magloft/material-carousel";
import { Picture } from "src/app/entity-models/picture.entity";
import { OverlayService } from "src/app/services/overlay.service";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ShareOptionsDialogComponent } from "src/app/dialogs/share-options-dialog/share-options-dialog.component";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import {
    faTimes,
    faEye,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { CallValidationErrors, CallValidationService } from "../../../account-services/call-validation.service";
import { ErrorLevel } from "../../../account-enums/error-level";
import { ValidationStops } from "../../../account-enums/validation-stops";
import { Helper } from "src/app/helpers/helper";
import { CallTypes, EmailReceiptLocationOrigins, newSequentialId } from "shield.shared";
import { Call, CallService } from "../../call-services/call.service";
import { CallValidationViewmodel } from "src/app/accounts/account-models/call-validation.viewmodel";
import { ShareOptionsDialogViewmodel } from "src/app/dialogs/share-options-dialog/share-options-dialog.viewmodel";
import { Employee } from "src/app/entity-models/employee.entity";
import { CallDistributionService } from "../../call-services/call-distribution.service";
import { CallProductStatus } from "src/app/entity-models/call-product-status.entity";
import { Receipt } from "src/app/entity-models/receipt";
import { SnackbarService } from "src/app/services/snackbar.service";
import { Survey } from "src/app/entity-models/survey.entity";
import { PingService } from "src/app/services/ping.service";
import { AppStateService } from "src/app/services/app-state.service";
import { ActivitiesSurveysComponent } from "../activities-surveys/activities-surveys.component";
import { ClosingNotesModalViewModel } from "../../call-viewmodels/closing-notes-modal.viewmodel";
import { ReceiptDelineationService } from "src/app/services/delineation-services/receipt-delineation.service";
import { PictureDelineationService } from "src/app/services/delineation-services/picture-delineation.service";
import { StepperCallApplicationService } from "../stepper-call-services/stepper-call-application.service";
import { RmWholesaleCall } from "src/app/entity-models/rm-wholesale-call.entity";
import { RmWholesaleStepperStep } from "src/app/enums/rm-wholesale-stepper-step";
import { Router } from "@angular/router";
import { debounceTime } from "rxjs/operators";
import { PictureModalComponent } from "src/app/dialogs/pop-out-img-dialog/img-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { NotificationDelineationService } from "src/app/services/delineation-services/notification-delineation.service";
import { untilDestroyed } from "@ngneat/until-destroy";
import { SystemInformationKeys } from "shield.shared";
import { SystemInformationDelineationService } from "src/app/services/delineation-services/system-information-delineation.service";
import { DialogService } from "src/app/services/dialog.service";
import { CallEventLoggerService, ReceiptRenderLocation, ReceiptRenderType } from "../../call-services/call-event-logger.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { ValidationErrorMessage } from "src/app/shared/cards/validation-error/validation-error.component";

@Component({
    selector: "app-closing-notes",
    templateUrl: "./closing-notes.component.html",
    styleUrls: ["./closing-notes.component.scss"]
})
export class ClosingNotesComponent implements OnInit, OnDestroy {
    @ViewChild("carousel") carousel: MatCarouselComponent;
    @ViewChild("activitiesAndSurveys") activitiesAndSurveys: ActivitiesSurveysComponent;

    @Output() autoPrintRetailReceipts = new EventEmitter();
    @Output() autoPrintWholesaleReceipts = new EventEmitter();

    notificationDelineationService: NotificationDelineationService

    //Public vars
    surveys: Survey[] = [];
    categoryDistributionViewmodels: CallCategoryDistributionViewModel[] = [];
    pictureTags = "";
    pictureType = "";
    pictureString: string = "";
    saveAndShareDisabled = true;
    slides: Picture[] = [];
    step: typeof RetailStepperStep = RetailStepperStep;
    selectedIndexSubscription: Subscription;
    isOnlineSubscription: Subscription;
    callSubscription: Subscription;
    employeeSubscription: Subscription;
    employee: Employee;
    selectedIndex: number;
    call: Call = null;
    closingNotes: string = null;
    validationErrorMessages = signal<ValidationErrorMessage[]>([]);
    displayValidationErrors = computed(() => {
        //Use a computed signal to add "Go To Receipts" link where needed.
        return this.validationErrorMessages().map(message => {
            if (message.text === CallValidationErrors.NoPrintedRetailReceipts ||
                message.text === CallValidationErrors.NoPrintedWholesaleReceipts
            ) {
                return {...message, linkText: "Go To Receipts"};
            }
            return message;
        });
    });
    proportion: number;
    faTimes: IconDefinition = faTimes;
    faEye: IconDefinition = faEye;
    callComments: string = null;
    shareEmployees: Employee[] = [];
    shareComments: string;
    isOnline = false;
    receiptsEmailed = false;

    buildSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    build$: Observable<boolean> = this.buildSubject.asObservable();
    buildSubscription: Subscription;

    retailCallType = CallTypes.retail;
    rmWholesaleCallType = CallTypes.rmWholesale;
    showReceiptsReminder = false; //  default to existing behavior

    //Private vars
    confirmationOverlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;
    closingNotesOverlayRef: SwisherOverlayRef<
        ClosingNotesModalViewModel,
        ClosingNotesComponent
    >;
    shareOverlayRef: SwisherOverlayRef<ShareOptionsDialogViewmodel, ShareOptionsDialogComponent>;
    pictures: Picture[] = [];
    receipts: Receipt[] = [];

    constructor(
        public callService: CallService,
        public injectedData: SwisherOverlayRef<
            ClosingNotesModalViewModel,
            ClosingNotesComponent
        >,
        private overlayService: OverlayService,
        public stepperCallApplicationService: StepperCallApplicationService,
        private callValidationService: CallValidationService,
        private callDistributionService: CallDistributionService,
        private pictureDelineationService: PictureDelineationService,
        private snackbarService: SnackbarService,
        private receiptDelineationService: ReceiptDelineationService,
        private pingService: PingService,
        private router: Router,
        private appStateService: AppStateService,
        private dialog: MatDialog,
        private systemInformationDelineationService: SystemInformationDelineationService,
        private dialogService: DialogService,
        notificationDelineationService: NotificationDelineationService,
        private eventLogger: CallEventLoggerService,
        private customerService: CustomerDelineationService,
    ) {
        this.notificationDelineationService = notificationDelineationService;
    }

    async ngOnInit(): Promise<void> {
        this.proportion = 75;

        this.systemInformationDelineationService.getByKey( SystemInformationKeys.disableAutoPrintReceipts ).then( response => {
            //  only update the value if the flag was present & not NaN
            if ( response?.values?.value ) {
                //  any non-zero int is true
                this.showReceiptsReminder = parseInt( response.values.value ) !== 0;
            }
        } );

        if (!this.buildSubscription || this.buildSubscription.closed) {
            this.buildSubscription = this.build$
            .pipe(debounceTime(250))
            .subscribe((value) => {
                if (value) {
                    this.buildTransactionViewmodelsFromDomainModel();
                }
            });
        }

        if (!this.isOnlineSubscription || this.isOnlineSubscription.closed) {
            this.isOnlineSubscription = this.pingService.online.subscribe((isOnline) => {
                this.isOnline = isOnline;
            });
        }

        if (this.injectedData) {

            this.closingNotesOverlayRef = this.injectedData;
            this.call = this.closingNotesOverlayRef.data.call;
            const picturesResponse = await this.pictureDelineationService.getPicturesByCall(this.call);
            this.pictures = picturesResponse?.values ?? new Array<Picture>();

            this.closingNotes = this.call.closingNotes;

            const response = await this.receiptDelineationService.getCallReceiptsByCall(
                this.call
            );
            this.receipts = response?.values ?? new Array<Receipt>();
            this.buildSubject.next(true);

        } else {
            if (!this.callSubscription || this.callSubscription.closed) {
                this.callSubscription = this.callService.observableCall.subscribe(
                    async (call) => {
                        this.call = call as RetailCall | RmWholesaleCall;
                        if (
                            this.call
                            && ((this.call.callType === CallTypes.retail && this.selectedIndex === RetailStepperStep.closingNotes)
                                || (this.call.callType === CallTypes.rmWholesale && this.selectedIndex === RmWholesaleStepperStep.closingNotes))

                        ) {
                            this.closingNotes = this.call.closingNotes;
                            const pictureIds = this.call?.callPictures?.map((cp) => cp.id);
                            const picturesResponse = await this.pictureDelineationService.getLocalPicturesByIds(pictureIds);
                            if (picturesResponse?.values?.length) {
                                this.pictures = picturesResponse.values;
                            }

                            const response = await this.receiptDelineationService.getLocalReceiptByCall(
                                this.call
                            )

                            if (!response) { return; }
                            this.receipts = response.values;
                            this.buildSubject.next(true);
                        }
                    }
                );
            }

            if (
                !this.selectedIndexSubscription ||
                this.selectedIndexSubscription.closed
            ) {
                this.selectedIndexSubscription = this.stepperCallApplicationService.observableSelectedIndex.subscribe(
                    (selectedIndex) => {
                        this.selectedIndex = selectedIndex;
                        if (
                            selectedIndex === RetailStepperStep.closingNotes &&
                            this.call
                        ) {
                            this.activitiesAndSurveys?.setDisplayActivitiesSurveys();
                            this.buildSubject.next(true);
                        }
                    }
                );
            }
        }

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.employeeSubscription = this.appStateService.currentEmployee.subscribe((employee) => {
                this.employee = employee;
            })
        }
    }

    ngOnDestroy(): void {
        if (
            this.selectedIndexSubscription &&
            !this.selectedIndexSubscription.closed
        ) {
            this.selectedIndexSubscription.unsubscribe();
        }

        if (
            this.callSubscription &&
            !this.callSubscription.closed
        ) {
            this.callSubscription.unsubscribe();
        }

        if (
            this.isOnlineSubscription &&
            !this.isOnlineSubscription.closed
        ) {
            this.isOnlineSubscription.unsubscribe();
        }

        if (
            this.buildSubscription &&
            !this.buildSubscription.closed
        ) {
            this.buildSubscription.unsubscribe();
        }
    }

    openPictureInModal(picture: string, allData: string) {
        this.dialog.open(PictureModalComponent, {
            data: {picture: picture, allData: allData},
            autoFocus: false,
            maxHeight: "80vh",
            width: "800px"
        });
    }

    onJumpToIndex(step: RetailStepperStep): void {
        if (this.injectedData) {
            this.closingNotesOverlayRef.data.selectedIndex = step;
            this.injectedData.close(this.closingNotesOverlayRef.data);
        } else {
            this.stepperCallApplicationService.selectedIndex = step;
        }
    }

    onSaveOrShare( share: boolean ): void {
        if ( this.showReceiptsReminder && this.hasReceiptsPending ) {
            this.openReceiptsReminder();

            return;
        }

        if ( share ) {
            this.onOpenShare();
        } else {
            this.onOpenConfirmation();
        }
    }

    onOpenConfirmation(): void {
        if (this.confirmationOverlayRef) {
            this.confirmationOverlayRef.close();
        }

        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.buttonRightFunction = () => this.onSave();
        data.buttonRightText = "Yes";
        data.buttonLeftText = "No";
        data.header = "Confirmation";
        data.message =
            "Saving this call will complete it and make it un-editable. Are you sure you want to complete the call?";
        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationOverlayRef.afterClosed$.subscribe(() => {
            this.confirmationOverlayRef = undefined;
        });
    }

    onOpenShare(): void {
        const data: ShareOptionsDialogViewmodel = new ShareOptionsDialogViewmodel();
        data.shareEmployees = this.shareEmployees;
        data.confirmButtonText = "Save and Share";
        data.comments = this.callComments;
        data.isOffline = true;
        data.onClose = (closeData: ShareOptionsDialogViewmodel) => {
            this.callComments = closeData.comments;
            this.shareEmployees = closeData.shareEmployees;
        }
        data.onSaveShare = async (
            saveData: ShareOptionsDialogViewmodel
        ): Promise<void> => {
            const ids = saveData.shareEmployees.map((e) => e.id);
            await this.callService.shareCall(
                this.call.createdUserId,
                (`${Helper.formatDisplayName(this.call)}`),
                ids,
                saveData.comments
            );
            this.callService.call.closingNotes = this.closingNotes;
            this.buildCallProductStatuses();
            this.shareOverlayRef.close();
            this.finalizeCall();
        }
        this.shareOverlayRef = this.overlayService.open(
            ShareOptionsDialogComponent,
            data
        );
    }

    openReceiptsReminder(): void {
            this.dialogService.showConfirmDialog(
                `Final receipt(s) have not been printed or emailed.  Go to Receipts now?`,
                "Final Receipts",
                "No",
                "Yes"
            ).subscribe( response => {
                if ( response ) {
                    this.onJumpToIndex( this.step.receipts );
                }
            });
    }

    buildCallProductStatuses(): void {
        for (const item of this.callDistributionService.callProducts) {
            if (item.dateAvailable || item.isIntro || item.isOos || item.isCos) {
                const ps = new CallProductStatus();
                ps.id = newSequentialId();
                ps.intro = item.isIntro;
                ps.outOfStock = item.isOos;
                ps.productId = item.product.id;
                ps.correctiveOffSelf = item.isCos;
                ps.approvedProduct = item.dateAvailable;
                (this.callService.call as RetailCall | RmWholesaleCall).callProductStatus.push(ps);
            }
        }
    }

    async retailReceiptAutoPrintComplete(): Promise<void> {
        if (!this.callService.isFinalWholesaleReceiptPrinted
            && !this.callService.isEmailFinalWholesaleReceipt
            && this.stepperCallApplicationService.hasWholesaleReceipts) {
            this.autoPrintWholesaleReceipts.emit()
        } else {
            await this.completeCall();
        }
    }

    async wholesaleReceiptAutoPrintComplete(): Promise<void> {
        await this.completeCall();
    }

    async printRetailReceipts(): Promise<void> {
        this.autoPrintRetailReceipts.emit();
    }

    async printWholesaleReceipts(): Promise<void> {
        this.autoPrintWholesaleReceipts.emit();
    }

    async completeCall(): Promise<void> {
        let res:boolean = await this.callService.completeCall();
        if (res) {
            this.stepperCallApplicationService.resetCall();
            if (this.callService.routeId) {
                await this.router.navigate(["/my-day/route-management", this.callService.routeId, "details"]);
            } else {
                await this.router.navigate(["/accounts/customers"]);
            }
        }
    }

    async onSave(): Promise<void> {
        this.callService.call.closingNotes = this.closingNotes;
        this.buildCallProductStatuses();
        this.confirmationOverlayRef.close();

        this.finalizeCall();
    }

    get hasReceiptsPending(): boolean {
        return this.isRetailReceiptPending || this.isWholesaleReceiptPending;
    }

    get isRetailReceiptPending(): boolean {
        return this.stepperCallApplicationService.hasRetailReceipts
            && !( this.callService.isFinalRetailReceiptPrinted || this.callService.isEmailFinalRetailReceipt );
    }

    get isWholesaleReceiptPending(): boolean {
        return this.stepperCallApplicationService.hasWholesaleReceipts
            && !( this.callService.isFinalWholesaleReceiptPrinted || this.callService.isEmailFinalWholesaleReceipt );
    }

    private updateStepperIndexForReceipts(): void {
        if (this.call?.callType === CallTypes.retail) {
            this.stepperCallApplicationService.selectedIndex = 5
        } else if (this.call?.callType === CallTypes.rmWholesale) {
            this.stepperCallApplicationService.selectedIndex = 3
        } else if (this.call?.callType === CallTypes.chainHq) {
            this.stepperCallApplicationService.selectedIndex = 3
        } else if (this.call?.callType === CallTypes.wholesale) {
            this.stepperCallApplicationService.selectedIndex = 3
        } else {
            this.stepperCallApplicationService.selectedIndex = 5
        }
    }

    private async finalizeCall() {
        if (this.isRetailReceiptPending) {
            this.updateStepperIndexForReceipts();

            setTimeout(() => {
                this.printRetailReceipts();
            }, 1500);
        } else if (this.isWholesaleReceiptPending) {
            this.updateStepperIndexForReceipts();

            setTimeout(() => {
                this.printWholesaleReceipts();
            }, 1500);
        } else {
            await this.completeCall();
        }
    }

    async buildTransactionViewmodelsFromDomainModel(): Promise<void> {
        if (this.call) {
            await this.stepperCallApplicationService.buildSalesTransactionViewmodelsFromDomainModel(
                this.call
            );
            this.stepperCallApplicationService.buildExchangeTransactionViewmodelsFromDomainModel(
                this.call
            );
            this.categoryDistributionViewmodels = await this.stepperCallApplicationService.buildCategoryDistributionViewmodelsFromDomainModel(
                this.call
            );

            this.slides = this.pictures;
            this.setPictureType(0);

            if (!this.injectedData) {

                const callValidation = this.callValidationService.isCallValid(this.callService.call.id);

                const errorMessages = callValidation.validationViolations
                    .filter(cv => cv.errorLevel === ErrorLevel.invalid)
                    ?.map(cv => cv.message);
                this.validationErrorMessages.set(errorMessages?.map(msg => { return { text: msg } }) ?? []);
                this.saveAndShareDisabled = callValidation.validationViolations
                    .findIndex(vv => (vv.validationStops & ValidationStops.callClosingHardStop) === ValidationStops.callClosingHardStop) !== -1;

            }
        }
    }

    setPictureType(index: number): void {
        if (index || index === 0) {
            if (this.slides?.length > 0) {
                const picture: Picture = this.slides[index];
                this.pictureString = picture?.image ? picture.image : ''
                if (picture) {
                    const callPicture = (this.call as RetailCall | RmWholesaleCall).callPictures.find(
                        (p) => p.id === picture.id
                    );
                    this.pictureType = callPicture?.type;

                    if (callPicture.tags) {
                        this.pictureTags = callPicture.tags.join(", ");
                    } else {
                        this.pictureTags = "";
                    }
                }

                setTimeout(() => {
                    this.carousel.slideTo(index > 0 ? index : 0);
                }, 0);
            }
        }
    }

    async reprint(): Promise<void> {
        if (!this.receipts?.length) {
            this.snackbarService.showError("No receipt was found in local or the server storage location");
            return;
        }
        if (this.call.callType === CallTypes.retail
            || this.call.callType === CallTypes.rmWholesale) {
            const ids = this.call.callReceipts?.map((cr) => cr.id);
            if (ids?.length) {
                const found = this.receipts.filter((r) => ids.includes(r.id));
                if (found) {
                    Helper.addIFrameImage(document, found.map((r) => r.base64Image));
                }
            }
        }
    }

    async email(): Promise<void> {
        const data: ShareOptionsDialogViewmodel = new ShareOptionsDialogViewmodel();
        data.shareEmployees = this.shareEmployees;
        data.confirmButtonText = "Share Receipt";
        data.comments = this.shareComments;
        data.customerId = this.call.customerId;
        data.onClose = (closeData: ShareOptionsDialogViewmodel) => {
            this.shareComments = closeData.comments;
            this.shareEmployees = closeData.shareEmployees;
        }
        data.onSaveShare = async (
            saveData: ShareOptionsDialogViewmodel
        ): Promise<boolean> => {
            this.shareComments = saveData.comments;
            let isSuccessfull = true;

            if (this.call.callType === CallTypes.retail
                || this.call.callType === CallTypes.rmWholesale) {

                if (this.receipts?.length) {

                    if (saveData.shareEmployees) {
                        const ids = saveData.shareEmployees.map((e) => e.id);

                        this.receiptsEmailed = true;
                        if (ids.length > 0) {
                            for (const employeeId of ids) {
                                const response = await this.notificationDelineationService.emailReceipt(employeeId, this.call.id,
                                    this.receipts[0].id, this.call.callReceipts[0].receiptNumber, EmailReceiptLocationOrigins.invoice, this.shareComments);
                                if (response) {
                                    isSuccessfull = isSuccessfull && !!response;
                                }
                            }
                        }
                    }

                    if (saveData.shareCustomerContact) {
                        const response = await this.notificationDelineationService.emailReceiptToCustomerContact(saveData.shareCustomerContact.email, this.call.id,
                            this.receipts[0].id, this.call.callReceipts[0].receiptNumber, EmailReceiptLocationOrigins.invoice, this.shareComments);
                        if (response) {
                            isSuccessfull = isSuccessfull && !!response;
                        }
                    }

                    const customer = (await this.customerService.getById(this.call.customerId)).values;
                    this.eventLogger.logReceiptRender(
                        ReceiptRenderType.Email,
                        ReceiptRenderLocation.CallHistory,
                        this.call,
                        customer,
                    );

                    return isSuccessfull;
                }
            }
            return undefined;
        }
        this.shareOverlayRef = this.overlayService.open(
            ShareOptionsDialogComponent,
            data
        );

        this.shareOverlayRef.afterClosed$.pipe(untilDestroyed(this)).subscribe(async (ref) => {
            if (ref?.data?.promise) {
                const isSuccess = await ref.data.promise;
                if (isSuccess) {
                    this.snackbarService.showInfo("Email sent successfully!");
                }
            }
        })
    }
}
