import { Injectable, TemplateRef } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { skip, take } from "rxjs/operators";
import { SystemInformationKeys } from "shield.shared";
import { ProgressSpinnerDialogComponent } from "../dialogs/progress-spinner-dialog/progress-spinner-dialog.component";
import { SystemInformationDelineationService } from "./delineation-services/system-information-delineation.service";

@Injectable({
    providedIn: "root"
})
export class PleaseWaitService {
    private count = 0;

    private dialogRef: MatDialogRef<ProgressSpinnerDialogComponent, unknown>;

    private enableClickClang = false;

    constructor(
        private dialog: MatDialog,
        private systemInformationDelineationService: SystemInformationDelineationService
    ) {
        void this.initialize();
    }

    async initialize(): Promise<void> {
        const response = await this.systemInformationDelineationService.getByKey(SystemInformationKeys.enableClickClang);
        if (response?.values?.value === "true" && window.localStorage.getItem("enableClickClang") === "true") {
            this.enableClickClang = true;
        } else {
            this.enableClickClang = false;
        }
    }


    showProgressSpinnerUntilLoaded<T>(observable: Observable<T>, text?: string, template?: TemplateRef<any>): void {
        this.openSpinner(text);
        // Skiping the initial value from the behavior subject and taking the second for the update before unsubscribing on its own.
        observable.pipe(skip(1), take(1)).subscribe({
            next: () => {
                this.closeSpinnerIfFinished();
            },
            complete: () => {
                this.dialogRef.close();
            }
        });
    }

    private closeSpinnerIfFinished() {
        this.count--;
        if (this.count === 0) {
            this.dialogRef.close();
        }
    }

    private openSpinner(text: string, template?: TemplateRef<any>) {
        if (this.count === 0) {
            this.dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
                panelClass: "transparent",
                disableClose: true,
            });
            this.dialogRef.componentInstance.enableClickClang = this.enableClickClang;
            if (text) {
                this.dialogRef.componentInstance.text = text;
            } else if (template) {
                this.dialogRef.componentInstance.contentTemplate = template;
            }
        }
        this.count++;
    }

    async withSpinnerShowing<T>(
        func: () => Promise<T>,
        text?: string,
        template?: TemplateRef<any>
    ): Promise<T> {
        this.openSpinner(text, template);
        try {
            return await func();
        } catch (e) {
            throw e;
        } finally {
            this.closeSpinnerIfFinished();
        }
    }

    killPleaseWait(): void {
        this.count = 0;
        this.dialogRef.close();
    }
}
