import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '../app.module';

@Injectable()
export class GeolocationService {

    constructor(@Inject(WINDOW) private window: Window) { }

    async getCurrentPosition(): Promise<google.maps.LatLngLiteral | undefined> {
        if (this.window.navigator.geolocation) {
            try {
                return await new Promise((res, rej) => {
                    this.window.navigator.geolocation.getCurrentPosition(
                        position => {
                            res({
                                lat: position.coords.latitude,
                                lng: position.coords.longitude
                            });
                        },
                        error => rej(error));
                });
            } catch (e) {
                if (e instanceof GeolocationPositionError) {
                    console.error(`Geolocation error: ${e.message}`);
                    return undefined;
                } else {
                    throw e;
                }
            }
        } else {
            console.error("This web browser does not support geolocation.");
            return undefined;
        }
    }
}
