/* eslint-disable no-bitwise */
import { Injectable } from "@angular/core";
import { RetailStepperStep } from "src/app/enums/retail-stepper-step";
import { CallTypes, newSequentialId } from "shield.shared";
import { ErrorLevel } from "../account-enums/error-level";
import { ValidationStops } from "../account-enums/validation-stops";
import { ValidationError } from "../account-models/validationError";
import { CallService } from "../call-master/call-services/call.service";
import { CallValidationViewmodel } from "../account-models/call-validation.viewmodel";
import { StepperCallApplicationService } from "../call-master/stepper-call/stepper-call-services/stepper-call-application.service";
import { RmWholesaleStepperStep } from "src/app/enums/rm-wholesale-stepper-step";

export enum CallValidationErrors {
    NoPrintedRetailReceipts = "Final Retail Receipts haven't been Printed or Emailed",
    NoPrintedWholesaleReceipts = "Final Wholesale Receipts haven't been Printed or Emailed",
}
@Injectable()
export class CallValidationService {
    static taxValidationMessage = "Tax calculations need to be visited for this customer's state.";
    constructor(
        private stepperCallApplicationService: StepperCallApplicationService,
        private callService: CallService
    ) {}

    addValidationError(errorLevel: ErrorLevel, validationStops: ValidationStops, message: string, hoverMessage?: string): ValidationError {
        const rtn: ValidationError = new ValidationError();
        rtn.errorLevel = errorLevel;
        rtn.validationStops = validationStops;
        rtn.message = message;
        if (message && !hoverMessage) {
            rtn.hoverMessage = rtn.message;
        } else {
            rtn.hoverMessage = hoverMessage;
        }

        return rtn;
    }

    isCallStepValid(
        index: number,
        skipFarthestIndex?: boolean
    ): ValidationError[] {
        const rtn: ValidationError[] = [];
        if (this.callService.call?.callType === CallTypes.retail || this.callService.call?.callType === CallTypes.rmWholesale) {

            let steppercallApplicationService: StepperCallApplicationService;
            const callType = this.callService.call?.callType;
            if (callType === CallTypes.retail
                || callType === CallTypes.rmWholesale) {
                steppercallApplicationService = this.stepperCallApplicationService;
            }

            if (!skipFarthestIndex) {
                if (this.callService.call?.farthestIndex < index) {
                    return rtn;
                }
            }


            switch (index) {
                case (callType === CallTypes.retail && RetailStepperStep.salesGratis)
                    || (callType === CallTypes.rmWholesale && RmWholesaleStepperStep.salesGratis): {
                    if (
                        steppercallApplicationService.totalDiscountPercent >
                        0.08
                    ) {
                        rtn.push(this.addValidationError(ErrorLevel.warning, ValidationStops.none, "Total Discount/Gratis can't exceed 8%."));
                    }

                    if (this.callService.call.shouldVisitTax) {
                        rtn.push(this.addValidationError(ErrorLevel.invalid
                            , ValidationStops.signatureHardStop | ValidationStops.callClosingHardStop | ValidationStops.retailReceiptHardStop
                            , CallValidationService.taxValidationMessage));
                    }

                    if (
                        this.callService.call?.cashProducts?.find(
                            (product) => !!product.discount
                                && product.discount >= product.price
                        )
                    ) {
                        rtn.push(this.addValidationError(ErrorLevel.invalid
                            , ValidationStops.callClosingHardStop | ValidationStops.retailReceiptHardStop | ValidationStops.signatureHardStop
                            , "Cash Sale Item: Discount cannot equal or exceed price."));
                    }

                    if (
                        steppercallApplicationService.callCashProductViewModels.findIndex(
                            (cash) => cash.price === 0
                        ) !== -1
                    ) {
                        rtn.push(this.addValidationError(ErrorLevel.invalid
                            , ValidationStops.signatureHardStop | ValidationStops.callClosingHardStop | ValidationStops.retailReceiptHardStop
                            , "Cash sales cannot contain a zero price."));
                    }

                    if (
                        steppercallApplicationService.callGratisProductViewModels.findIndex(
                            (gratis) => gratis.value === 0
                        ) !== -1
                    ) {
                        rtn.push(this.addValidationError(ErrorLevel.invalid
                            , ValidationStops.signatureHardStop | ValidationStops.callClosingHardStop | ValidationStops.retailReceiptHardStop
                            , "Gratis cannot contain a zero price."));
                    }

                    const mySet = new Set<string>();
                    let isValid = true;
                    for (const order of steppercallApplicationService
                        .callOrderProductViewModels) {
                        // We want to allow multiples if the wholesaler has no value as the validation of
                        // "A wholesaler and UIN must be selected on order sales.", will catch it.
                        const value =
                            order.product.id + "_" + (order.wholesaler?.length ? order.wholesaler[0]?.wholesaler?.id : newSequentialId());
                        if (!mySet.has(value)) {
                            mySet.add(value);
                        } else {
                            isValid = false;
                        }
                        if (!isValid) {
                            rtn.push(this.addValidationError(ErrorLevel.invalid
                                , ValidationStops.signatureHardStop | ValidationStops.callClosingHardStop | ValidationStops.wholesaleReceiptHardStop
                                , "A Product can only be selected once per wholesaler."));
                            break;
                        }
                    }

                    let areWholsalersPresent = true;
                    for (const order of steppercallApplicationService
                        .callOrderProductViewModels) {
                        if (
                            order.uin === "0" ||
                            !order.uin ||
                            !order.wholesaler
                        ) {
                            areWholsalersPresent = false;
                            break;
                        }
                    }

                    if (!areWholsalersPresent) {
                        rtn.push(this.addValidationError(ErrorLevel.invalid
                            , ValidationStops.signatureHardStop | ValidationStops.callClosingHardStop | ValidationStops.wholesaleReceiptHardStop
                            , "A wholesaler and UIN must be selected on order sales."));
                    }
                    break;
                }
                case (callType === CallTypes.retail && RetailStepperStep.exchange)
                    || (callType === CallTypes.rmWholesale && RmWholesaleStepperStep.exchange): {
                    if (
                        (steppercallApplicationService.totalDiffRetail &&
                            steppercallApplicationService.totalDiffRetail <
                                0)
                    ) {
                        rtn.push(this.addValidationError(ErrorLevel.invalid
                            , ValidationStops.signatureHardStop | ValidationStops.callClosingHardStop | ValidationStops.retailReceiptHardStop
                            , "Total Diff Retail cannot be negative on the Exchange Step."));
                    }


                    if (this.callService.call.shouldVisitTax) {
                        rtn.push(this.addValidationError(ErrorLevel.invalid
                            , ValidationStops.signatureHardStop | ValidationStops.callClosingHardStop
                            , CallValidationService.taxValidationMessage));
                    }

                    let isProductInRetailPriceValid = true;
                    let isProductInWholeSalePriceValid = true;
                    let isProductOutRetailPriceValid = true;
                    let isProductOutWholeSalePriceValid = true;

                    for (const productIn of steppercallApplicationService
                        .callProductInViewModels) {
                        if (productIn.price === 0) {
                            isProductInRetailPriceValid = false;
                        }
                        if (productIn.wholesalePrice === 0) {
                            isProductInWholeSalePriceValid = false;
                        }
                    }

                    for (const productOut of steppercallApplicationService
                        .callProductOutViewModels) {
                        if (productOut.price === 0) {
                            isProductOutRetailPriceValid = false;
                        }
                        if (productOut.wholesalePrice === 0) {
                            isProductOutWholeSalePriceValid = false;
                        }
                    }

                    if (!isProductInRetailPriceValid) {
                        rtn.push(this.addValidationError(ErrorLevel.invalid
                            , ValidationStops.signatureHardStop | ValidationStops.callClosingHardStop | ValidationStops.retailReceiptHardStop
                            , "ExchangeIn product cannot contain a zero retail price."));
                    }

                    if (!isProductInWholeSalePriceValid) {
                        rtn.push(this.addValidationError(ErrorLevel.invalid
                            , ValidationStops.signatureHardStop | ValidationStops.callClosingHardStop | ValidationStops.retailReceiptHardStop
                            , "ExchangeIn product cannot contain a zero wholesale price."));
                    }

                    if (!isProductOutRetailPriceValid) {
                        rtn.push(this.addValidationError(ErrorLevel.invalid
                            , ValidationStops.signatureHardStop | ValidationStops.callClosingHardStop | ValidationStops.retailReceiptHardStop
                            , "ExchangeOut product cannot contain a zero retail price."));
                    }

                    if (!isProductOutWholeSalePriceValid) {
                        rtn.push(this.addValidationError(ErrorLevel.invalid
                            , ValidationStops.signatureHardStop | ValidationStops.callClosingHardStop | ValidationStops.retailReceiptHardStop
                            , "ExchangeOut product cannot contain a zero wholesale price."));
                    }
                    break;
                }
                case (callType === CallTypes.retail && RetailStepperStep.receipts)
                    || (callType === CallTypes.rmWholesale && RmWholesaleStepperStep.receipts):
                    if (
                        !this.callService.call?.signature &&
                        (steppercallApplicationService.hasRetailReceipts ||
                            steppercallApplicationService
                                .hasWholesaleReceipts)
                    ) {
                        rtn.push(this.addValidationError(ErrorLevel.invalid
                            , ValidationStops.callClosingHardStop | ValidationStops.retailReceiptHardStop | ValidationStops.wholesaleReceiptHardStop
                            , "Signature from the Customer is required."));
                    }

                    if (
                        this.callService.call &&
                        !this.callService.call.isFinalRetailReceiptPrinted &&
                        !this.callService.call.isEmailFinalRetailReceipt &&
                        steppercallApplicationService.hasRetailReceipts
                    ) {
                        rtn.push(this.addValidationError(ErrorLevel.invalid
                            , ValidationStops.callClosingSoftStop
                            , CallValidationErrors.NoPrintedRetailReceipts));
                    }

                    if (this.callService.call &&
                        !this.callService.call.isFinalWholesaleReceiptPrinted &&
                        !this.callService.call.isEmailFinalWholesaleReceipt &&
                        steppercallApplicationService.hasWholesaleReceipts
                    ) {
                        rtn.push(this.addValidationError(ErrorLevel.invalid
                            , ValidationStops.callClosingSoftStop
                            , CallValidationErrors.NoPrintedWholesaleReceipts));
                    }
                    break;
                case (callType === CallTypes.retail && RetailStepperStep.after)
                     || (callType === CallTypes.rmWholesale && RmWholesaleStepperStep.after): {
                    const beforePicture = this.callService.call?.callPictures.find(
                        (p) => p.type === "Before"
                    );
                    const afterPicture = this.callService.call?.callPictures.find(
                        (p) => p.type === "After"
                    );

                    if (beforePicture && !afterPicture) {
                        rtn.push(this.addValidationError(ErrorLevel.invalid
                            , ValidationStops.callClosingHardStop
                            , "After picture is required if a before picture has been taken."));
                    }
                    break;
                }
                default:
                    break;
            }
        }

        return rtn;
    }

    isCallValid(callId: string): CallValidationViewmodel {
        let rtn: CallValidationViewmodel = new CallValidationViewmodel();
        rtn.callId = callId;
        rtn.validationViolations = [];

        let length = 0;
        if (this.callService.call?.callType === CallTypes.retail) {
            length = RetailStepperStep.closingNotes;
        }
        else if (this.callService.call?.callType === CallTypes.rmWholesale) {
            length = RmWholesaleStepperStep.closingNotes;
        }

        for (let i = 0; i <= length; i++) {
            rtn.validationViolations = rtn.validationViolations.concat(this.isCallStepValid(i));
        }
        return rtn;
    }

    private isAlphaNumeric(value: string): boolean {
        return !!/^[0-9a-zA-Z]+$/.exec(value);
    }
}
