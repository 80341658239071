import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule, Title } from "@angular/platform-browser";
import { ErrorHandler, InjectionToken, NO_ERRORS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { Helper } from "./helpers/helper";
import { NgxCurrencyDirective, provideEnvironmentNgxCurrency } from "ngx-currency";

//Angular Material
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';

import {
    IPublicClientApplication,
    PublicClientApplication,
    InteractionType,
    BrowserCacheLocation,
    LogLevel,
    ILoggerCallback
} from "@azure/msal-browser";
import {
    MsalGuard,
    MsalInterceptor,
    MsalBroadcastService,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalGuardConfiguration
} from "@azure/msal-angular";

import { ApiGatewayInterceptor } from "./auth/apiGatewayInterceptor";
import { LoggerOptions } from "@azure/msal-common";
import { AppComponent } from "./app.component";
import { environment } from "../environments/environment";

import { HomeComponent } from "./home/home.component";
import { NavMenuComponent } from "./nav-menu/nav-menu.component";

import { AppRoutingModule } from "./app.routing.module";
import { AccountsModule } from "./accounts/accounts.module";

import { FormsModule } from "@angular/forms";
import { ServiceWorkerModule } from "@angular/service-worker";

import { MatSnackBarModule } from "@angular/material/snack-bar";
import { OverlayModule } from "@angular/cdk/overlay";
import { OverlayComponent } from "./overlay/overlay.component";
import { OverlayService } from "./services/overlay.service";
import { UserModule } from "./user/user.module";
import { SharedModule } from "./shared/shared.module";
import { PleaseWaitService } from "./services/please-wait.service";
import { MatMenuModule } from "@angular/material/menu";
import { dataSyncHandlerProviders } from "./sync/data-sync-handler-providers";
import { TransactionOnlineService } from "./services/online-services/transaction-online.service";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { OrderListRefinerService } from "./orders/orders-list/orders-list-refiner.service";
import { OrderExtractionRefinerService } from "./orders/order-extraction/order-extraction-refiner.service";
import { TransactionsListRefinerService } from "./sales/transactions/transactions-list/transactions-list-refiner.service";
import { OrderExtractionWholesalerService } from "./orders/order-extraction/order-extraction-wholesaler.service";
import { SalesModule } from "./sales/sales.module";
import { MyDayModule } from "./my-day/my-day.module";
import { DetailsModule } from "./details/details.module";
import { MatIconModule } from "@angular/material/icon";
import { SplashComponent } from "./splash/splash.component";
import { PingService } from "./services/ping.service";
import { DatasourceDelineationService } from "./services/delineation-services/datasource-delineation.service";
import { CustomerContractDelineationService } from "./services/delineation-services/customer-contract-delineation.service";
import { CustomerDelineationService } from "./services/delineation-services/customer-delineation.service";
import { ContractTemplateDelineationService } from "./services/delineation-services/contract-template-delineation.service";
import { ServicesModule } from "./services/services.module";
import { ContactDelineationService } from "./services/delineation-services/contact-delineation.service";
import { ProjectDelineationService } from "./services/delineation-services/project-delineation.service";
import { SystemInformationDelineationService } from "./services/delineation-services/system-information-delineation.service";
import { CallDelineationService } from "./services/delineation-services/call-delineation.service";
import { PictureDelineationService } from "./services/delineation-services/picture-delineation.service";
import { SurveyDelineationService } from "./services/delineation-services/survey-delineation.service";
import { WholesalerGroupMemberDelineationService } from "./services/delineation-services/wholesaler-group-member-delineation.service";
import { EmployeeDelineationService } from "./services/delineation-services/employee-delineation.service";
import { AccountOwnershipDelineationService } from "./services/delineation-services/account-ownership-delineation.service";
import { ContractPaymentDelineationService } from "./services/delineation-services/contract-payment-delineation.service";
import { VolumeDelineationService } from "./services/delineation-services/volume-delineation.service";
import { ProductDelineationService } from "./services/delineation-services/product-delineation.service";
import { ValidationModule } from "./validation/validation.module";
import { StateLicenseDelineationService } from "./services/delineation-services/state-license-delineation.service";
import { ReceiptDelineationService } from "./services/delineation-services/receipt-delineation.service";
import { TransactionDelineationService } from "./services/delineation-services/transaction-delineation.service";
import { TimeEntryDelineationService } from "./services/delineation-services/time-entry-delineation.service";
import { RouteDelineationService } from "./services/delineation-services/route-delineation.service";
import { NotificationDelineationService } from "./services/delineation-services/notification-delineation.service";
import { WholesalerGroupProductCatalogItemDelineationService } from "./services/delineation-services/wholesaler-group-product-catalog-item-delineation.service";
import { StateDelineationService } from "./services/delineation-services/state-delineation.service";
import { TaxRateDelineationService } from "./services/delineation-services/tax-rate-delineation.service";
import { RegisteredUserDelineationService } from "./services/delineation-services/registered-user-delineation.service";
import { ReceiptSettingsDelineationService } from "./services/delineation-services/receipt-settings-delineation.service";
import { TimeEntryTypeDelineationService } from "./services/delineation-services/time-entry-type-delineation.service";
import { GratisDelineationService } from "./services/delineation-services/gratis-delineation.service";
import { OrderDelineationService } from "./services/delineation-services/order-delineation.service";
import { SyncService } from "./services/sync.service";
import { ResourcesModule } from "./resources/resources.module";
import { WholesalerGroupDelineationService } from "./services/delineation-services/wholesaler-group-delineation.service";
import { ZoneRecapDelineationService } from "./services/delineation-services/zone-recap-delineation.service";
import { DailySummaryEntryDelineationService } from "./services/delineation-services/daily-summary-entry-delineation.service";
import { SyncGateComponent } from "./splash/sync-gate/sync-gate.component";

import { ApplicationinsightsAngularpluginErrorService, IErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { Px3DelineationService } from "./services/delineation-services/px3-delineation.service";
import { provideEnvironmentNgxMask } from "ngx-mask";
import { customCurrencyMaskConfig } from "./shared/constants/custom-currency-mask.config";
import { GoogleMapsModule } from "@angular/google-maps";
import { MissingReceiptsModule } from "./resources/missing-receipts/missing-receipts.module";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";


const loggerCallback: ILoggerCallback = (
    level: LogLevel,
    message: string,
    containsPii: boolean
): void => console.log("MSAL: " + (containsPii ? "[PII] " : "") + message);

const msalInstanceFactory = (): IPublicClientApplication => {
    const redirectUri = window.location.origin.replace(
        /http:\/\//gi,
        "https://"
    );

    const msalLogEnabled = window.localStorage.getItem("msal_log") != null;

    const loggerOptions: LoggerOptions = msalLogEnabled
        ? {
              loggerCallback,
              logLevel: LogLevel.Verbose,
              piiLoggingEnabled: true
          }
        : {};

    return new PublicClientApplication({
        auth: {
            authority: `https://login.microsoftonline.com/${environment.auth.tenantId}`,
            clientId: environment.auth.clientId,
            redirectUri,
            postLogoutRedirectUri: '/'
        },
        system: {
            loggerOptions
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: false // set to true for IE 11
        }
    });
};

const msalInterceptorConfigFactory = (): MsalInterceptorConfiguration => {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", [
        "user.read"
    ]);

    protectedResourceMap.set(environment.apiRootUrl + "api/*", [
        "openid",
        "profile",
        "email",
        environment.auth.apiScope
    ]);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
};

const msalGuardConfigFactory = (): MsalGuardConfiguration => {
    return { interactionType: InteractionType.Redirect };
};

export class LogErrorsToConsole implements IErrorService {
    name = "LogErrorsToConsole" as const;
    handleError(error: any): void {
        console.error(error);
    }
}

const aiFactory = (ai: ApplicationinsightsAngularpluginErrorService) => {
    ai.addErrorHandler(new LogErrorsToConsole());
    return ai;
}

export const WINDOW = new InjectionToken<Window>('Global window object', {
    factory: () => window,
});

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        OverlayComponent,
        SplashComponent,
        SyncGateComponent
    ],
    imports: [
        CommonModule,
        BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
        HttpClientJsonpModule,
        HttpClientModule,
        NgbModule,
        MsalModule,
        MyDayModule,
        AppRoutingModule,
        MatMenuModule,
        MatIconModule,
        ServicesModule,
        BrowserAnimationsModule,
        FormsModule,
        MatSnackBarModule,
        OverlayModule,
        AccountsModule,
        DetailsModule,
        UserModule,
        SalesModule,
        SharedModule,
        MissingReceiptsModule,
        FontAwesomeModule,
        MatPaginatorModule,
        MatSidenavModule,
        MatTableModule,
        MatInputModule,
        MatSelectModule,
        MyDayModule,
        ValidationModule,
        ResourcesModule,
        GoogleMapsModule,
        ServiceWorkerModule.register("/ngsw-worker.js", {
            enabled: environment.production
        }),
        NgxCurrencyDirective,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiGatewayInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: msalInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: msalGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: msalInterceptorConfigFactory
        },
        {
            provide: ErrorHandler,
            useFactory: aiFactory,
            deps: [ApplicationinsightsAngularpluginErrorService]
        },
        //As of 4/2024 This is used to get around an angular bug that causes the hint field on
        //mat-form-field to always display, even if it's blank.
        //See https://github.com/angular/components/issues/24980
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                subscriptSizing: 'dynamic'
            },
        },
        AccountOwnershipDelineationService,
        CallDelineationService,
        ContactDelineationService,
        ContractPaymentDelineationService,
        ContractTemplateDelineationService,
        CustomerContractDelineationService,
        CustomerDelineationService,
        DailySummaryEntryDelineationService,
        DatasourceDelineationService,
        EmployeeDelineationService,
        GratisDelineationService,
        Helper,
        MsalBroadcastService,
        MsalGuard,
        MsalService,
        NotificationDelineationService,
        OrderDelineationService,
        OrderExtractionRefinerService,
        OrderExtractionWholesalerService,
        OrderListRefinerService,
        OverlayService,
        PictureDelineationService,
        PingService,
        PleaseWaitService,
        ProductDelineationService,
        ProjectDelineationService,
        Px3DelineationService,
        ReceiptDelineationService,
        ReceiptSettingsDelineationService,
        RegisteredUserDelineationService,
        RouteDelineationService,
        StateDelineationService,
        StateLicenseDelineationService,
        SystemInformationDelineationService,
        SurveyDelineationService,
        SyncService,
        TaxRateDelineationService,
        TimeEntryDelineationService,
        TimeEntryTypeDelineationService,
        Title,
        TransactionDelineationService,
        TransactionsListRefinerService,
        TransactionOnlineService,
        VolumeDelineationService,
        WholesalerGroupDelineationService,
        WholesalerGroupMemberDelineationService,
        WholesalerGroupProductCatalogItemDelineationService,
        ZoneRecapDelineationService,
        ...dataSyncHandlerProviders(),
        provideEnvironmentNgxMask(),
        provideEnvironmentNgxCurrency(customCurrencyMaskConfig)
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
