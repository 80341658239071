import { Component, EventEmitter, input, Input, output, Output } from '@angular/core';

export interface ValidationErrorMessage {
    text: string;
    linkText?: string;
}

@Component({
  selector: 'validation-error',
  templateUrl: './validation-error.component.html',
  styleUrl: './validation-error.component.scss'
})
export class ValidationErrorComponent {
    messages = input.required<ValidationErrorMessage[]>();
    click = output<number>()

    onLinkClicked(i: number) {
        this.click.emit(i);
    }
}
