@if (messages().length > 0) {
    <div class="alert alert-danger" role="alert">
        <div class="mb-2">
            <b>Validation Summary</b>
        </div>
        @for(message of messages(); track message; let i = $index) {
            <div>
                <mat-icon class="d-inline">warning</mat-icon>
                {{ message.text }}
                @if (message.linkText) {
                    <a class="alert-link" (click)="onLinkClicked(i)">{{message.linkText}}</a>
                }
            </div>
        }
    </div>
}
