import moment, { Moment } from "moment";
import { PDFDocument } from "pdf-lib";
import {
    CustomerTypeEnum,
    EmployeeRoleType,
    SharedHelper,
    valueSeparator
} from "shield.shared";
import { Address } from "../entity-models/address.entity";
import { Customer } from "../entity-models/customer.entity";
import { Employee } from "../entity-models/employee.entity";
import { CustomerGenericTypes } from "../enums/customer-generic-types";
import Dexie from "dexie";
import { WholesalerProductCatalogItems } from "../entity-models/wholesaler-product-catalog-items.entity";

export class Helper {

    /**
     * Alternative implementation to Dexie's anyOf which seems to give better performance (By 10x)
     * in at least one situation.
     * An alternative to this method can probably be made using table.get for unique indexes.
     * @param table The Dexie table to query
     * @param key The key to query on.
     * @param anyOfValues The values to check on the index
     * @returns All database records where the key matches any of the provided values.
     */
    static async anyOf<T>(table: Dexie.Table<T, string>, key: string, anyOfValues: string[]): Promise<T[]> {
        const unflattenedResponse = await Promise.all(
            anyOfValues.map(v => table.where(key).equals(v).toArray())
        );
        return unflattenedResponse.reduce((cur, resp) => [...cur, ...resp], []);
    }

    static notAvailableInOfflineMode(): never {
        throw new Error("This feature is not available in offline mode.");
    }


    static async addIFrameImage(document: Document, images: string[]): Promise<void> {

        const pdfDoc = await PDFDocument.create();

        for (const image of images) {
            const myDoc = await PDFDocument.load(image);
            const page = await pdfDoc.copyPages(myDoc, myDoc.getPageIndices());
            page.forEach((page) => pdfDoc.addPage(page));
        }

        const pdfBytes = await pdfDoc.save();
        const file = new Blob([pdfBytes], { type: "application/pdf" });
        const fileUrl = URL.createObjectURL(file);

        const theWindow = window.open(fileUrl);
        //give the window a cycle to open the new tab.
        // If we don't there are times you can hit a can't read document of iundefined
        await Helper.timeOutAsPromise(() => {
            if (theWindow?.document?.body) {
                const theDoc = theWindow.document;
                const theScript = document.createElement("script");
                theDoc.body.appendChild(theScript);
            } else {
                console.log('Console not ready for script addition');
            }
        }, 500);
    }

    static async getFileUrlFromDocument(document: Document, images: string[]): Promise<string> {

        const pdfDoc = await PDFDocument.create();

        for (const image of images) {
            const myDoc = await PDFDocument.load(image);
            const page = await pdfDoc.copyPages(myDoc, myDoc.getPageIndices());
            page.forEach((page) => pdfDoc.addPage(page));
        }

        const pdfBytes = await pdfDoc.save();
        const file = new Blob([pdfBytes], { type: "application/pdf" });
        return URL.createObjectURL(file);
    }

    static pdfBlobConversion(b64Data: string, contentType: string): Blob {
        contentType = contentType || "";
        const sliceSize = 512;
        b64Data = b64Data.replace(/^[^,]+,/, "");
        b64Data = b64Data.replace(/\s/g, "");
        const byteCharacters = window.atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset = offset + sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    static equalsIgnoreCase(a: string, b: string): boolean {
        if (!a && !b) {
            return true;
        }
        if (!a && b) {
            return false;
        }
        if (a && !b) {
            return false;
        }

        return a.toLocaleLowerCase() === b.toLocaleLowerCase();
    }

    static roundMoment(moment: moment.Moment, isRoundedDown = true): moment.Moment {
        if (moment && moment.isValid) {
            if (isRoundedDown) {
                return moment.set("hour", 0).set("minute", 0).set("second", 0).set("milliseconds", 0);
            } else {
                return moment.set("hour", 23).set("minute", 59).set("second", 59).set("milliseconds", 0);
            }
        }
    }

    static validateMin(
        num: number,
        minNumber?: number,
        allowDecimals = false
    ): number {
        let rtn: number = (minNumber ??= 1);

        if (num) {
            if (!isNaN(num) && num > minNumber) {
                if (allowDecimals) {
                    rtn = num;
                } else {
                    rtn = Math.round(num);
                }
            }
        }
        return rtn;
    }

    static groupBy<T>(
        array: T[],
        keyGetter: (a: T) => string
    ): Map<string, T[]> {

        return SharedHelper.groupBy(array, keyGetter);
    }

    //Useage
    // const grouped = groupBy(pets, pet => pet.type);

    /**
     * Group an array by a key and extract a value
     *
     * @param array Array to be grouped
     * @param keyGetter Function to extract a key from the items in the array
     * @param valueGetter Function to extract the value to be returned in the groups
     * @returns A map of the grouped values by key
     */
    static groupByValue<T, K, V>(
        array: T[],
        keyGetter: (a: T) => K,
        valueGetter: (a: T) => V
    ): Map<K, V[]> {
        const map = new Map<K, V[]>();
        array.forEach((item) => {
            const key = keyGetter(item);
            const value = valueGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [value]);
            } else {
                collection.push(value);
            }
        });
        return map;
    }

    static chunkArray<T>(array: T[], size: number): T[][] {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            const chunk = array.slice(i, i + size);
            result.push(chunk);
        }
        return result;
    }

    static getCustomerGenericType(
        customer: Customer
    ): CustomerGenericTypes {
        if (!customer) return;

        let rtn: CustomerGenericTypes = null;
        switch (customer.customerType.id) {
            case CustomerTypeEnum.IndependentRetail:
            case CustomerTypeEnum.ChainRetail:
                rtn = CustomerGenericTypes.retail;
                break;
            case CustomerTypeEnum.DirectWholesaler:
            case CustomerTypeEnum.IndirectWholesaler:
                rtn = CustomerGenericTypes.wholesale;
                break;
            case CustomerTypeEnum.ChainHQ:
                rtn = CustomerGenericTypes.chainHeadquarter;
                break;
            default:
                throw new Error("StoreType not mapped to customer type: " + customer.customerType.name + ".");
                break;
        }
        return rtn;
    }

    static getStoreTypesByCustomerGenericType(
        customerGenericType: CustomerGenericTypes
    ): CustomerTypeEnum[] {
        const rtn: CustomerTypeEnum[] = [];
        switch (customerGenericType) {
            case CustomerGenericTypes.retail: {
                rtn.push(CustomerTypeEnum.IndependentRetail);
                rtn.push(CustomerTypeEnum.ChainRetail);
            }
                break;
            case CustomerGenericTypes.wholesale: {
                rtn.push(CustomerTypeEnum.DirectWholesaler);
                rtn.push(CustomerTypeEnum.IndirectWholesaler);
            }
                break;
            case CustomerGenericTypes.chainHeadquarter: {
                rtn.push(CustomerTypeEnum.ChainHQ);
            }
                break;
            default:
                throw new Error(`No store types are mapped to Customer Generic Type: ${customerGenericType as string}.`);
                break;
        }
        return rtn;
    }

    static getHoursAndMinutesFromMilliseconds(ms: number): string {
        let rtn = "";

        if (ms || ms === 0) {
            const hours: number = Math.floor(ms / (60 * 60 * 1000));
            const hoursms: number = ms % (60 * 60 * 1000);
            const minutes: number = Math.floor(hoursms / (60 * 1000));
            const hoursStr =
                hours < 10 ? "0" + hours.toString() : hours.toString();
            const minutesStr: string =
                minutes < 10 ? "0" + minutes.toString() : minutes.toString();

            rtn += hoursStr + ":" + rtn + minutesStr;
        }

        return rtn;
    }

    static getHoursAndMinutesFromMinutes(mins: number, leadingZero = true): string {
        let rtn = "";

        if (mins || mins === 0) {
            const hours: number = Math.floor(mins / 60);
            const hoursMins: number = mins % 60;
            const minutes: number = Math.floor(hoursMins);
            const hoursStr =
                leadingZero && hours < 10 ? "0" + hours.toString() : hours.toString();
            const minutesStr: string =
                minutes < 10 ? "0" + minutes.toString() : minutes.toString();

            rtn += hoursStr + ":" + rtn + minutesStr;
        }

        return rtn;
    }

    static formatDate(date: Date): string {
        const hours: number = date.getHours();
        const minutes: number = date.getMinutes();
        const suffix = hours >= 12 ? "PM" : "AM";
        const h = (((hours + 11) % 12) + 1).toString() + ":";
        const m: string =
            minutes < 10 ? "0" + minutes.toString() : minutes.toString();
        const result: string = h + m + " " + suffix;

        return result;
    }

    static selectInputText(element: HTMLInputElement): void {
        element.select();
    }

    static trimDateToSeconds(date: Date): Date {
        return new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            0
        );
    }

    static pad(myNumber: number, length: number): string {
        let str = "" + myNumber.toString();
        while (str.length < length) {
            str = "0" + str;
        }

        return str;
    }

    static verboseLog(message: string): void {

        if (!window.localStorage.getItem("verboseLogging")) {
            window.localStorage.setItem("verboseLogging", "false")
        }

        if (
            window.localStorage.getItem("verboseLogging") === "true"
        ) {
            console.log(message);
        }
    }

    static isValidMomentDate(value: Moment, dateFormat: string): boolean {
        const date = moment(value, dateFormat, true);
        return date.isValid();
    }

    static formatAddress(address: Address): string {
        let cityStateZip;
        let addressText;
        if (address?.city && address?.state && address?.zip) {
            cityStateZip = `${address.city}, ${address.state} ${address.zip}`;
        }
        if (address?.address1) {
            addressText = `${address.address1} ${address.address2 ?? ""}`.trim();
        }
        if (cityStateZip && addressText) return `${addressText}, ${cityStateZip}`;
        if (cityStateZip) return `${cityStateZip}`;
        if (addressText) return `${addressText}`;
    }

    static getDistanceinMiles(source: { lat: number, lng: number }, destination: { lat: number, lng: number }): number {
        const radius = 3959; // mean radius in mi of the earth

        const lat1 = source.lat * Math.PI / 180; // converted to radians
        const lat2 = destination.lat * Math.PI / 180;
        const deltaLat = (destination.lat - source.lat) * Math.PI / 180;
        const deltaLng = (destination.lng - source.lng) * Math.PI / 180;

        const arc = Math.pow(Math.sin(deltaLat / 2), 2) +
            Math.cos(lat1) * Math.cos(lat2) *
            Math.pow(Math.sin(deltaLng / 2), 2);
        const deltaGlobal = 2 * Math.atan2(Math.sqrt(arc), Math.sqrt(1 - arc));
        return deltaGlobal * radius;
    }

    static formatDisplayName(object: { createdUserZrt?: string, createdUserFullName?: string, modifiedUserFullName?: string, modifiedUserZrt?: string }, useCreated?: boolean): string | undefined {
        if (!object) {
            return;
        }

        let zrt = object.createdUserZrt;
        let name = object.createdUserFullName;
        let separator = " - ";

        if (!useCreated && object.modifiedUserFullName) {
            name = object.modifiedUserFullName;
        }

        if (!useCreated && object.modifiedUserZrt) {
            zrt = object.modifiedUserZrt;
        }

        if (!zrt) {
            zrt = zrt ?? "";
            separator = "";
        }

        if (!name) {
            name = name ?? "";
            separator = "";
        }

        const splitName = name.split(",");
        if (splitName.length > 1) {
            name = `${splitName[1]} ${splitName[0]}`;
        }

        return `${zrt}${separator}${name}`;
    }

    static valueSeparatedStringToFormattedString(value: string): string {
        return value.split(valueSeparator).join(", ");
    }

    static localMidnightDateToUtcMidnightString(local: Date): string {
        if (!local) return;

        local.setHours(0, 0, 0, 0);

        return new Date(local.getTime() - (local.getTimezoneOffset() * 60000)).toISOString();
    }

    static utcMidnightStringToLocalMidnightDate(utc: string): Date {
        if (!utc) return;

        const utcDate = new Date(utc);

        return new Date(utcDate.getTime() + (utcDate.getTimezoneOffset() * 60000));
    }

    static formatDisplayZrt(zrt: string): string {
        const searchable = zrt?.split("");

        if (searchable == undefined || !searchable.length) return "";

        if (searchable[3] && searchable[3] !== "0") return `Territory ${zrt}`;
        if (searchable[2] && searchable[2] !== "0") return `Region ${searchable[0]}${searchable[1]}${searchable[2]}`;
        if (searchable[1] && searchable[1] !== "0") return `Zone ${searchable[0]}${searchable[1]}`;
    }

    static getEmployeeDisplayName(employee: Employee, username?: string): string {
        if (!employee) return "";

        let prefix = "";

        if (employee.employeeRoles.some(v => v.employeeRoleType.id == EmployeeRoleType.ZCAM)) {
            prefix = "Zone Chain Accounts";
        }

        if (!prefix.length && employee.employeeRoles.some(v => v.employeeRoleType.id == EmployeeRoleType.NAM)) {
            prefix = "National Accounts";
        }

        if (!prefix.length && employee.employeeRoles.some(v => v.employeeRoleType.id == EmployeeRoleType.SPM)) {
            prefix = "Special Projects";
        }

        if (!prefix.length && employee.employeeRoles.some(v => v.employeeRoleType.id == EmployeeRoleType.ZM)) {
            prefix = "Zone " + employee.zone;
        }

        if (!prefix.length && employee.employeeRoles.some(v => v.employeeRoleType.id == EmployeeRoleType.RM)) {
            prefix = "Region " + employee.zone + employee.region;
        }

        if (!prefix.length && employee.employeeRoles.some(v => v.employeeRoleType.id == EmployeeRoleType.TM)) {
            prefix = "Territory " + employee.zrt;
        }

        return `${prefix.length ? prefix + " - " : ""}${username ?? employee.fullName}`;
    }

    static getFirstDayOfWeek(d: Date) {
        // 👇️ clone date object, so we don't mutate it
        const date = new Date(d);
        const day = date.getDay(); // 👉️ get day of week

        // 👇️ day of month - day of week (-6 if Sunday), otherwise +1
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);

        return new Date(date.setDate(diff));
    }

    static isEmployeeZrtAssigned(employee: Employee): boolean {
        return employee && employee.employeeRoles && employee.employeeRoles.every(v =>
            v.employeeRoleType.id === EmployeeRoleType.TM ||
            v.employeeRoleType.id === EmployeeRoleType.RM ||
            v.employeeRoleType.id === EmployeeRoleType.ZM
        )
    }

    static isEmployeeInRoles(employee: Employee, roles: EmployeeRoleType[]): boolean {
        return employee && employee.employeeRoles && employee.employeeRoles.some(v =>
            roles.indexOf(v.employeeRoleType.id) > 0
        );
    }

    static isEmployeeCorpOrShieldAdmin(employee: Employee): boolean {
        return employee && employee.employeeRoles && employee.employeeRoles.some(v =>
            v.employeeRoleType.id === EmployeeRoleType.CorpAdmin ||
            v.employeeRoleType.id === EmployeeRoleType.ShieldAdmin
        );
    }

    static isEmployeeCustomerServiceOrAdmin(employee: Employee): boolean {
        return employee && employee.employeeRoles && employee.employeeRoles.some(v =>
            v.employeeRoleType.id === EmployeeRoleType.CS ||
            v.employeeRoleType.id === EmployeeRoleType.CorpAdmin ||
            v.employeeRoleType.id === EmployeeRoleType.ShieldAdmin);
    }

    static isEmployeeShieldAdmin(employee?: Employee): boolean {
        return employee && employee.employeeRoles && employee.employeeRoles.some(v =>
            v.employeeRoleType.id === EmployeeRoleType.ShieldAdmin
        );
    }

    static isEmployeeAboveZmRole(employee: Employee): boolean {
        return employee && employee.employeeRoles && employee.employeeRoles.some(v =>
            v.employeeRoleType.id === EmployeeRoleType.ZM ||
            v.employeeRoleType.id === EmployeeRoleType.CS ||
            v.employeeRoleType.id === EmployeeRoleType.CorpAdmin ||
            v.employeeRoleType.id === EmployeeRoleType.ShieldAdmin);
    }

    static isEmployeeAboveTmRole(employee: Employee): boolean {
        return employee &&
            employee.employeeRoles &&
            employee.employeeRoles
                .map(v => v.employeeRoleType.id)
                .some(r =>
                    r === EmployeeRoleType.RM ||
                    r === EmployeeRoleType.ZM ||
                    r === EmployeeRoleType.CS ||
                    r === EmployeeRoleType.CorpAdmin ||
                    r === EmployeeRoleType.ShieldAdmin);
    }

    static isEmployeeAboveTmRoleAndAllowedToRemoveZrtFilter(employee: Employee): boolean {
        return employee && employee.employeeRoles && employee.employeeRoles.some(v =>
            v.employeeRoleType.id === EmployeeRoleType.RM ||
            v.employeeRoleType.id === EmployeeRoleType.ZM ||
            v.employeeRoleType.id === EmployeeRoleType.CS ||
            v.employeeRoleType.id === EmployeeRoleType.CorpAdmin ||
            v.employeeRoleType.id === EmployeeRoleType.ShieldAdmin ||
            v.employeeRoleType.id === EmployeeRoleType.NAM ||
            v.employeeRoleType.id === EmployeeRoleType.ZCAM ||
            v.employeeRoleType.id === EmployeeRoleType.SPM);
    }

    static isEmployeeAllowedToRemoveZrtFilter(employee: Employee): boolean {
        return employee && employee.employeeRoles && employee.employeeRoles.some(v =>
            v.employeeRoleType.id === EmployeeRoleType.CS ||
            v.employeeRoleType.id === EmployeeRoleType.CorpAdmin ||
            v.employeeRoleType.id === EmployeeRoleType.ShieldAdmin ||
            v.employeeRoleType.id === EmployeeRoleType.NAM ||
            v.employeeRoleType.id === EmployeeRoleType.ZCAM ||
            v.employeeRoleType.id === EmployeeRoleType.SPM);
    }


    static compareMomentMonthAndYear(a: Moment, b: Moment): boolean {
        return (a && b && a.month() === b.month() && a.year() === b.year());
    }

    static compareDayMonthYear(a: Date, b: Date): boolean {
        return (a && b &&
            a.getFullYear() == b.getFullYear() &&
            a.getMonth() == b.getMonth() &&
            a.getDate() == b.getDate());
    }

    /**
     * Transforms text to titlecase by converting it to lowercase & then changing the first letter of each word to uppercase.
     * @param text - Arbitrary text, possibly including whitespace or punctuation
     * @returns the transformed text
     */
    static titleCase(text: string): string {
        return text.toLowerCase().replace(/\w\S*/g, word => word.charAt(0).toUpperCase() + word.substring(1));
    }

    /**
     * Creates a promise that calls the provided callback after the specified timeout before resolving.
     * @param callback Callback accepts no parameters and returns void or a promise of void.
     * @param timeout Time in ms to wait before calling the callback.
     * @returns
     */
    static async timeOutAsPromise(callback: () => void | Promise<void>, timeout: number): Promise<void> {
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                try {
                    await Promise.resolve(callback())
                    resolve();
                } catch (e) {
                    reject(e);
                }
            }, timeout);
        });
    }
}
