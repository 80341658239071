<div #originalSwisherRetailReceipt *ngIf="hasSwisherProducts()">
    <div class="center">
        ******Draft******Draft******Draft******Draft******Draft******Draft******
        ******Draft******Draft******Draft******Draft******Draft******Draft******
    </div>
    <div class="row">
        <div class="col center">
            {{ customer().businessAddress.name }}
        </div>
    </div>
    <div class="row">
        <div class="col center">
            Retail License # {{ stateOptLicense()?.licenseNumber }}
        </div>
    </div>
    <div class="row">
        <div class="col center">
            {{ formattedAddress() }}
        </div>
    </div>
    <div *ngIf="customer().businessAddress?.county" class="row">
        <div class="col center">{{customer().businessAddress?.county}}&nbsp;County</div>
    </div>
    <hr />
    <div *ngIf="swisherCashProducts().length > 0">
        <div class="row margin-right">
            <div class="col-5">
                <b>Cash Sales Item</b>
            </div>
            <div class="col-1">
                <b>Qty</b>
            </div>
            <div class="col-2">
                <b>Price</b>
            </div>
            <div class="col-2">
                <b>Discount</b>
            </div>
            <div class="col-2">
                <b>Total</b>
            </div>
        </div>
        <div class="row  margin-right" *ngFor="let cashProduct of swisherCashProducts()">
            <div class="col-5">
                {{ cashProduct.product?.description }}
            </div>
            <div class="col-1">
                {{ cashProduct.quantity }}
            </div>
            <div class="col-2">
                {{ cashProduct.price | currency }}
            </div>
            <div class="col-2">
                {{ cashProduct.discount | currency }}
            </div>
            <div class="col-2">
                {{ cashProduct.total | currency }}
            </div>
        </div>
        <hr />
        <div class="row total-display">
            <div class="col-12">
                <b>Total: {{ swisherCashDueNow() | currency }}</b>
            </div>
        </div>
        <div class="row">
            <div class="col-12 total-display larger">
                <b><u>Cash Due Now: {{ swisherCashDueNow() | currency }}</u></b>
            </div>
        </div>
        <br />
    </div>
    <div *ngIf="swisherGratisProducts().length > 0">
        <div class="row margin-right">
            <div class="col-5">
                <b>Promotional Goods</b>
            </div>
            <div class="col-2">
                <b>Qty</b>
            </div>
            <div class="col-2">
                <b>Price</b>
            </div>
            <div class="col-3 ">
                <b>Total</b>
            </div>
        </div>
        <div class="row margin-right" *ngFor="let gratisProduct of swisherGratisProducts()">
            <div class="col-5">
                {{ gratisProduct.product?.description }}
            </div>
            <div class="col-2">
                {{ gratisProduct.quantity }}
            </div>
            <div class="col-2">
                {{ gratisProduct.value | currency }}
            </div>
            <div class="col-3">
                {{ gratisProduct.total | currency }}
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col total-display">
                <b>Promotional Goods Total:
                    {{ swisherGratisTotal() | currency }}</b>
            </div>
        </div>
        <br />
    </div>
    <div *ngIf="swisherExchangeOutProducts().length > 0">
        <div class="row margin-right">
            <div class="col-5">
                <b>Exchange Out Item</b>
            </div>
            <div class="col-2">
                <b>Qty</b>
            </div>
            <div class="col-2">
                <b>Price</b>
            </div>
            <div class="col-3">
                <b>Total</b>
            </div>
        </div>
        <div class="row  margin-right" *ngFor="let outProduct of swisherExchangeOutProducts()">
            <div class="col-5">
                {{ outProduct.product?.description }}
            </div>
            <div class="col-2">
                {{ outProduct.quantity }}
            </div>
            <div class="col-2">
                {{ outProduct.price | currency }}
            </div>
            <div class="col-3">
                {{ outProduct.total | currency }}
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col total-display">
                <b>Exchange Out Total:
                    {{ swisherExchangeOutTotal() | currency }}</b>
            </div>
        </div>
        <br />
    </div>
    <div *ngIf="swisherExchangeInProducts().length > 0">
        <div class="row margin-right">
            <div class="col-5">
                <b>Exchange In Item</b>
            </div>
            <div class="col-2">
                <b>Qty</b>
            </div>
            <div class="col-2">
                <b>Price</b>
            </div>
            <div class="col-3">
                <b>Total</b>
            </div>
        </div>
        <div class="row margin-right" *ngFor="let inProduct of swisherExchangeInProducts()">
            <div class="col-5">
                {{ inProduct.product?.description }}
            </div>
            <div class="col-2">
                {{ inProduct.quantity }}
            </div>
            <div class="col-2">
                {{ inProduct.price | currency }}
            </div>
            <div class="col-3">
                {{ inProduct.total | currency }}
            </div>
        </div>
        <hr />
        <div class="row ">
            <div class="col total-display">
                <b>Exchange In Total: {{ swisherExchangeInTotal() | currency }}</b>
            </div>
        </div>
        <br />
    </div>
    <div class="center">
        ******Draft******Draft******Draft******Draft******Draft******Draft******
        ******Draft******Draft******Draft******Draft******Draft******Draft******
    </div>
    <div *ngIf="hasEasProducts()" class="page-break">
    </div>
</div>
