import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { MapDirectionsService } from "@angular/google-maps";
import { MarkerClustererOptions } from "@googlemaps/markerclustererplus";
import  humanizeDuration from "humanize-duration";
import { Observable, Subject, filter, lastValueFrom, map, of, shareReplay, switchMap, tap } from "rxjs";
import { CustomerMapService } from "src/app/accounts/account-services/customer-map.service";
import { Address } from "src/app/entity-models/address.entity";
import { CustomerMarker } from "src/app/entity-models/customer-marker.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { RouteStop } from "src/app/entity-models/route-stop.entity";
import { Route } from "src/app/entity-models/route.entity";
import { RouteManagementService } from "src/app/my-day/route-management/route-management.service";
import { CustomerConverterService } from "src/app/services/converter-services/customer-converter.service";
import { DatabaseService } from "src/app/services/database.service";
import { GeocoderService } from "src/app/services/geocoder.service";
import { RoutingModeFilters } from "../../account-enums/routing-mode-filters";
import { GoogleMapLatLng } from "../googleMapsModels";

export class AccountRoutingModeViewmodel {
    customerMarkers: CustomerMarker[] = [];
    route: Route;
    routeCustomerIds: string[] = [];
    infoMarker: CustomerMarker;
    routeMarkers: CustomerMarker[] = [];
    nonRouteMarkers: CustomerMarker[] = [];
    nonRouteMarkersVisibleIds: string[] = [];
    routeTotalDriveTime: string;
    routeAverageTimePerCall: string;
    routeDate: Date = new Date();
    routePrevStartAddresses: Address[] = [];
    routePrevEndAddresses: Address[] = [];
    routeStartAtCurrent: boolean;
    routeStartAtStorage: boolean;
    routeEndAtCurrent: boolean;
    routeEndAtStorage: boolean;
    routeEndAtStart: boolean;
    isRoutePristine: boolean;
    canOptimizeRoute: boolean = false; //Only enable optimize route button when changes to route have been made
    isCurrentAddressAvailable = false;

    employee: Employee;
    finishButtonText = "Finish/Download";
    foundLatLngs = new Array<CustomerMarker>();
    foundRouteCustomers: CustomerMarker[] = [];
    futureRouteMap: Map<string, Route> = new Map();
    typeFilters: RoutingModeFilters[] = [];

    mapBoundsLower: GoogleMapLatLng;
    mapBoundsUpper: GoogleMapLatLng;

    readonly routeDateMin: Date = new Date();
    currentPosition: google.maps.LatLngLiteral | undefined;
    private currentAddress: Address; // use async getCurrentAddress() to read/geocode
    private readonly directions = new Subject<google.maps.DirectionsRequest>();
    readonly directionsResult: Observable<google.maps.DirectionsResult>;

    constructor(
        private dbService: DatabaseService,
        private geocoder: GeocoderService,
        private routeManagementService: RouteManagementService,
        private directionsService: MapDirectionsService,
    ) {
        this.initRoute();
        this.directionsResult = this.directions.pipe(
            switchMap(req => this.directionsService.route(req)),
            map(response => response?.result),
            filter(result => !!result),
            shareReplay(1),
        );
        //We need to have at least one subscriber to force the pipeline to run at least once.
        //Otherwise it won't show directions when the map loads.
        this.directionsResult.subscribe();
    }

    public mapMarkerClusterOptions: MarkerClustererOptions = {
        maxZoom: 12,
    };

    removeRouteStop(stop: RouteStop, directionsBuilt: boolean = true): void {
        const ix = this.routeCustomerIds.findIndex(
            (id) => id === stop.customerId
        );
        if (ix !== -1) {
            this.routeCustomerIds.splice(ix, 1);
        }
        const rix = this.route.stops.indexOf(stop);
        if (rix !== -1) {
            if (!directionsBuilt) {
                this.directions.next(null);
            }

            if (rix === 0) {
                this.routeStartAtCurrent = false;
                this.routeStartAtStorage = false;
            }

            if (rix === this.route.stops.length - 1) {
                this.routeEndAtCurrent = false;
                this.routeEndAtStorage = false;
                this.routeEndAtStart = false;
            }

            this.route.stops.splice(rix, 1);
        }
        this.buildRouteMarkers(true, directionsBuilt);
    }

    removeMarkerFromRoute(marker: CustomerMarker): void {
        if (!marker || !marker.customerId) return;

        this.setCustomerMarkerOnRoute(marker, false);
    }

    setCustomerMarkerOnRoute(marker: CustomerMarker, shouldAdd: boolean): void {
        if (!marker || this.isAddNewRouteStopDisabled(marker.customerId)) return;

        if (shouldAdd) {
            this.routeCustomerIds = [...this.routeCustomerIds, marker.customerId];
            const newStop: RouteStop = {
                id: this.dbService.newSequentialId(),
                customerId: marker.customerId,
                address: marker.address,
                label: marker.title,
                sequence: this.route.stops.length,
                px3RankId: marker.px3Rank
            };
            const lastStop = this.route.stops[this.route.stops.length - 1];
            if (!lastStop || lastStop?.customerId) {
                this.route.stops = [...this.route.stops, newStop];
            }
            else {
                this.removeRouteStop(lastStop);
                this.route.stops = [...this.route.stops, newStop, lastStop];
            }
        } else {
            const stop = this.route.stops.find(
                (rs) => rs.customerId === marker.customerId
            );
            if (stop) {
                this.removeRouteStop(stop);
            }
        }
        this.isRoutePristine = false;
        this.buildRouteMarkers();
    }

    buildRouteMarkers(rebuildRoute: boolean = true, directionsSet?: boolean) {
        this.route.stops.forEach((rs, ix) => {
            rs.sequence = ix;
        });

        if (rebuildRoute) {
            this.routeMarkers = this.route.stops.map((rs) =>
                this.getRouteStopMapMarker(rs)
            ).filter(v => !!v);
        }
        this.routeCustomerIds = this.route.stops.map(v => v.customerId);
        this.routeManagementService.routeCustomers = this.routeMarkers.filter(r => r.address);

        if (!directionsSet) {
            this.setRouteDirections();
        }
    }

    private setRouteDirections() {
        const filteredRouteMarkers = this.routeMarkers.filter((r) => r.address != null);
        if (filteredRouteMarkers.length <= 1) {
            return;
        }

        this.directions.next({
            travelMode: google.maps.TravelMode.DRIVING,
            origin: CustomerMapService.getLatLngFromMarker(filteredRouteMarkers[0]),
            destination: CustomerMapService.getLatLngFromMarker(filteredRouteMarkers[filteredRouteMarkers.length - 1]),
            optimizeWaypoints: true,
            waypoints: filteredRouteMarkers
                .slice(1, filteredRouteMarkers.length - 1)
                .map((latlng) => (
                    {
                        location: new google.maps.LatLng(latlng.address.latitude, latlng.address.longitude),
                        stopover: true
                    }))
        });
    }

    isCustomerOnRoute(customerId: string): boolean {
        return this.routeCustomerIds.includes(customerId);
    }

    updateNonRouteCustomerMarkers(): void {
        const customerIDSet = new Set(this.nonRouteMarkers.map(v => v.customerId));
        const newMarkers = this.customerMarkers.filter((m) =>
            CustomerMapService.isCustomerMarkerVisible(m, this.mapBoundsLower, this.mapBoundsUpper) &&
            !this.isCustomerOnRoute(m.customerId) &&
            !customerIDSet.has(m.customerId)
            && m.address?.address1
        );
        for (const marker of newMarkers) {
            this.nonRouteMarkers.push(marker);
        }
        this.filterCustomerMarkers();
    }

    filterCustomerMarkers(): void {
        if (!this.typeFilters?.length) {
            this.typeFilters = [];
            this.nonRouteMarkersVisibleIds = this.customerMarkers
                .filter((m) =>
                    CustomerMapService.isCustomerMarkerVisible(m, this.mapBoundsLower, this.mapBoundsUpper) &&
                    !this.isCustomerOnRoute(m.customerId))
                .map(v => v.customerId);
            return;
        }
        const isRanked = this.typeFilters.includes(RoutingModeFilters.px3Ranked);
        const isRouted = this.typeFilters.includes(RoutingModeFilters.routed);

        let markers = this.customerMarkers.filter(
            (marker) => !!marker && !this.isCustomerOnRoute(marker.customerId)
        );


        if (isRanked) { // Do not search by type if Px3 Rank is the only filter.
            markers = markers.filter(
                (m) => !!m.px3Rank
            );
        }
        if (isRouted) { // Same if Routed is the only filter.
            markers = markers.filter(
                (m) => m.routed === isRouted
            );
        }
        if (this.typeFilters.filter(v => v !== RoutingModeFilters.px3Ranked && v != RoutingModeFilters.routed).length) {
            markers = markers.filter(
                (m) => this.typeFilters.includes(m.customerType.name as RoutingModeFilters)
            );
        }
        this.nonRouteMarkersVisibleIds = markers.map(v => v.customerId);
    }

    private getRouteStopMapMarker(stop: RouteStop): CustomerMarker {
        const markerLabel = (stop.sequence + 1).toString();
        if (stop.customerId && stop.address) {
            const foundMarker = this.foundRouteCustomers.find((cm) => cm.customerId == stop.customerId)
                ?? this.customerMarkers.find((cm) => cm.customerId == stop.customerId);
            if (foundMarker) {
                foundMarker.label = markerLabel;
                return foundMarker;
            }
        }
        // non-customer stops
        return {
            ...CustomerMapService.getMarkerAddressParts(stop.address, this.foundLatLngs),
            customerId: stop.customerId,
            title: stop.label,
            label: markerLabel,
            address: stop.address
        }
    }

    dropRouteItem(event: CdkDragDrop<string[]>): void {
        // makes sure old/new positions are both customers...
        if (
            this.route.stops[event.previousIndex].customerId &&
            this.route.stops[event.currentIndex].customerId
        ) {
            this.canOptimizeRoute = true;
            moveItemInArray(
                this.route.stops,
                event.previousIndex,
                event.currentIndex
            );
            this.buildRouteMarkers();
        }
    }

    setRouteTimeAndDistance(legs: google.maps.DirectionsLeg[]): void {
        this.routeTotalDriveTime = "Calculating...";
        this.routeAverageTimePerCall = "Calculating...";

        if (legs) {
            let totalDriveSecs = 0;

            for (const leg of legs) {
                totalDriveSecs += leg.duration.value;
            }

            const totalSecsPerWorkDay = 8 * 60 * 60;
            const timePerCall =
                (totalSecsPerWorkDay - totalDriveSecs) / this.routeCustomerIds.length;

            this.routeTotalDriveTime = this.humanizeDurationSecs(totalDriveSecs);
            this.routeAverageTimePerCall = this.humanizeDurationSecs(timePerCall);
        } else {
            this.routeTotalDriveTime = null;
            this.routeAverageTimePerCall = null;
        }
    }

    private humanizeDurationSecs(secs: number): string {
        if (secs < 0) return "< 1 minute";

        const humanizerOptions: humanizeDuration.HumanizerOptions = {
            largest: 2,
            units: ["h", "m"],
            round: true
        };
        return humanizeDuration(secs * 1000, humanizerOptions);
    }

    async getRouteCustomersOriginDestinations(): Promise<{
        origin: google.maps.LatLng[];
        destinations: google.maps.LatLng[];
    }> {
        const addresses = this.route.stops.map((s) => s.address).slice();
        for (const a of addresses) {
            if (!a.latitude || !a.longitude) {
                const latLng = await this.getLatLngFromAddress(a);
                a.latitude = latLng.lat;
                a.longitude = latLng.lng;
            }
        }
        const latLngs = addresses.map(
            (a) => new google.maps.LatLng(a.latitude, a.longitude)
        );
        const origin = latLngs.slice(0, 1); // only first stop
        const destinations = latLngs.slice(1); // first stop will never be a destination
        return { origin, destinations };
    }

    getDistanceMatrix(
        origins: google.maps.LatLng[] | string[],
        destinations: google.maps.LatLng[] | string[]
    ): Promise<google.maps.DistanceMatrixResponse> {
        const service = new google.maps.DistanceMatrixService();

        const request: google.maps.DistanceMatrixRequest = {
            origins,
            destinations,
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.IMPERIAL
        };

        return new Promise<google.maps.DistanceMatrixResponse>(
            (resolve, reject) =>
                service.getDistanceMatrix(request, (response, status) => {
                    if (status === google.maps.DistanceMatrixStatus.OK) {
                        resolve(response);
                    } else {
                        reject(status);
                    }
                })
        );
    }

    async optimizeRoute(): Promise<void> {
        const {
            origin,
            destinations
        } = await this.getRouteCustomersOriginDestinations();

        const response = await this.getDistanceMatrix(origin, destinations);

        // todo -- perform actual optimization here --

        if (!response.rows.find((row) => row.elements
            .map((req) => req.status)
            .find((status) => status !==
                google.maps.DistanceMatrixElementStatus.OK
            ))) {
            const totalDriveSecs = response.rows.reduce(
                (prev, cur, ix) => prev + cur.elements[ix].duration.value,
                0
            );

            const totalSecsPerWorkDay = 8 * 60 * 60;
            const timePerCall =
                (totalSecsPerWorkDay - totalDriveSecs) / this.routeCustomerIds.length;

            this.routeTotalDriveTime = this.humanizeDurationSecs(totalDriveSecs);
            this.routeAverageTimePerCall = this.humanizeDurationSecs(timePerCall);
        }
        else {
            this.routeTotalDriveTime = null;
            this.routeAverageTimePerCall = null;
        }
    }

    async getCurrentAddress(): Promise<Address | undefined> {
        if (this.currentAddress) return this.currentAddress;

        if (!this.currentPosition) {
            return;
        }
        const request: google.maps.GeocoderRequest = {
            location: this.currentPosition
        };

        const results = await lastValueFrom(this.geocoder.geocode(request));

        const parts = new Map<string, string>();
        for (const p of results[0].address_components) {
            parts.set(p.types[0], p.short_name);
        }

        const streetNumber = parts.get("street_number") ?? "";
        const streetName = parts.get("route") ?? "";
        const address1 = `${streetNumber} ${streetName}`;
        const city = parts.get("locality") ?? null;
        const state = parts.get("administrative_area_level_1") ?? null;
        const county = parts.get("administrative_area_level_2") ?? null;
        const country = parts.get("country");
        const zip = parts.get("postal_code");
        const countyFipsCode = "";
        const dateCreated: Date | null = null;
        const lastUpdated: Date | null = null;
        const lastDelettionDate: Date | null = null;
        const typeAddress = "";
        const isDeactivated = false;
        const isDeleted = false;

        this.currentAddress = {
            id: this.dbService.newSequentialId(),
            name: "Current location",
            latitude: this.currentPosition.lat,
            longitude: this.currentPosition.lng,
            address1,
            address2: null,
            city,
            state,
            zip,
            county,
            countyFipsCode,
            country,
            dateCreated,
            lastUpdated
        };

        return this.currentAddress;
    }

    getStorageAddress(): Address {
        const rtn = new Address();

        rtn.name = this.employee.shipAddress1;
        rtn.address1 = this.employee.shipAddress2;
        rtn.address2 = this.employee.shipAddress3;
        rtn.city = this.employee.shipCity;
        rtn.state = this.employee.shipState;
        rtn.zip = this.employee.shipZipCode;

        return rtn;
    }

    async setRouteStartAtCurrent(value: boolean): Promise<void> {
        if (this.routeStartAtCurrent === value) return;
        this.routeStartAtCurrent = value;

        if (value) {
            // add new stop
            const address = await this.getCurrentAddress();

            const newStop: RouteStop = {
                id: this.dbService.newSequentialId(),
                customerId: null, // this is a non-customer route stop
                address,
                label: address.name,
                sequence: 0,
            };
            this.route.stops = [newStop, ...this.route.stops];
            this.buildRouteMarkers();
        }
    }

    async setRouteStartAtStorage(value: boolean): Promise<void> {
        if (this.routeStartAtStorage === value) return;
        this.routeStartAtStorage = value;
        if (value) {
            // add new stop
            const address = new Address();
            address.name = this.employee.shipAddress1;
            address.address1 = this.employee.shipAddress2;
            address.address2 = this.employee.shipAddress3;
            address.city = this.employee.shipCity;
            address.state = this.employee.shipState;
            address.zip = this.employee.shipZipCode;

            if (!this.foundLatLngs.find((ll) => ll.addressText === address.address1)) {
                await this.getLatLngFromAddress(address);
            }

            const newStop: RouteStop = {
                id: this.dbService.newSequentialId(),
                customerId: null, // this is a non-customer route stop
                address,
                label: address.name + " (Storage Location)",
                sequence: 0,
            };
            this.route.stops = [newStop, ...this.route.stops];
            this.buildRouteMarkers();
        }
    }

    async setRouteStartAtCustom(value: Address): Promise<void> {
        if (value?.id && this.route.stops[0].address.id === value?.id) return;
        const current = await this.getCurrentAddress();
        if (!(
            value?.name === this.employee.shipAddress1 &&
            value?.address1 === this.employee.shipAddress2 &&
            value?.address2 === this.employee.shipAddress3 &&
            value?.city === this.employee.shipCity &&
            value?.state === this.employee.shipState &&
            value?.zip === this.employee.shipZipCode
        ) && !(
            value?.name === current?.name &&
            value?.address1 === current?.address1 &&
            value?.address2 === current?.address2 &&
            value?.city === current?.city &&
            value?.state === current?.state &&
            value?.zip === current?.zip
        )) {
            if (value?.address1 && (!value?.latitude || !value?.longitude)) {
                if (!this.foundLatLngs.find((ll) => ll.addressText === value.address1)) {
                    await this.getLatLngFromAddress(value);
                }
            }
            if (value && value?.address1 && value?.city && value?.state && value?.zip) {
                const newStop: RouteStop = {
                    id: this.dbService.newSequentialId(),
                    customerId: null, // this is a non-customer route stop
                    address: value,
                    label: value.name ? value.name : "Custom location",
                    sequence: 0,
                };
                this.route.stops = [newStop, ...this.route.stops];
                this.buildRouteMarkers();
            }
        }
    }

    async setRouteEndAtCurrent(value: boolean): Promise<void> {
        if (this.routeEndAtCurrent === value) return;
        this.routeEndAtCurrent = value;
        if (value) {
            // add new stop
            const address = await this.getCurrentAddress();

            const newStop: RouteStop = {
                id: this.dbService.newSequentialId(),
                customerId: null, // this is a non-customer route stop
                address,
                label: address.name,
                sequence: this.route.stops.length,
            };
            this.route.stops = [...this.route.stops, newStop];
            this.buildRouteMarkers();
        }
    }

    async setRouteEndAtStorage(value: boolean): Promise<void> {
        if (this.routeEndAtStorage === value) return;
        this.routeEndAtStorage = value;
        if (value) {
            // add new stop
            const address = new Address();
            address.name = this.employee.shipAddress1;
            address.address1 = this.employee.shipAddress2;
            address.address2 = this.employee.shipAddress3;
            address.city = this.employee.shipCity;
            address.state = this.employee.shipState;
            address.zip = this.employee.shipZipCode;

            if (!this.foundLatLngs.find((ll) => ll.addressText === address.address1)) {
                await this.getLatLngFromAddress(address);
            }

            const newStop: RouteStop = {
                id: this.dbService.newSequentialId(),
                customerId: null, // this is a non-customer route stop
                address,
                label: address.name + " (Storage Location)",
                sequence: this.route.stops.length,
            };
            this.route.stops = [...this.route.stops, newStop];
            this.buildRouteMarkers();
        }
    }

    async setRouteEndAtStart(value: boolean): Promise<void> {
        if (this.routeEndAtStart === value) return;
        // const lastStop = this.route.stops[this.route.stops.length - 1];
        // if (lastStop) {
        //     this.removeRouteStop(lastStop);
        // }
        this.routeEndAtStart = value;
        if (value) {
            // add new stop
            const address = this.route.stops[0].address;
            const rankId = this.route.stops[0].px3RankId;
            if (!this.foundLatLngs.find((ll) => ll.addressText === address.address1)) {
                await this.getLatLngFromAddress(address);
            }

            const newStop: RouteStop = {
                id: this.dbService.newSequentialId(),
                customerId: null, // this is a non-customer route stop
                address,
                label: `${this.route.stops[0].label} (Starting Location)`,
                sequence: this.route.stops.length,
                px3RankId: rankId
            };
            this.route.stops = [...this.route.stops, newStop];
            this.buildRouteMarkers();
        }
    }

    async setRouteEndAtCustom(value: Address): Promise<void> {
        if (value.id && this.route.stops[this.route.stops.length - 1].address.id === value.id) return;
        const current = await this.getCurrentAddress();
        if (!(
            value?.name === this.employee.shipAddress1 &&
            value?.address1 === this.employee.shipAddress2 &&
            value?.address2 === this.employee.shipAddress3 &&
            value?.city === this.employee.shipCity &&
            value?.state === this.employee.shipState &&
            value?.zip === this.employee.shipZipCode
        ) && !(
            value?.name === current?.name &&
            value?.address1 === current?.address1 &&
            value?.address2 === current?.address2 &&
            value?.city === current?.city &&
            value?.state === current?.state &&
            value?.zip === current?.zip
        )) {
            if (value?.address1 && (!value?.latitude || !value?.longitude)) {
                if (!this.foundLatLngs.find((ll) => ll.addressText === value.address1)) {
                    await this.getLatLngFromAddress(value);
                }
            }

            if (value && value?.address1 && value?.city && value?.state && value?.zip) {
                const newStop: RouteStop = {
                    id: this.dbService.newSequentialId(),
                    customerId: null, // this is a non-customer route stop
                    address: value,
                    label: value.name ? value.name : "Custom location",
                    sequence: this.route.stops.length,

                };
                this.route.stops = [...this.route.stops, newStop];
                this.buildRouteMarkers();
            }
        }
    }

    setOptimizedWaypoints(waypointOrder?: number[]): void {
        const toSort = this.route.stops.slice(1, this.route.stops.length - 1);
        if (waypointOrder) {
            for (const waypoint of waypointOrder) {
                const index = waypointOrder.indexOf(waypoint) + 1;
                this.route.stops[index] = toSort[waypoint];
            }
            this.buildRouteMarkers(true, true);
        }
        this.canOptimizeRoute = false;
    }

    async unsetCustomStartEndLocations(): Promise<void> {
        const firstStop = this.route.stops[0];
        if (!firstStop?.customerId) {
            this.removeRouteStop(firstStop, false);
        }

        const lastStop = this.route.stops[this.route.stops.length - 1];
        if (!lastStop?.customerId) {
            this.removeRouteStop(lastStop, false);
        }
    }

    isAddNewRouteStopDisabled(customerId: string): boolean {
        return (
            this.route.stops.length > 19 &&
            !this.route.stops.map((r) => r.customerId).includes(customerId)
        );
    }

    async setDirectionsFlags(): Promise<void> {
        const currentAddress = await this.getCurrentAddress();
        this.isCurrentAddressAvailable = !!currentAddress;
        const storageAddress = this.getStorageAddress();

        const firstStop = this.route.stops[0];
        const lastStop = this.route.stops[this.route.stops.length - 1];
        if (CustomerConverterService.compareAddresses(firstStop?.address, storageAddress)) {
            this.routeStartAtStorage = true;
        } else if (
            this.isCurrentAddressAvailable &&
                CustomerConverterService.compareAddresses(firstStop?.address, currentAddress)
        ) {
            this.routeStartAtCurrent = true;
        }

        if (CustomerConverterService.compareAddresses(lastStop?.address, storageAddress)) {
            this.routeEndAtStorage = true;
        } else if (
            this.isCurrentAddressAvailable &&
                CustomerConverterService.compareAddresses(lastStop?.address, currentAddress)) {
            this.routeEndAtCurrent = true;
        }

        if (CustomerConverterService.compareAddresses(firstStop?.address, lastStop?.address)) {
            this.routeEndAtStart = true;
        }
    }

    async validateLatLngsForCurrentRoute(): Promise<void> {
        for (const stop of this.route.stops) {
            if (stop.address && (!stop.address.latitude || !stop.address.longitude)) {
                await this.getLatLngFromAddress(stop.address);
            }
        }
    }

    private async getLatLngFromAddress(address: Address): Promise<GoogleMapLatLng> {
        let lat = 0;
        let lng = 0;
        if (!this.foundLatLngs.find((ll) => ll.addressText === address.address1)) {
            const geocodedAddress = address.address1
                + (address.address2 ? " " + address.address2 : "")
                + " " + address.city
                + " " + address.state;
            const request: google.maps.GeocoderRequest = {
                address: geocodedAddress
            };
            const results = await lastValueFrom(this.geocoder.geocode(request));
            if (results) {
                address.latitude = results[0].geometry.location.lat();
                address.longitude = results[0].geometry.location.lng();

                this.foundLatLngs.push({
                    title: address.id,
                    label: "",
                    address: address,
                    addressText: address.address1,
                    cityStateZip: address.city
                })
            }
        }
        return { lat, lng };
    }

    resetMarkers(): void {
        this.customerMarkers = [];
        this.nonRouteMarkers = [];
        this.nonRouteMarkersVisibleIds = [];
    }

    initRoute(): void {
        this.route = new Route();
        this.route.date = new Date();
        this.route.stops = [];
        this.routeCustomerIds = [];
        this.routeMarkers = [];
        this.infoMarker = null;
        this.isRoutePristine = true;
        this.routeStartAtCurrent = false;
        this.routeStartAtStorage = false;
        this.routeEndAtCurrent = false;
        this.routeEndAtStorage = false;
        this.routeEndAtStart = false;
        if (this.employee) {
            this.route.employeeId = this.employee.id;
            this.route.employeeZrt = this.employee.zrt ?? "";
        }
        this.resetMarkers();
    }
}
