<mat-card appearance="outlined" class="mt-3">
    <validation-error [messages]="validationErrorMessages()"></validation-error>
    <div class="row">
        <div class="col-lg-9 col-sm-12">
            <div class="pb-3">
                <mat-card appearance="outlined" class="inner-card m-0">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="m-teal"><b>Cash Sales</b></h2>
                        </div>
                        <div class="col-12 button-bottom-margin">
                            <button appThrottleClick
                                [disabled]="isFinalRetailReceiptPrinted"
                                class="btn sw-red-btn btn-sm"
                                type="button"
                                tabindex="0"
                                (throttledClick)="addCashProduct()">
                                ADD PRODUCTS
                            </button>
                        </div>
                    </div>
                    <div>
                        <div class="overflow-auto">
                            <table *ngIf="
                            stepperCallApplicationService.callCashProductViewModels &&
                            stepperCallApplicationService.callCashProductViewModels.length > 0
                                " class="table table-bordered table-striped mobile-w-max">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Description</th>
                                        <th scope="col"></th>
                                        <th scope="col">Unit QTY</th>
                                        <th scope="col">Unit of Measure</th>
                                        <th scope="col">Total Sticks</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Discount</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="
                                            let cashProduct of stepperCallApplicationService.callCashProductViewModels;
                                            let i = index
                                        ">
                                        <td class="icon-width">
                                            <button [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                class="btn btn-outline-danger text-decoration-none btn-sm"
                                                (click)="removeCashProduct(
                                                        cashProduct
                                                    )"
                                                title="Remove Product">
                                                <span class="flaticon-delete"></span>
                                            </button>
                                        </td>
                                        <td>
                                            {{
                                            cashProduct?.product
                                            ?.description
                                            }}
                                        </td>
                                        <td class="icon-width">
                                            <button [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                class="btn btn-outline-info btn-sm"
                                                (click)="copyCashProduct(
                                                        cashProduct
                                                    )"
                                                title="Copy Product Metadata">
                                                <span class="material-icons">content_copy</span>
                                            </button>
                                        </td>
                                        <td class="counter-fixed-width">
                                            <div class="input-group counter-control">
                                                <div class="input-group-prepend">
                                                    <button [disabled]="isFinalRetailReceiptPrinted" tabindex="-1"
                                                    class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            incrementCashProductQuantity(
                                                                cashProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">add</span>
                                                    </button>
                                                </div>
                                                <input
                                                    [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                    type="text" #cashProductQty (mouseup)="select(cashProductQty)"
                                                    (blur)="
                                                        validationCashProductQuantity(
                                                            cashProduct
                                                        )
                                                    " class="form-control form-control-sm mw-7rem-form" mask="000000"
                                                    aria-label="Example text with button addon" [(ngModel)]="
                                                        cashProduct.quantity
                                                    " />
                                                <div class="input-group-append">
                                                    <button [disabled]="isFinalRetailReceiptPrinted" tabindex="-1"
                                                        class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            decrementCashProductQuantity(
                                                                cashProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">remove</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="uom-column">
                                            <div class="input-group">
                                                <mat-form-field appearance="outline" color="accent">
                                                    <mat-label>Unit of Measure</mat-label>
                                                    <mat-select
                                                        [disabled]="isFinalRetailReceiptPrinted"
                                                        [value]="cashProduct.upc"
                                                        (valueChange)="setCashProductUPC(cashProduct, $event)"
                                                    >
                                                        <mat-option *ngFor="let upc of cashProduct.unitsOfMeasure" [value]="upc.upc">
                                                            {{upc.uom}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </td>
                                        <td class="total-sticks-fixed-width dotted-right-border">
                                            {{
                                            cashProduct.quantity *
                                            cashProduct.units
                                            }}
                                        </td>
                                        <td class="price-column">
                                            <mat-form-field class="price-input" appearance="outline"
                                                color="accent">
                                                <input
                                                    [disabled]="isFinalRetailReceiptPrinted"
                                                    tabindex="0"
                                                    matInput
                                                    #cashProductPrice
                                                    (mouseup)="select(cashProductPrice)"
                                                    (blur)="validationCashProductPrice(cashProduct)"
                                                    currencyMask
                                                    [(ngModel)]="cashProduct.price"
                                                    (change)="onProductInputChange()"
                                                />
                                            </mat-form-field>
                                        </td>
                                        <td class="price-column">
                                            <mat-form-field class="price-input" appearance="outline"
                                                color="accent">
                                                <input
                                                    [disabled]="isFinalRetailReceiptPrinted"
                                                    tabindex="0"
                                                    matInput
                                                    #cashProductDiscount
                                                    (mouseup)="select(cashProductDiscount)"
                                                    (blur)="saveCashProduct(cashProduct)"
                                                    currencyMask
                                                    [(ngModel)]="cashProduct.discount"
                                                    (change)="onProductInputChange()"
                                                />
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            {{
                                            cashProduct.quantity *
                                            (cashProduct.price -
                                            cashProduct.discount)
                                            | currency
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </mat-card>
            </div>

            <!-- Orders -->

            <div class="" *ngIf="stepperCallApplicationService.hasOrderableProduct && !isGenericWholesalerStoreType">
                <mat-card appearance="outlined" class="inner-card m-0">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="m-teal"><b>Orders/ Prebook</b></h2>
                        </div>
                        <div class="col-12 button-bottom-margin">
                            <button appThrottleClick
                                [disabled]="isFinalWholesaleReceiptPrinted" tabindex="0"
                                class="btn sw-red-btn btn-sm" type="button" (throttledClick)="addOrderProduct()">
                                ADD PRODUCTS
                            </button>
                        </div>
                    </div>
                    <div>
                        <div class="overflow-auto">
                            <table *ngIf="
                            stepperCallApplicationService.callOrderProductViewModels &&
                            stepperCallApplicationService.callOrderProductViewModels.length > 0
                                " class="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Description</th>
                                        <th scope="col"></th>
                                        <!-- <th scope="col"></th> -->
                                        <th scope="col">Unit QTY</th>
                                        <th scope="col">Unit Of Measure</th>
                                        <th scope="col">Total Sticks</th>
                                        <th scope="col">Wholesaler</th>
                                        <th scope="col">UIN</th>
                                        <th scope="col">Order Dates</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="
                                            let orderProduct of stepperCallApplicationService.callOrderProductViewModels;
                                            let i = index
                                        ">
                                        <td class="icon-width">
                                            <button [disabled]="isFinalWholesaleReceiptPrinted" tabindex="0"
                                                class="btn btn-outline-danger text-decoration-none btn-sm" (click)="
                                                    removeOrderProduct(
                                                        orderProduct
                                                    )
                                                " title="Remove Product">
                                                <span class="flaticon-delete"></span>
                                            </button>
                                        </td>
                                        <td>
                                            {{
                                            orderProduct?.product
                                            ?.description
                                            }}
                                        </td>
                                        <td class="icon-width">
                                            <button [disabled]="isFinalWholesaleReceiptPrinted" tabindex="0"
                                                class="btn btn-outline-info btn-sm" (click)="
                                                    duplicateOrderProduct(
                                                        orderProduct
                                                    )
                                                " title="Add Copy of Product">
                                                <span class="flaticon-add"></span>
                                            </button>
                                        </td>
                                        <td class="counter-fixed-width">
                                            <div class="input-group counter-control">
                                                <div class="input-group-prepend">
                                                    <button [disabled]="isFinalWholesaleReceiptPrinted" tabindex="-1"
                                                        class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            incrementOrderProductQuantity(
                                                                orderProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">add</span>
                                                    </button>
                                                </div>
                                                <input
                                                    [disabled]="isFinalWholesaleReceiptPrinted" tabindex="0"
                                                    type="text" #orderProductQty (mouseup)="select(orderProductQty)"
                                                    (blur)="
                                                        validationOrderProductQuantity(
                                                            orderProduct
                                                        )
                                                    " class="form-control form-control-sm mw-7rem-form" mask="000000"
                                                    aria-label="Example text with button addon" [(ngModel)]="
                                                        orderProduct.quantity
                                                    " />
                                                <div class="input-group-append">
                                                    <button [disabled]="isFinalWholesaleReceiptPrinted" tabindex="-1"
                                                        class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            decrementOrderProductQuantity(
                                                                orderProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">remove</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="uom-column">
                                            <div class="input-group">
                                                <mat-form-field appearance="outline" color="accent">
                                                    <mat-label>Unit of Measure</mat-label>
                                                    <mat-select
                                                        [disabled]="isFinalRetailReceiptPrinted"
                                                        [value]="orderProduct.upc"
                                                        (valueChange)="setOrderProductUPC(orderProduct, $event)"
                                                    >
                                                        <mat-option
                                                            *ngFor="let upc of orderProduct.unitsOfMeasure"
                                                            [value]="upc.upc"
                                                        >
                                                            {{upc.uom}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </td>
                                        <td class="total-sticks-fixed-width dotted-right-border">
                                            {{
                                            orderProduct.quantity *
                                            orderProduct.units

                                            }}
                                        </td>
                                        <td>
                                            <ng-container *ngIf="!isFinalWholesaleReceiptPrinted">
                                                <angular2-multiselect class="wholesaler-field"
                                                    [data]="orderProduct.wholesalers"
                                                    [(ngModel)]="orderProduct.wholesaler"
                                                    [settings]="wholeSalerDropdownSettings"
                                                    (onDeSelect)="wholesalerDeselect(orderProduct, $event)"
                                                    (onSelect)="onWholsalerSelectionChange(orderProduct)"
                                                    (onDeSelectAll)="wholesalerDeselect(orderProduct, $event)">
                                                </angular2-multiselect>
                                            </ng-container>
                                            <ng-container *ngIf="isFinalWholesaleReceiptPrinted">
                                                <mat-form-field class="wholesaler-field" appearance="outline" color="accent">
                                                    <input
                                                        [disabled]="isFinalWholesaleReceiptPrinted"
                                                        tabindex="0"
                                                        matInput
                                                        [(ngModel)]="orderProduct.wholesaler[0].displayValue"
                                                    />
                                                </mat-form-field>
                                            </ng-container>
                                        </td>
                                        <td>
                                            {{orderProduct.uin}}
                                        </td>
                                        <td>
                                            <button appThrottleClick *ngIf="orderProduct.selectedProject"
                                                [disabled]="isFinalWholesaleReceiptPrinted" tabindex="0"
                                                class="btn btn-outline-info btn-sm" type="button" (throttledClick)="
                                                    setOrderOptions(
                                                        orderProduct
                                                    )
                                                ">
                                                Order Options
                                            </button>
                                            <span *ngIf="!orderProduct.selectedProject && orderProduct.dateAvailable">
                                                {{ orderProduct.dateAvailable | date : 'shortDate'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </mat-card>
            </div>

            <!-- Gratis -->

            <div class="">
                <mat-card appearance="outlined" class="inner-card">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="m-teal"><b>Gratis</b></h2>
                        </div>
                        <div class="col-12 button-bottom-margin">
                            <button appThrottleClick
                                class="btn sw-red-btn btn-sm"
                                type="button"
                                tabindex="0"
                                [disabled]="isFinalRetailReceiptPrinted"
                                (throttledClick)="addGratisProduct()">
                                ADD PRODUCTS
                            </button>
                        </div>
                    </div>
                    <div>
                        <div class="overflow-auto">
                            <table *ngIf="
                            stepperCallApplicationService.callGratisProductViewModels &&
                            stepperCallApplicationService.callGratisProductViewModels.length > 0
                                " class="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Description</th>
                                        <th scope="col"></th>
                                        <th scope="col">Unit QTY</th>
                                        <th scope="col">Unit of Measure</th>
                                        <th scope="col">Total Sticks</th>
                                        <th scope="col">Value</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="
                                            let gratisProduct of stepperCallApplicationService.callGratisProductViewModels;
                                            let i = index
                                        ">
                                        <td class="icon-width">
                                            <button [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                class="btn btn-outline-danger btn-sm" (click)="
                                                    removeGratisProduct(
                                                        gratisProduct
                                                    )
                                                " title="Remove Product">
                                                <span class="flaticon-delete"></span>
                                            </button>
                                        </td>
                                        <td>
                                            {{
                                            gratisProduct?.product
                                            ?.description
                                            }}
                                        </td>
                                        <td class="icon-width">
                                            <button [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                class="btn btn-outline-info btn-sm" (click)="
                                                    copyGratisProduct(
                                                        gratisProduct
                                                    )
                                                " title="Copy Product Metadata">
                                                <span class="material-icons">content_copy</span>
                                            </button>
                                        </td>
                                        <td class="counter-fixed-width">
                                            <div class="input-group counter-control">
                                                <div class="input-group-prepend">
                                                    <button [disabled]="isFinalRetailReceiptPrinted" tabindex="-1"
                                                        class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            incrementGratisProductQuantity(
                                                                gratisProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">add</span>
                                                    </button>
                                                </div>
                                                <input
                                                    [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                    type="text" #gratisProductQty
                                                    (mouseup)="select(gratisProductQty)" (blur)="
                                                        validationGratisProductQuantity(
                                                            gratisProduct
                                                        )
                                                    " class="form-control form-control-sm mw-7rem-form" mask="000000"
                                                    aria-label="Example text with button addon" [(ngModel)]="
                                                        gratisProduct.quantity
                                                    " />
                                                <div class="input-group-append">
                                                    <button [disabled]="isFinalRetailReceiptPrinted" tabindex="-1"
                                                        class="btn btn-outline-secondary btn-sm" type="button"
                                                        (click)="
                                                            decrementGratisProductQuantity(
                                                                gratisProduct
                                                            )
                                                        ">
                                                        <span class="material-icons">remove</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="uom-column">
                                            <div class="input-group">
                                                <mat-form-field appearance="outline" color="accent">
                                                    <mat-label>Unit of Measure</mat-label>
                                                    <mat-select
                                                        [disabled]="isFinalRetailReceiptPrinted"
                                                        [value]="gratisProduct.upc"
                                                        (valueChange)="setGratisProductUPC(gratisProduct, $event)"
                                                    >
                                                        <mat-option *ngFor="let upc of gratisProduct.unitsOfMeasure" [value]="upc.upc">
                                                            {{upc.uom}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </td>
                                        <td class="total-sticks-fixed-width dotted-right-border">
                                            {{
                                            gratisProduct.quantity *
                                            gratisProduct.units

                                            }}
                                        </td>
                                        <td>
                                            <mat-form-field appearance="outline" color="accent" class="price-input">
                                                <input
                                                    [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                                    matInput #gratisProductValue
                                                    (mouseup)="select(gratisProductValue)" (blur)="
                                                    validationGratisProductPrice(
                                                            gratisProduct
                                                        )
                                                    " currencyMask [(ngModel)]="
                                                        gratisProduct.value
                                                    " />
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            {{
                                            gratisProduct.quantity *
                                            gratisProduct.value
                                            | currency
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
        <div class="col-lg-3 col-sm-12">
            <mat-card appearance="outlined" class="inner-card m-0">
                <div class="totals container">
                    <div class="row">
                        <div class="col-12 col-xl-6">
                            <b>Total Before Discount</b>
                        </div>
                        <div class="text-right col-6 col-xl-4">
                            {{ stepperCallApplicationService.totalCashBeforeDiscount | currency }}
                        </div>
                        <div class="text-right col-6 col-xl-2"></div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-12 col-xl-6">
                            <b>Total Discounts/Gratis</b>
                        </div>
                        <div class="text-right col-6 col-xl-4">
                            {{ stepperCallApplicationService.totalCashAndGratisDiscount | currency }}
                        </div>
                        <div class="text-right col-6 col-xl-2" [ngClass]=" stepperCallApplicationService.totalDiscountPercent > 0.08 ? 'm-red' : ''">
                            {{ (stepperCallApplicationService.totalDiscountPercent ? stepperCallApplicationService.totalDiscountPercent : 0) | percent }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-xl-6">
                            <b>Total Payment Due</b>
                        </div>
                        <div class="text-right col-6 col-xl-4">
                            {{ stepperCallApplicationService.totalPaymentDue | currency }}
                        </div>
                        <div class="text-right col-6 col-xl-2"></div>
                    </div>
                </div>
                <div class="row p-3">
                    <div>
                        <button appThrottleClick *ngIf="isTaxAvailable"
                            [disabled]="isFinalWholesaleReceiptPrinted || isFinalRetailReceiptPrinted"
                            (throttledClick)="onOpenTaxCalculator()" class="btn sw-red-btn btn-sm" tabindex="0"
                            type="button">
                            Tax Calculator
                        </button>
                    </div>
                    <div></div>
                </div>
            </mat-card>
        </div>
    </div>
</mat-card>
