import { Address } from "src/app/entity-models/address.entity";
import { GeocoderService } from "src/app/services/geocoder.service";

export class DirectionsDialogViewModel {
    storageLocation?: boolean;
    startAtCurrentLocation: boolean;
    startAtStorageLocation: boolean;
    startPrevLocations: Address[];
    startNewLocation: Address = new Address();
    startFirstStopLocation: Address;
    startLocation: Address;
    endAtCurrentLocation: boolean;
    endAtStorageLocation: boolean;
    endAtStartingLocation: boolean;
    endPrevLocations: Address[];
    endNewLocation: Address = new Address();
    endLastStopLocation: Address;
    endLocation: Address;
    onSave: (data: DirectionsDialogViewModel) => void;
    geocoder: GeocoderService;
    isCurrentAddressAvailable: boolean;
}
